import React, { useState, useEffect, useCallback, useRef } from "react";
import {
  Table,
  Button,
  Tag,
  Space,
  Typography,
  Form,
  Select,
  Drawer,
  message,
  Row,
  Col,
  Empty,
  Alert,
} from "antd";
import { DndProvider, useDrag, useDrop, createDndContext } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import update from "immutability-helper";
import { useMutation } from "react-query";
import http from "services/httpService.js";
import moment from "moment";
import { DeleteOutlined, PlusOutlined, SaveOutlined } from "@ant-design/icons";
import SYTeacher from "views/SYSubjects/components/SYTeacher";

import {
  useSchoolLevelQuery,
  useSRSYSubjects,
  useSubjectsSR,
  useSubjectsSRCategory,
} from "views/SYSubjects/hooks/Index";

const RNDContext = createDndContext(HTML5Backend);
const type = "DragableBodyRow";
const { Option, OptGroup } = Select;
const { Text } = Typography;

// for extraction
const DragableBodyRow = ({ index, moveRow, record, className, style, ...restProps }) => {
  const ref = React.useRef();
  const [{ isOver, dropClassName }, drop] = useDrop({
    accept: type,
    collect: (monitor) => {
      const { index: dragIndex } = monitor.getItem() || {};
      if (dragIndex === index) {
        return {};
      }
      return {
        isOver: monitor.isOver(),
        dropClassName: dragIndex < index ? " drop-over-downward" : " drop-over-upward",
      };
    },

    drop: (item) => {
      moveRow(item.index, index);
    },
  });
  const [, drag] = useDrag({
    item: { type, index },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  drop(drag(ref));

  return (
    <tr
      ref={ref}
      className={`${className}${isOver ? dropClassName : ""}`}
      style={{ cursor: "move", ...style }}
      {...restProps}
    />
  );
};

// for extraction
const SelectionForm = ({ form, selectedSyLevel, setSelectedSyLevel }) => {
  const { isLoading, data } = useSchoolLevelQuery(2);
  const sySelections = generateSy();
  function generateSy() {
    let yearArray = [];
    let yearNow = Number(moment().format("YYYY"));
    for (let i = yearNow; i > 2000; i--) {
      yearArray.push(`${i}-${i + 1}`);
    }
    return yearArray;
  }
  const onSelectionChange = (value, option, type) => {
    switch (type) {
      case "sy":
        setSelectedSyLevel({ ...selectedSyLevel, sy: value });
        break;
      case "level_code":
        setSelectedSyLevel({ ...selectedSyLevel, level_code: value, level: option.children });
        break;
      case "sem":
        setSelectedSyLevel({ ...selectedSyLevel, sem: value });
        break;
      default:
        return;
    }
  };
  return (
    <Form layout="inline" form={form}>
      <Form.Item label="Select School Year" name="sy">
        <Select
          placeholder="none"
          style={{ width: 120 }}
          onChange={(value, option) => {
            onSelectionChange(value, option, "sy");
          }}
          rules={[
            {
              required: true,
              message: "Please select School Year!",
            },
          ]}
        >
          {sySelections.map((item) => {
            return (
              <Option value={item} key={item}>
                {item}
              </Option>
            );
          })}
        </Select>
      </Form.Item>
      <Form.Item label="Select Level" name="level_code">
        <Select
          placeholder="{none}"
          style={{ width: 180 }}
          loading={isLoading}
          onChange={(value, option) => {
            onSelectionChange(value, option, "level_code");
          }}
          rules={[
            {
              required: true,
              message: "Please select Level!",
            },
          ]}
        >
          {isLoading
            ? null
            : data.map((item, index) => {
                return (
                  <OptGroup label={item.school_level_category} key={index}>
                    {item.school_level.map((element) => {
                      return (
                        <Option value={element.level_code} key={element.level_code}>
                          {element.level}
                        </Option>
                      );
                    })}
                  </OptGroup>
                );
              })}
        </Select>
      </Form.Item>
      <Form.Item label="Select Semester" name="sem">
        <Select
          placeholder="{none}"
          style={{ width: 150 }}
          onChange={(value, option) => {
            onSelectionChange(value, option, "sem");
          }}
          rules={[
            {
              required: true,
              message: "Please School Semester!",
            },
          ]}
        >
          <Option value="1">1st Semester</Option>
          <Option value="2">2nd Semester</Option>
        </Select>
      </Form.Item>
    </Form>
  );
};

const CategorySelection = ({ hasSelection, categoryCodes, handlesAddPrimarySubject }) => {
  const { isLoading, data: subjectsrcategory } = useSubjectsSRCategory();
  const [value, setValue] = useState(null);

  const onSelectionChange = (value, option) => {
    handlesAddPrimarySubject(
      { subjects_sr_category_id: value, subjects_sr_category: option.children },
      true
    );
    setValue(null);
  };

  return (
    <Select
      style={{ width: 200 }}
      onChange={(value, option) => {
        onSelectionChange(value, option);
      }}
      loading={isLoading}
      disabled={hasSelection ? false : true}
      value={value}
      placeholder="Select Category"
    >
      {!isLoading
        ? subjectsrcategory.map((item) => {
            return (
              <Option
                value={item.subjects_sr_category_id}
                key={item.subjects_sr_category_id}
                disabled={categoryCodes.includes(item.subjects_sr_category_id) ? true : false}
              >
                {item.subjects_sr_category}
              </Option>
            );
          })
        : null}
    </Select>
  );
};
// for extraction
const SubjectSRSelection = ({
  modalVisibility,
  setModalVisibility,
  subjectSYCodes,
  handlesAddPrimarySubject,
}) => {
  const columns = [
    {
      title: "Subject Code",
      dataIndex: "subject_code",
      key: "subject_code",
      render: (text, record) => (
        <span className={subjectSYCodes.includes(record.subject_code) ? "disabled-row" : ""}>
          {record.subject_code}
        </span>
      ),
    },
    {
      title: "Subject",
      dataIndex: "subject",
      key: "subject",
      render: (text, record) => (
        <span className={subjectSYCodes.includes(record.subject_code) ? "disabled-row" : ""}>
          {record.subject}
        </span>
      ),
    },
    {
      title: "Units",
      dataIndex: "units",
      key: "units",
      render: (text, record) => (
        <span className={subjectSYCodes.includes(record.subject_code) ? "disabled-row" : ""}>
          {record.units}
        </span>
      ),
    },
  ];
  const { data: subjectsr } = useSubjectsSR();
  const [tableData, setTableData] = useState([]);
  useEffect(() => {
    setTableData(subjectsr);
  }, [subjectsr]);

  const handlesRowClick = (event, record) => {
    if (subjectSYCodes.includes(record.subject_code)) {
      message.warning("Already in the list. ");
    } else {
      handlesAddPrimarySubject(record, false);
    }
  };
  return (
    <Drawer
      title="Subject Senior High School"
      width={650}
      placement="right"
      closable={true}
      onClose={() => setModalVisibility(false)}
      visible={modalVisibility}
      footer={
        <div
          style={{
            textAlign: "right",
          }}
        >
          <Button onClick={() => setModalVisibility(false)} type="primary">
            Close
          </Button>
        </div>
      }
    >
      <Table
        columns={columns}
        dataSource={tableData}
        rowKey="subject_code"
        pagination={false}
        size="small"
        onRow={(record, rowIndex) => {
          return {
            onClick: (event) => {
              handlesRowClick(event, record);
            }, // click row
          };
        }}
      />
    </Drawer>
  );
};

const SYSubjectsSenior = () => {
  const [selectionForm] = Form.useForm();
  const [selectedSyLevel, setSelectedSyLevel] = useState({
    sy: null,
    level_code: null,
    level: null,
    sem: null,
  });
  const [modalVisibility, setModalVisibility] = useState(false);
  const [hasSelection, setHasSelection] = useState(false);
  const [subjectSYCodes, setSubjectSYCodes] = useState([]);
  const [categoryCodes, setCategoryCodes] = useState([]);

  const [enableMainQuery, setEnableMainQuery] = useState(false);
  const [data, setData] = useState();

  const { isLoading, data: sySubjectsData } = useSRSYSubjects(selectedSyLevel, {
    enabled: enableMainQuery,
  });

  const manager = useRef(RNDContext);

  const [isLocked, setIsLocked] = useState();

  const [mutate] = useMutation(
    async (values) => {
      console.log(values);
      await http.put(
        `subjectsrsy/update/${selectedSyLevel.sy}/${selectedSyLevel.level_code}/${selectedSyLevel.sem}`,
        {
          values: values,
        }
      );
    },
    {
      onSuccess: (data, variables) => {
        message.success("Record saved.");
      },
      onError: () => {
        // setDrawerVisibility(false);
        message.warning("Unable to save, Something went wrong :/");
      },
    }
  );

  const columns = [
    {
      title: () => (
        <Space>
          <Text>Subjects</Text>
          {selectedSyLevel.sy && selectedSyLevel.level_code ? (
            <Text> {`for (SY - ${selectedSyLevel.sy}) (Level - ${selectedSyLevel.level})`}</Text>
          ) : null}

          {isLocked ? null : (
            <CategorySelection
              hasSelection={hasSelection}
              categoryCodes={categoryCodes}
              handlesAddPrimarySubject={handlesAddPrimarySubject}
            />
          )}
        </Space>
      ),
      name: "subject",
      dataIndex: "subject",
      //   width: 300,
      render: (text, record) => {
        let iscateg = record.isCategory === 1 ? true : false;
        return (
          <Space>
            {iscateg ? <Tag color="geekblue">Category</Tag> : null}
            <span
              style={{ paddingLeft: iscateg ? 3 : 50, fontWeight: iscateg ? "bold" : "normal" }}
            >
              {iscateg ? record.subject.toUpperCase() : record.subject}
            </span>
          </Space>
        );
      },
    },
    {
      title: isLocked ? null : (
        <Button
          shape="circle"
          icon={<PlusOutlined />}
          type="primary"
          disabled={!hasSelection}
          onClick={() => {
            setModalVisibility(true);
          }}
        ></Button>
        // <Button
        //   shape="circle"
        //   icon={<ReloadOutlined />}
        //   type="primary"
        //   disabled={!hasSelection}
        // ></Button>
      ),
      key: "manage",
      dataIndex: "manage",
      width: 60,
      render: (text, record, index) =>
        isLocked ? null : (
          <Button
            size="small"
            type="link"
            danger
            icon={<DeleteOutlined />}
            onClick={() => handlesDeleteRow(record, index, record.isCategory === 1 ? true : false)}
          />
        ),
    },
  ];

  useEffect(() => {
    if (selectedSyLevel.sy && selectedSyLevel.level_code && selectedSyLevel.sem) {
      setHasSelection(true);
      setEnableMainQuery(true);
    }
    if (sySubjectsData) {
      setData(sySubjectsData.subjectsSRSY);
      setSubjectSYCodes(sySubjectsData.subjectSRSYCodes);
      setCategoryCodes(sySubjectsData.categoryCodes);
      setIsLocked(sySubjectsData.isLocked);
    }
  }, [sySubjectsData, selectedSyLevel]);

  const components = {
    body: {
      row: DragableBodyRow,
    },
  };

  const moveRow = useCallback(
    (dragIndex, hoverIndex) => {
      const dragRow = data[dragIndex];
      setData(
        update(data, {
          $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, dragRow],
          ],
        })
      );
    },
    [data]
  );

  const handlesDeleteRow = (record, index, isCategory) => {
    if (isCategory) {
      setCategoryCodes(categoryCodes.filter((item) => item !== record.subjects_sr_category_id));
    } else {
      setSubjectSYCodes(subjectSYCodes.filter((item) => item !== record.subject_code));
    }
    setData(
      update(data, {
        $splice: [[index, 1]],
      })
    );
  };

  const handlesAddPrimarySubject = (record, isCategory) => {
    if (isCategory) {
      console.log(record, isCategory);
      let scode = Math.floor(Math.random() * 1000);
      setData([
        ...data,
        {
          subject_code: scode,
          subject: record.subjects_sr_category,
          with_sub: 0,
          isCategory: 1,
          subjects_sr_category_id: record.subjects_sr_category_id,
        },
      ]);
      setCategoryCodes([...categoryCodes, record.subjects_sr_category_id]);
    } else {
      // "subject_code" => $subjects['subject_code'], "subject" => $subjects['subject'], "with_sub" => 0, "isCategory" => 0]
      setData([
        ...data,
        { subject_code: record.subject_code, subject: record.subject, with_sub: 0, isCategory: 0 },
      ]);
      setSubjectSYCodes([...subjectSYCodes, record.subject_code]);
    }
  };

  const handlesSave = (event) => {
    event.preventDefault();
    mutate([...data]);
  };
  return (
    <>
      <SelectionForm
        form={selectionForm}
        selectedSyLevel={selectedSyLevel}
        setSelectedSyLevel={setSelectedSyLevel}
      />

      {!hasSelection ? (
        <Empty description="Select School Year, Level And Semester" style={{ marginTop: 60 }} />
      ) : (
        <>
          {isLocked ? (
            <Alert
              message="Locked!"
              description="This SY / Level is locked, you cannot make any changes on subjects once rating sheet has been created. "
              type="warning"
              showIcon
              style={{ marginBottom: 20 }}
            />
          ) : null}

          <Row gutter={8}>
            <Col span={16}>
              <DndProvider manager={manager.current.dragDropManager}>
                <Table
                  columns={columns}
                  dataSource={data}
                  components={components}
                  onRow={(record, index) => ({
                    index,
                    moveRow,
                  })}
                  pagination={false}
                  rowKey="subject_code"
                  loading={isLoading}
                  size="medium"
                  //   style={{ pointerEvents: "none", opacity: "0.4" }}
                />
              </DndProvider>

              <Row gutter={8} style={{ marginTop: 8 }}>
                <Col span={18}>
                  <Alert
                    message="Please click save button to apply changes on Subjects."
                    type="info"
                    showIcon
                  />
                </Col>
                <Col span={6}>
                  <Button
                    icon={<SaveOutlined />}
                    type="primary"
                    block
                    onClick={(event) => {
                      handlesSave(event);
                    }}
                    size="large"
                    disabled={isLocked ? true : !hasSelection}
                  >
                    Save
                  </Button>
                </Col>
              </Row>
            </Col>
            <Col span={8}>
              <SYTeacher
                selectedSyLevel={selectedSyLevel}
                disabled={!hasSelection}
                isLocked={isLocked}
              />
            </Col>
          </Row>
        </>
      )}
      <SubjectSRSelection
        modalVisibility={modalVisibility}
        setModalVisibility={setModalVisibility}
        subjectSYCodes={subjectSYCodes}
        handlesAddPrimarySubject={handlesAddPrimarySubject}
      />
    </>
  );
};

export default SYSubjectsSenior;
