import { REQUEST_ERROR } from "./system.types";

const INIT_STATE = {
    isRequestError: false,
    requestErrorMsg: "",
    requestErrorSubMsg: "",
};

const systemReducer = (state = INIT_STATE, action) => {
    switch (action.type) {
        case REQUEST_ERROR:
            return {
                ...state,
                isRequestError: true,
                requestErrorMsg: action.payload.errorMessage,
                requestErrorSubMsg: action.payload.errorSubMessage,
            };
        default:
            return state;
    }
};

export default systemReducer;
