import React from "react";
import { Avatar, message, Button, Row, Col } from "antd";

import { components } from "react-select";
import AsyncSelect from "react-select/async";
import { SearchOutlined } from "@ant-design/icons";
// import Drawer from "globalComponents/Drawer";
// import PatientForm from "views/Patients/Components/Forms/PatientForm/Index";

import http from "services/httpService";

const DynamicSearchBox = ({ searchFrom, onSelect, showAddbutton, disabled = false }) => {
  // const [isPatientFormVisible, setIsPatientFormVisible] = useState(false);

  // const togglePatientFormVisible = () => {
  //   setIsPatientFormVisible(!isPatientFormVisible);
  // };

  const promiseOptions = (inputValue) =>
    new Promise((resolve) => {
      resolve(
        http
          .get(`${searchFrom}/search/${inputValue}`)
          .then(function (response) {
            if (response.data.error) {
              return [];
            } else {
              return response.data;
            }
          })
          .catch(function (error) {
            message.error(error.message);
          })
      );
    });

  // useEffect(() => {
  //   console.log(sy, level_code, level);

  // }, [sy, level_code, level]);

  const optionFormat = (props) => {
    const { innerProps, innerRef, data } = props;

    let fullName = props.value;
    // const imgPath = data.img_path;
    // const initials = data.initials;

    // const avatarProps = imgPath ? { size: 40, icon: "user" } : { size: 40 };

    return (
      <div
        ref={innerRef}
        {...innerProps}
        style={{
          margin: 8,
          cursor: "pointer",
          backgroundColor: props.isFocused ? "#e6f7ff" : "inherit",
        }}
      >
        <Row gutter={8}>
          <Col style={{ padding: "10px 0px 10px 8px" }}>
            <Avatar size={28}>{data.initials}</Avatar>
          </Col>
          <Col style={{ padding: 10 }}>
            <b>{fullName}</b>
            <br />
            <small>
              <b>SY Entry: </b> {data.entry_sy}
              <br></br> <b>Town: </b> {data.town_city}{" "}
            </small>
          </Col>
        </Row>
      </div>
    );
  };

  const customTheme = (theme) => {
    return {
      ...theme,
      borderRadius: 4,
      colors: {
        ...theme.colors,
        primary: "#e6f7ff",
        primary25: "#e6f7ff",
        primary50: "#e6f7ff",
        neutral30: "#40a9ff",
        neutral190: "#595959",
      },
      spacing: {
        ...theme.spacing,
      },
    };
  };

  const dropdownIndicator = (props) => {
    return (
      components.DropdownIndicator && (
        <components.DropdownIndicator {...props}>
          <SearchOutlined twoToneColor="#eb2f96" />
        </components.DropdownIndicator>
      )
    );
  };

  const handleChange = (option) => {
    onSelect(option);
  };

  // const handlePatientFormSubmitSuccess = () => {
  //   togglePatientFormVisible();
  // };

  return (
    <>
      <AsyncSelect
        components={{
          DropdownIndicator: dropdownIndicator,
          Option: optionFormat,
        }}
        //cacheOptions
        loadOptions={promiseOptions}
        noOptionsMessage={() => (
          <>
            {"No record found"}{" "}
            {showAddbutton && (
              <>
                <br /> <Button type="link">Add Patient</Button>
              </>
            )}
          </>
        )}
        isClearable={true}
        escapeClearsValue={true}
        theme={customTheme}
        styles={customStyles}
        onChange={handleChange}
        getOptionLabel={(option) => option.fullname}
        getOptionValue={(option) => option.fullname}
        placeholder={`Search ${searchFrom}`}
        menuContainerStyle={{ zIndex: 999 }}
        isDisabled={disabled}
        value={null}
      />
      {/* 
      <Drawer
        title="Add Patient"
        placement="right"
        visible={isPatientFormVisible}
        onClose={togglePatientFormVisible}
        destroyOnClose={true}
        width={breakpoint.isNormalMobile ? 320 : breakpoint.isTabletPortrait ? 700 : 1000}
        bodyStyle={breakpoint.isNormalMobile ? { padding: "24px 12px" } : null}
      >
        <PatientForm
          patientData={null}
          dataEditing={false}
          breakpoint={breakpoint}
          inline={true}
          onAddSuccess={handlePatientFormSubmitSuccess}
        />
      </Drawer> */}
    </>
  );
};

const customStyles = {
  option: (provided, state) => ({
    ...provided,
    color: "#595959",
  }),
  control: (provided, state) => ({
    // none of react-select's styles are passed to <Control />
    ...provided,

    "&:hover": {
      border: "1px solid #40a9ff",
    },

    border: "1px solid #d9d9d9",
  }),
  input: (provided) => ({
    ...provided,
    color: "#595959",
    fontSize: 14,
  }),
  singleValue: (provided, state) => ({
    ...provided,
    color: "#595959",
  }),
  valueContainer: (provided) => ({
    ...provided,
    zIndex: 2,
  }),
  menu: (provided) => ({
    ...provided,
    zIndex: 99,
  }),
};

export default DynamicSearchBox;
