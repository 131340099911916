import React, { useState, useEffect } from "react";
import {
  Table,
  Row,
  Col,
  Descriptions,
  Typography,
  Button,
  Empty,
  Tag,
  Spin,
  Space,
  Drawer,
  Popconfirm,
  message,
  Collapse,
  Input,
  DatePicker,
  Form,
} from "antd";
import {
  PrinterOutlined,
  PlusOutlined,
  EditOutlined,
  DeleteOutlined,
  LockOutlined,
} from "@ant-design/icons";
import moment from "moment";
import noimage from "assets/images/no-image.png";
import { generateString } from "helpers/randomStringGenerator";
import { queryCache } from "react-query";
import http from "services/httpService.js";
// import update from "immutability-helper";
import StudentGrades from "views/RatingSheet/StudentGrades";
import pdfMakePermanentElementary from "views/PermanentRecord/components/PermanentElementary";
import pdfMakePermanentJunior from "views/PermanentRecord/components/PermanentJunior";
import pdfMakePermanentSenior from "views/PermanentRecord/components/PermanentSenior";
import DynamicSearchBox from "globalComponents/DynamicSearchBox";
import {
  // usePermanentRegistration,
  usePermanentRecord,
  useSettingsQuery,
} from "views/SYSubjects/hooks/Index";

import ComposerK12 from "views/PermanentRecord/components/SubjectComposer/ComposerK12";
import ComposerSH from "views/PermanentRecord/components/SubjectComposer/ComposerSH";
import GradesComposer from "views/PermanentRecord/components/GradesComposer/Index";

const { Text } = Typography;
const { Panel } = Collapse;

const PermanentRecord = () => {
  useSettingsQuery();
  const [studentData, setStudentData] = useState(null);
  const [studentRegistrations, setStudentRegistrations] = useState([]);
  const [studentID, setStudentID] = useState(null);

  const [printCredentials, setPrintCredentials] = useState({
    student_id: null,
    permanentType: 0,
  });
  const [goPrint, setGoPrint] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  // const { isLoading, data: permanentRegistration } = usePermanentRegistration(studentID);
  const { isLoading: isLoadingPermamentRecord, data: permanentRecord } =
    usePermanentRecord(printCredentials);

  const [subjectComposerDrawerK12, setSubjectComposerDrawerK12] =
    useState(false);
  const [subjectComposerDrawerSH, setSubjectComposerDrawerSH] = useState(false);
  const [gradesComposerDrawer, setGradesComposerDrawer] = useState(false);
  const [levelDetails, setLevelDetails] = useState({});

  //  newwwww
  const [drawerOldGrades, setDrawerOldGrades] = useState(false);
  const [credentailsOldGrades, setCredentailsOldGrades] = useState({
    registration_no: null,
    school_level_category_id: null,
    sem: 1,
  });
  //

  const [regs, setRegs] = useState({});

  const [customGradesData, setCustomGradesData] = useState({
    credentials: {},
    newCustomGrades: false,
    regs: [],
    subjects: [],
  });

  const [remarks, setRemarks] = useState({
    a1: "",
    r1: "",
    a2: "",
    r2: "",
    a3: "",
    r3: "",
    admissionDate: null,
  });

  const handlesStudentSearch = (option) => {
    if (option) {
      setStudentID(option.student_id);
      setStudentData(option);
      getRegistrations(option.student_id);
    }
  };

  async function getRegistrations(student_id) {
    setIsLoading(true);
    const { data } = await http.get(`permanent/registration/${student_id}`);
    setStudentRegistrations(data);
    setIsLoading(false);
  }

  useEffect(() => {
    if (permanentRecord && goPrint) {
      if (printCredentials.permanentType === 1) {
        let otherDetails = { admissionTo: remarks.a1, remarks: remarks.r1 };
        pdfMakePermanentElementary(permanentRecord, otherDetails);
      } else if (printCredentials.permanentType === 2) {
        let otherDetails = { admissionTo: remarks.a2, remarks: remarks.r2 };
        pdfMakePermanentJunior(permanentRecord, otherDetails);
      } else if (printCredentials.permanentType === 3) {
        let otherDetails = {
          admissionTo: remarks.a3,
          remarks: remarks.r3,
          admissionDate: remarks.admissionDate,
        };
        pdfMakePermanentSenior(permanentRecord, otherDetails);
      }
      setGoPrint(false);
    }
  }, [goPrint, printCredentials, permanentRecord, remarks]);

  const deleteRegs = async (record) => {
    try {
      setIsLoading(true);
      await http.delete(
        `permanent/registration/delete/${record.registration_no}/${record.level_code}`
      );
      getRegistrations(studentData.student_id);
      message.success("Delete success!");
    } catch (error) {
      message.error("Something went wrong. " + error.message);
    }
  };

  const handlesManageGrades = (type, record) => {
    switch (type) {
      case "new":
        let created_at = moment().format("YYY-MM-DD H:s:m");
        let registration_no = generateString(7, "r");

        setRegs({
          level_code: record.level_code,
          level: record.level,
          registration_no: registration_no,
          created_at: created_at,
          is_transferee: 0,
          student_id: studentData.student_id,
          sy: null,
          full_name: studentData.fullname,
          sem: 1,
        });
        setLevelDetails(record);

        if (record.level_code < 14) {
          setSubjectComposerDrawerK12(true);
        } else {
          setSubjectComposerDrawerSH(true);
        }
        break;
      case "edit":
        setCustomGradesData({
          ...customGradesData,
          credentials: {
            registration_no: record.registration_no,
            school_level_category_id: record.level_code > 13 ? 2 : 1,
            sem: 1,
          },
          newCustomGrades: false,
          regs: null,
          subjects: null,
        });
        setLevelDetails(record);
        setGradesComposerDrawer(true);
        break;
      default:
        return;
    }
  };
  const columns = [
    {
      title: "Level",
      dataIndex: "level",
      key: "level",
    },
    {
      title: "SY",
      dataIndex: "sy",
      key: "sy",
      render: (text, record) => record.sy || "-",
    },
    {
      title: "School Attended",
      dataIndex: "school_attended",
      key: "school_attended",
      render: (text, record) => record.school_attended || "-",
    },
    {
      title: "Final Grade",
      dataIndex: "final_grade",
      key: "final_grade",
      render: (text) => (
        <Tag color="geekblue">
          {Number(text) > 0 ? Number(text).toFixed(3) : null}
        </Tag>
      ),
    },
    {
      title: "Category",
      dataIndex: "school_level_category",
      key: "school_level_category",
      render: (text, record) =>
        record.level_code < 14 ? "K12" : "Senior High",
    },
    {
      title: "Custom",
      dataIndex: "is_custom",
      key: "is_custom",
      render: (text, record) => (record.is_custom ? "Yes" : "-"),
    },
    {
      title: "Trasferee",
      dataIndex: "is_transferee",
      key: "is_transferee",
      render: (text, record) => (record.is_transferee ? "Yes" : "-"),
    },
    {
      title: "Manage Grades",
      dataIndex: "school_level_category",
      render: (text, record) =>
        record.registration_no ? (
          Number(record.is_custom) === 1 ? (
            <Space size="middle">
              <Button
                shape="circle"
                size="small"
                icon={<EditOutlined />}
                onClick={() => handlesManageGrades("edit", record)}
              />
              <Popconfirm
                title="Are you sure to delete this record?"
                onConfirm={() => deleteRegs(record)}
                okText="Yes"
                cancelText="No"
              >
                <Button
                  shape="circle"
                  size="small"
                  icon={<DeleteOutlined />}
                  danger
                />
              </Popconfirm>
            </Space>
          ) : (
            <Button
              shape="circle"
              size="small"
              icon={<EditOutlined style={{ color: "#9e1068" }} />}
              onClick={(e) => editOldGrades(e, record)}
            />

            // <LockOutlined style={{ color: "orange" }} />
          )
        ) : (
          <Button
            shape="circle"
            size="small"
            icon={<PlusOutlined />}
            type="primary"
            onClick={() => handlesManageGrades("new", record)}
          />
        ),
    },
  ];

  const editOldGrades = (_, record) => {
    console.log(record);

    // registration_no: record.registration_no,
    // school_level_category_id:
    //   parseInt(selectedSyLevel.level_code) < 14 ? 1 : 2,

    // final_grade: "89.872"
    // is_custom: 0
    // is_transferee: 0
    // level: "Grade 4"
    // level_code: 7
    // registration_no: "5470"
    // school_attended: "L.S.I.M."
    // sy: "2018-2019"
    setCredentailsOldGrades({
      registration_no: record.registration_no,
      school_level_category_id: parseInt(record.level_code) < 14 ? 1 : 2,
      sem: 1,
    });
    setDrawerOldGrades(true);
  };

  const extraPrintIcon = (type) => (
    <PrinterOutlined
      onClick={(event) => {
        event.stopPropagation();
        removeQueriesPermanentRecords();
        setPrintCredentials({ student_id: studentID, permanentType: type });
        setGoPrint(true);
      }}
      style={{ fontSize: 20 }}
    />
  );

  const handlesChangeRemarks = (value, i) => {
    setRemarks({ ...remarks, [i]: value });
  };
  const removeQueriesPermanentRecords = () => {
    queryCache.removeQueries("permamentRecord");
  };

  return (
    <>
      <Drawer
        title="K12 Custom Subject Composer"
        width={800}
        placement="right"
        closable={false}
        visible={subjectComposerDrawerK12}
      >
        <ComposerK12
          levelDetails={levelDetails}
          setLevelDetails={setLevelDetails}
          setSubjectComposerDrawerK12={setSubjectComposerDrawerK12}
          regsDetails={regs}
          customGradesData={customGradesData}
          setCustomGradesData={setCustomGradesData}
          setGradesComposerDrawer={setGradesComposerDrawer}
        />
      </Drawer>
      <Drawer
        title="Senior High Custom Subject Composer"
        width={800}
        placement="right"
        closable={false}
        visible={subjectComposerDrawerSH}
      >
        <ComposerSH
          levelDetails={levelDetails}
          setLevelDetails={setLevelDetails}
          setSubjectComposerDrawerSH={setSubjectComposerDrawerSH}
          regsDetails={regs}
          customGradesData={customGradesData}
          setCustomGradesData={setCustomGradesData}
          setGradesComposerDrawer={setGradesComposerDrawer}
        />
      </Drawer>
      <Drawer
        title={`Grades Composer (${
          levelDetails ? levelDetails.level + " : " + levelDetails.sy : ""
        })`}
        width={1000}
        placement="right"
        closable={false}
        visible={gradesComposerDrawer}
      >
        <GradesComposer
          setDrawerVisibility={setGradesComposerDrawer}
          customGradesData={customGradesData}
          getRegistrations={getRegistrations}
          studentData={studentData}
        />
      </Drawer>
      <Drawer
        width={1100}
        placement="right"
        closable={false}
        visible={drawerOldGrades}
        destroyOnClose={true}
      >
        <StudentGrades
          credentials={{ ...credentailsOldGrades }}
          setDrawerVisibility={setDrawerOldGrades}
        />
      </Drawer>
      <Spin
        spinning={isLoadingPermamentRecord}
        size="large"
        tip="Loading, please wait..."
      >
        <Row>
          <Col span={14}>
            {/* <Button onClick={() => removeQueriesPermanentRecords()}>Show Drawer K12</Button> */}
            {/* <Button onClick={() => setGradesComposerDrawer(true)}>Show Drawer SH</Button> */}
            <DynamicSearchBox
              searchFrom={"student"}
              showAddbutton={false}
              disabled={false}
              onSelect={handlesStudentSearch}
            />
            {studentData ? (
              <Row style={{ marginTop: 20 }}>
                <Col span={5}>
                  <img
                    width={120}
                    src={
                      studentData.img_path
                        ? studentData.img_path.replace(".jpg", "-card.jpg")
                        : noimage
                    }
                    style={{ borderRadius: 10 }}
                    alt="Rating Sheet Students"
                  />
                </Col>
                <Col span={19}>
                  <Descriptions bordered size="small">
                    <Descriptions.Item label={<span>ID</span>} span={3}>
                      {studentData.student_id}
                    </Descriptions.Item>
                    <Descriptions.Item label={<span>Name</span>} span={3}>
                      {studentData.fullname}
                    </Descriptions.Item>
                    <Descriptions.Item label={<span>SY Entry</span>} span={3}>
                      {studentData.entry_sy}
                    </Descriptions.Item>
                    <Descriptions.Item label={<span>Town </span>} span={3}>
                      {studentData.town_city}
                    </Descriptions.Item>
                  </Descriptions>
                </Col>
              </Row>
            ) : null}
          </Col>
          <Col span={10}>
            {studentData ? (
              <div style={{ paddingLeft: 40 }}>
                <Collapse accordion>
                  <Panel
                    header="Permament Record Intermediate"
                    key="1"
                    extra={extraPrintIcon(1)}
                  >
                    <Space direction="vertical" style={{ width: "100%" }}>
                      <Input
                        prefix="For admission: "
                        onChange={(e) =>
                          handlesChangeRemarks(e.target.value, "a1")
                        }
                      />
                      <Input
                        prefix="Remarks: "
                        onChange={(e) =>
                          handlesChangeRemarks(e.target.value, "r1")
                        }
                      />
                    </Space>
                  </Panel>
                  <Panel
                    header="Permament Record Junior High School"
                    key="2"
                    extra={extraPrintIcon(2)}
                  >
                    <Space direction="vertical" style={{ width: "100%" }}>
                      <Input
                        prefix="For admission: "
                        onChange={(e) =>
                          handlesChangeRemarks(e.target.value, "a2")
                        }
                      />
                      <Input
                        prefix="Remarks: "
                        onChange={(e) =>
                          handlesChangeRemarks(e.target.value, "r2")
                        }
                      />
                    </Space>
                  </Panel>
                  <Panel
                    header="Permament Record Senior High School"
                    key="3"
                    extra={extraPrintIcon(3)}
                  >
                    <Space direction="vertical" style={{ width: "100%" }}>
                      <Input
                        prefix="For admission: "
                        onChange={(e) =>
                          handlesChangeRemarks(e.target.value, "a3")
                        }
                      />
                      <Input
                        prefix="Remarks: "
                        onChange={(e) =>
                          handlesChangeRemarks(e.target.value, "r3")
                        }
                      />
                      <Form.Item
                        label="Date of SHS Admission"
                        style={{ marginLeft: 4 }}
                      >
                        <DatePicker
                          onChange={(d1, d2) =>
                            handlesChangeRemarks(d2, "admissionDate")
                          }
                        />
                      </Form.Item>
                    </Space>
                  </Panel>
                </Collapse>
              </div>
            ) : null}
          </Col>
        </Row>
        {studentData ? (
          <>
            <div style={{ marginTop: 20, marginBottom: 10 }}>
              <Text style={{ paddingBottom: 10 }}>Registration</Text>
              <Table
                columns={columns}
                dataSource={studentRegistrations}
                size="small"
                pagination={false}
                rowKey="level_code"
                loading={isLoading}
              />
            </div>
          </>
        ) : (
          <Empty
            style={{ marginTop: 40 }}
            description="Please search and select student."
          />
        )}
      </Spin>
    </>
  );
};

export default PermanentRecord;
