function customRegsK12(regs) {
  return [
    {
      action_taken: "",
      advance_credit: "",
      classified_as: "",
      conduct: null,
      created_at: regs.created_at,
      days_present: "0.00",
      employee: null,
      employee_id: null,
      entry_sy: null,
      final_average: "0.00",
      final_grade: "0.00",
      first_average: "0.00",
      fourth_average: "0.00",
      full_name: regs.full_name,
      fullname: null,
      img_path: null,
      is_custom: 1,
      is_transferee: regs.is_transferee,
      lack_credit: null,
      level: regs.level,
      level_code: regs.level_code,
      lrn: null,
      registration_no: regs.registration_no,
      remarks: null,
      retained_in: "",
      school_attended: "",
      school_days: "0.00",
      second_average: "0.00",
      student_id: regs.student_id,
      sy: regs.sy,
      third_average: "0.00",
      town_city: null,
      units_earned: null,
      updated_at: null,
      with_removal: null,
      with_subjects: null,
      withdrawn: null,
    },
  ];
}
function customSubjectsK12(regs, data) {
  let subjects = [];
  let ctr = 1;
  data.forEach((elementPrimary) => {
    let sub = [];
    let with_sub = 0;
    let ctrSub = 1;
    // children: (4) [{…}, {…}, {…}, {…}]
    // subject: "MAPEH"
    // subject_code: "6"
    // with_sub: 1
    //  isSub: 1
    // subject: "Music"
    // subject_code: "1005"
    // subject_code_primary: "6"

    if (elementPrimary.with_sub === 1) {
      console.log("with sub");
      with_sub = 1;
      elementPrimary.children.forEach((elementSub) => {
        sub.push({
          action: "",
          final_grade: "0.00",
          first_grading: "0.00",
          fourth_grading: "0.00",
          level_code: regs.level_code,
          ordering: ctrSub,
          registration_no: regs.registration_no,
          second_grading: "0.00",
          student_id: regs.student_id,
          subject: elementSub.subject,
          subject_code: elementSub.subject_code_primary,
          subject_code_sub: elementSub.subject_code,
          sy: regs.sy,
          third_grading: "0.00",
          units: "",
        });
        ctrSub++;
      });
    }

    subjects.push({
      action: "",
      employee_id: null,
      final_grade: "0.00",
      first_grading: "0.00",
      fourth_grading: "0.00",
      level_code: regs.level_code,
      ordering: ctr,
      registration_no: regs.registration_no,
      second_grading: "0.00",
      student_id: regs.student_id,
      sub: sub,
      subject: elementPrimary.subject,
      subject_code: elementPrimary.subject_code,
      sy: regs.sy,
      third_grading: "0.00",
      units: "",
      with_sub: with_sub,
    });
    ctr++;
  });

  return subjects;
}

function customRegsSH(regs) {
  return [
    {
      action_taken: null,
      advance_credit: null,
      classified_as: null,
      conduct: null,
      created_at: regs.created_at,
      days_present: "0.00",
      employee: null,
      employee_id: null,
      entry_sy: null,
      final_average: "0.00",
      final_grade: "0.00",
      final_sr_ave: "0.00",
      first_average: "0.00",
      fourth_average: "0.00",
      full_name: null,
      fullname: null,
      img_path: null,
      is_custom: 1,
      is_transferee: regs.is_transferee,
      lack_credit: null,
      level: regs.level,
      level_code: regs.level_code,
      lrn: null,
      registration_no: regs.registration_no,
      remarks: null,
      retained_in: null,
      school_attended: null,
      school_days: "0.00",
      second_average: "0.00",
      sem_one_ave: "0.00",
      sem_one_days: "0.00",
      sem_one_first_ave: "0.00",
      sem_one_present: "0.00",
      sem_one_second_ave: "0.00",
      sem_two_ave: "0.00",
      sem_two_days: "0.00",
      sem_two_first_ave: "0.00",
      sem_two_present: "0.00",
      sem_two_second_ave: "0.00",
      student_id: regs.student_id,
      sy: regs.sy,
      third_average: "0.00",
      town_city: null,
      units_earned: null,
      updated_at: null,
      with_removal: null,
      with_subjects: null,
      withdrawn: null,
    },
  ];
}

// isCategory: 1
// sem: 1
// subject: "Core Subjects"
// subject_code: 7555
// subjects_sr_category_id: 1
// with_sub: 0

// isCategory: 0
// sem: 1
// subject: "Oral Communication"
// subject_code: "1"
// with_sub: 0

function customSubjectsSH(regs, data) {
  let ctr = 1;
  let subjects_sr_category_id = 0;
  let subjects = [];
  let subjects_sr_category = "";

  data.forEach((element) => {
    if (element.isCategory === 1) {
      subjects_sr_category_id = element.subjects_sr_category_id;
      subjects_sr_category = element.subject;
    } else {
      subjects.push({
        registration_no: regs.registration_no,
        sy: regs.sy,
        student_id: regs.student_id,
        level_code: regs.level_code,
        sem: element.sem,
        subjects_sr_category_id: subjects_sr_category_id,
        subject_code: element.subject_code,
        first_grading: "0.00",
        second_grading: "0.00",
        final_grade: "0.00",
        units: "",
        action: "",
        with_sub: 0,
        ordering: ctr,
        subjects_sr_category: subjects_sr_category,
        subject: element.subject,
      });
      ctr++;
    }
  });

  return subjects;
  // 'registration_no' => $subject['registration_no'],
  // 'sy' => $subject['sy'],
  // 'student_id' => $subject['student_id'],
  // 'level_code' => $subject['level_code'],
  // 'sem' => $subject['sem'],
  // 'subjects_sr_category_id' => $subject['subjects_sr_category_id'],
  // 'subject_code' => $subject['subject_code'],
  // 'first_grading' => $subject['first_grading'],
  // 'second_grading' => $subject['second_grading'],
  // 'final_grade' => $subject['final_grade'],
  // 'units' => $subject['units'],
}

export { customRegsK12, customSubjectsK12, customRegsSH, customSubjectsSH };
