import axios from "axios";
import { message } from "antd";
import { queryCache } from "react-query";
// import { defaultToken } from "config.json";
import { apiUrl } from "config.json";
const http = axios.create({ baseURL: apiUrl });
const token = localStorage.getItem("token") ? localStorage.getItem("token") : "";

http.defaults.headers.common["authorization"] = token;

http.interceptors.response.use(null, (error) => {
  if (error.message && error.message.responseCancelled) {
    console.log("response cancelled");
  } else {
    const expectedError =
      error.response && error.response.status >= 400 && error.response.status < 500;

    if (!expectedError) {
      message.error("An unexpected error occurred. Please try again.");
    } else if (error.response.data === "Unauthorized") {
      alert("Session expired");
      localStorage.clear();
      queryCache.clear();
      window.location = `/`;
    }
  }

  return Promise.reject(error);
});

export default http;

// {
//   get: axios.get,
//   post: axios.post,
//   put: axios.put,
//   delete: axios.delete,
//   CancelToken: axios.CancelToken,
//   all: axios.all,
//   spread: axios.spread,
// };
