const rsK12 = [
  {
    Header: "Info",
    hideHeader: true,
    columns: [
      {
        Header: "Subject",
        accessor: "subject",
        type: "text",
        columnIndex: -1,
      },
      {
        Header: "1st",
        accessor: "first_grading",
        type: "number",
        columnIndex: 0,
        isGrade: true,
        decimalPlaces: 3,
      },
      {
        Header: "2nd",
        accessor: "second_grading",
        type: "number",
        columnIndex: 1,
        isGrade: true,
        decimalPlaces: 3,
      },
      {
        Header: "3rd",
        accessor: "third_grading",
        type: "number",
        columnIndex: 2,
        isGrade: true,
        decimalPlaces: 3,
      },
      {
        Header: "4th",
        accessor: "fourth_grading",
        type: "number",
        columnIndex: 3,
        isGrade: true,
        decimalPlaces: 3,
      },
      {
        Header: "Final",
        accessor: "final_grade",
        type: "number",
        columnIndex: 4,
        isGrade: true,
        decimalPlaces: 3,
      },
      {
        Header: "Units",
        accessor: "units",
        type: "input",
        columnIndex: 5,
      },
      {
        Header: "Actions",
        accessor: "action",
        type: "input",
        columnIndex: 6,
      },
    ],
  },
];

const rgK12 = [
  {
    Header: "Info",
    hideHeader: true,
    columns: [
      {
        Header: "Conduct",
        accessor: "conduct",
        type: "input",
        columnIndex: 0,
      },
      {
        Header: "School Days",
        accessor: "school_days",
        type: "input",
        columnIndex: 1,
      },
      {
        Header: "Days Present",
        accessor: "days_present",
        type: "input",
        columnIndex: 2,
      },
      {
        Header: "1st Ave",
        accessor: "first_average",
        type: "number",
        columnIndex: 3,
        isGrade: true,
        decimalPlaces: 3,
      },
      {
        Header: "2nd Ave",
        accessor: "second_average",
        type: "number",
        columnIndex: 4,
        isGrade: true,
        decimalPlaces: 3,
      },
      {
        Header: "3rd Ave",
        accessor: "third_average",
        type: "number",
        columnIndex: 5,
        isGrade: true,
        decimalPlaces: 3,
      },
      {
        Header: "4th Ave",
        accessor: "fourth_average",
        type: "number",
        columnIndex: 6,
        isGrade: true,
        decimalPlaces: 3,
      },
      {
        Header: "Final Ave",
        accessor: "final_average",
        type: "number",
        columnIndex: 7,
        isGrade: true,
        decimalPlaces: 3,
      },

      // -------------------
      {
        Header: "Final Grade",
        accessor: "final_grade",
        type: "number",
        columnIndex: 8,
        isGrade: true,
        decimalPlaces: 3,
      },
      {
        Header: "Units Earned",
        accessor: "units_earned",
        type: "input",
        columnIndex: 9,
      },
      {
        Header: "Action Taken",
        accessor: "action_taken",
        type: "input",
        columnIndex: 10,
      },
      {
        Header: "School Attended",
        accessor: "school_attended",
        type: "input",
        columnIndex: 11,
      },
      {
        Header: "Advance Credit",
        accessor: "advance_credit",
        type: "input",
        columnIndex: 12,
      },
      {
        Header: "Lack Credit",
        accessor: "lack_credit",
        type: "input",
        columnIndex: 13,
      },
      {
        Header: "Callsified As",
        accessor: "classified_as",
        type: "input",
        columnIndex: 14,
      },
      {
        Header: "Retained In",
        accessor: "retained_in",
        type: "input",
        columnIndex: 15,
      },
      {
        Header: "With Removal",
        accessor: "with_removal",
        type: "input",
        columnIndex: 16,
      },
      {
        Header: "Withdrawn",
        accessor: "withdrawn",
        type: "input",
        columnIndex: 17,
      },
      {
        Header: "With Subjects",
        accessor: "with_subjects",
        type: "input",
        columnIndex: 18,
      },
      {
        Header: "Remarks",
        accessor: "remarks",
        type: "input",
        columnIndex: 19,
      },
    ],
  },
];

const rsSR = [
  {
    Header: "Info",
    hideHeader: true,
    columns: [
      {
        Header: "Sem",
        accessor: "sem",
        type: "text",
        columnIndex: -1,
      },
      {
        Header: "Category",
        accessor: "subjects_sr_category",
        type: "text",
        columnIndex: -1,
        className: "title",
      },
      {
        Header: "Subject",
        accessor: "subject",
        type: "text",
        columnIndex: -1,
      },
      {
        Header: "1st",
        accessor: "first_grading",
        type: "number",
        columnIndex: 0,
        isGrade: true,
        decimalPlaces: 3,
      },
      {
        Header: "2nd",
        accessor: "second_grading",
        type: "number",
        columnIndex: 1,
        isGrade: true,
        decimalPlaces: 3,
      },
      {
        Header: "Final",
        accessor: "final_grade",
        type: "number",
        columnIndex: 2,
        isGrade: true,
        decimalPlaces: 3,
      },
      {
        Header: "Units",
        accessor: "units",
        type: "input",
        columnIndex: 3,
      },
      {
        Header: "Actions",
        accessor: "action",
        type: "input",
        columnIndex: 4,
      },
    ],
  },
];

const rgSR = [
  {
    Header: "Info",
    hideHeader: true,
    columns: [
      {
        Header: "Conduct",
        accessor: "conduct",
        type: "input",
        columnIndex: 0,
      },
      {
        Header: "School Days",
        accessor: "school_days",
        type: "input",
        columnIndex: 1,
      },
      {
        Header: "Days Present",
        accessor: "days_present",
        type: "input",
        columnIndex: 2,
      },
      {
        Header: "Sem1 (1st Ave)",
        accessor: "sem_one_first_ave",
        type: "number",
        columnIndex: 3,
        isGrade: true,
        decimalPlaces: 3,
      },
      {
        Header: "Sem1 (2nd Ave)",
        accessor: "sem_one_second_ave",
        type: "number",
        columnIndex: 4,
        isGrade: true,
        decimalPlaces: 3,
      },
      {
        Header: "Sem1 (Final Ave)",
        accessor: "sem_one_ave",
        type: "number",
        columnIndex: 5,
        isGrade: true,
        decimalPlaces: 3,
      },
      {
        Header: "Sem2 (1st Ave)",
        accessor: "sem_two_first_ave",
        type: "number",
        columnIndex: 6,
        isGrade: true,
        decimalPlaces: 3,
      },
      {
        Header: "Sem2 (2nd Ave)",
        accessor: "sem_two_second_ave",
        type: "number",
        columnIndex: 7,
        isGrade: true,
        decimalPlaces: 3,
      },

      // -------------------
      {
        Header: "Sem2 (Final Ave)",
        accessor: "sem_two_ave",
        type: "number",
        columnIndex: 8,
        isGrade: true,
        decimalPlaces: 3,
      },

      {
        Header: "Final Average",
        accessor: "final_sr_ave",
        type: "number",
        columnIndex: 9,
        isGrade: true,
        decimalPlaces: 3,
      },
      {
        Header: "Units Earned",
        accessor: "units_earned",
        type: "input",
        columnIndex: 10,
      },
      {
        Header: "Action Taken",
        accessor: "action_taken",
        type: "input",
        columnIndex: 11,
      },
      {
        Header: "School Attended",
        accessor: "school_attended",
        type: "input",
        columnIndex: 12,
      },
      {
        Header: "Advance Credit",
        accessor: "advance_credit",
        type: "input",
        columnIndex: 13,
      },
      {
        Header: "Lack Credit",
        accessor: "lack_credit",
        type: "input",
        columnIndex: 14,
      },
      {
        Header: "Callsified As",
        accessor: "classified_as",
        type: "input",
        columnIndex: 15,
      },
      {
        Header: "Retained In",
        accessor: "retained_in",
        type: "input",
        columnIndex: 16,
      },
      {
        Header: "With Removal",
        accessor: "with_removal",
        type: "input",
        columnIndex: 17,
      },
      {
        Header: "Withdrawn",
        accessor: "withdrawn",
        type: "input",
        columnIndex: 18,
      },
      {
        Header: "With Subjects",
        accessor: "with_subjects",
        type: "input",
        columnIndex: 19,
      },
      {
        Header: "Remarks",
        accessor: "remarks",
        type: "input",
        columnIndex: 20,
      },
      {
        Header: "Sem 1 Days",
        accessor: "sem_one_days",
        type: "input",
        columnIndex: 21,
      },
      {
        Header: "Sem 1 Present",
        accessor: "sem_one_present",
        type: "input",
        columnIndex: 22,
      },
      {
        Header: "Sem 2 Days",
        accessor: "sem_two_days",
        type: "input",
        columnIndex: 23,
      },
      {
        Header: "Sem 2 Present",
        accessor: "sem_two_present",
        type: "input",
        columnIndex: 24,
      },
    ],
  },
];

const rsAttendance = [
  {
    Header: "Info",
    hideHeader: true,
    columns: [
      {
        Header: "Attendance",
        accessor: "attendance",
        type: "text",
        columnIndex: -1,
      },
      {
        Header: "Jun",
        accessor: "jun",
        type: "number",
        columnIndex: 0,
        isGrade: false,
        decimalPlaces: 2,
      },
      {
        Header: "Jul",
        accessor: "jul",
        type: "number",
        columnIndex: 1,
        isGrade: false,
        decimalPlaces: 2,
      },
      {
        Header: "Aug",
        accessor: "aug",
        type: "number",
        columnIndex: 2,
        isGrade: false,
        decimalPlaces: 2,
      },
      {
        Header: "Sep",
        accessor: "sep",
        type: "number",
        columnIndex: 3,
        isGrade: false,
        decimalPlaces: 2,
      },
      {
        Header: "Oct",
        accessor: "oct",
        type: "number",
        columnIndex: 4,
        isGrade: false,
        decimalPlaces: 2,
      },
      {
        Header: "Nov",
        accessor: "nov",
        type: "number",
        columnIndex: 5,
        isGrade: false,
        decimalPlaces: 2,
      },
      {
        Header: "Dec",
        accessor: "dec",
        type: "number",
        columnIndex: 6,
        isGrade: false,
        decimalPlaces: 2,
      },
      {
        Header: "Jan",
        accessor: "jan",
        type: "number",
        columnIndex: 7,
        isGrade: false,
        decimalPlaces: 2,
      },
      {
        Header: "Mar",
        accessor: "mar",
        type: "number",
        columnIndex: 8,
        isGrade: false,
        decimalPlaces: 2,
      },
      {
        Header: "Apr",
        accessor: "apr",
        type: "number",
        columnIndex: 9,
        isGrade: false,
        decimalPlaces: 2,
      },
      {
        Header: "Total",
        accessor: "total_attendance",
        type: "number",
        columnIndex: 10,
        isGrade: false,
        decimalPlaces: 2,
      },
    ],
  },
];

const rsTraits = [
  {
    Header: "Info",
    hideHeader: true,
    columns: [
      {
        Header: "Traits",
        accessor: "traits",
        type: "text",
        columnIndex: -1,
      },
      {
        Header: "First",
        accessor: "first",
        type: "input",
        columnIndex: 0,
      },
      {
        Header: "Second",
        accessor: "second",
        type: "input",
        columnIndex: 1,
      },
      {
        Header: "Third",
        accessor: "third",
        type: "input",
        columnIndex: 2,
      },
      {
        Header: "Fourth",
        accessor: "fourth",
        type: "input",
        columnIndex: 3,
      },
    ],
  },
];

export { rsK12, rgK12, rsSR, rgSR, rsTraits, rsAttendance };
