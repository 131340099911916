import pdfMake from "pdfmake/build/pdfmake";
import vfsFonts from "pdfmake/build/vfs_fonts";
import { queryCache } from "react-query";
import getBase64ImageFromURL from "helpers/base64ImageFromURL";
import { titleCase } from "helpers/textTransformer";
import moment from "moment";

export default async (data, otherDetails) => {
  const { permanent } = data;
  const { vfs } = vfsFonts.pdfMake;
  pdfMake.vfs = vfs;

  var cellmargin = -0.9;
  var normalFontSize = 7.5;
  var cellColor = "#A8D08D";

  var depEdLogo = await getBase64ImageFromURL(
    "https://itmg.sgp1.digitaloceanspaces.com/swa/deped.jpg"
  );

  var logo = await getBase64ImageFromURL(
    "https://itmg.sgp1.digitaloceanspaces.com/swa/lsim.jpg"
  );
  var imgBlank = await getBase64ImageFromURL(
    "https://itmg.sgp1.digitaloceanspaces.com/swa/_blank.jpg "
  );

  var imgPath = permanent.student.img_path
    ? await getBase64ImageFromURL(
        permanent.student.img_path.replace(".jpg", "-card.jpg")
      )
    : imgBlank;

  var thinBorder = {
    hLineWidth: function (i, node) {
      return i === 0 || i === node.table.body.length ? 0.1 : 0.1;
    },
    vLineWidth: function (i, node) {
      return i === 0 || i === node.table.widths.length ? 0.1 : 0.1;
    },
  };
  const {
    director,
    principal_senior,
    adviser_senior,
  } = queryCache.getQueryData("settings");

  const renderRows = (registration, sem) => {
    // "level_code": 10,
    // "level": "Grade 7",
    // "registration_no": "3585",
    // "sy": "2016-2017",
    // "school_attended": "L.S.B.S.H.S.",
    // "final_grade": "86.360",
    // "final_average": "86.360",
    // "action_taken": "PROMOTED",
    // "school_days": "202",
    // "days_present": "38",
    // "classified_as": "GRADE 8",
    // "units_earned": "11",
    return [
      [
        {
          colSpan: 4,
          text: `School: ${registration.school_attended}`,
          style: "subjectLabelBG",
        },
        {},
        {},
        {},
      ],
      [
        { text: `Level :  ${registration.level}`, style: "subjectLabelBG" },
        {
          colSpan: 3,
          alignment: "center",
          text: `School Year: ${registration.sy}`,
          style: "subjectLabelBG",
        },
        {},
        {},
      ],
      [
        {
          colSpan: 4,
          text: sem === "1" ? "FIRST SEMESTER" : "SECOND SEMESTER",
          style: "header",
          fillColor: cellColor,
        },
        {},
        {},
        {},
      ],
      ...registration[sem],
      [{}, {}, {}, {}],
      [
        { text: "AVERAGE", style: "subjectLabel", bold: true },
        {
          text:
            sem === "1"
              ? Number(registration.sem_one_first_ave).toFixed(3)
              : Number(registration.sem_two_first_ave).toFixed(3),
          style: "gradeNormal",
        },
        {
          text:
            sem === "1"
              ? Number(registration.sem_one_second_ave).toFixed(3)
              : Number(registration.sem_two_second_ave).toFixed(3),
          style: "gradeNormal",
        },
        {
          text:
            sem === "1"
              ? Number(registration.sem_one_ave).toFixed(3)
              : Number(registration.sem_two_ave).toFixed(3),
          style: "gradeNormal",
        },
      ],
      [
        {
          text: `No. of school days for ${sem === "1" ? "1st" : "2nd"} quarter`,
          style: "subjectLabel",
        },
        {
          text:
            sem === "1"
              ? registration.sem_one_days || "-"
              : registration.sem_two_days || "-",
          style: "gradeNormal",
        },
        {
          colSpan: 2,
          rowSpan: 2,
          text:
            sem === "1"
              ? [
                  { text: "Action Taken\n", style: "gradeNormal", fontSize: 6 },
                  {
                    text: registration.action_taken,
                    alignment: "center",
                    fontSize: 9,
                    bold: true,
                    margin: [0, 10, 0, 0],
                  },
                ]
              : [
                  {
                    text: "Eligible for Admission to\n",
                    style: "gradeNormal",
                    fontSize: 6,
                  },
                  {
                    text: registration.classified_as,
                    alignment: "center",
                    fontSize: 9,
                    bold: true,
                  },
                ],
        },
        {},
      ],
      [
        { text: "No. of Days present", style: "subjectLabel" },
        {
          text:
            sem === "1"
              ? registration.sem_one_present || "-"
              : registration.sem_two_present || "-",
          style: "gradeNormal",
        },
        {},
        {},
      ],
    ];
  };

  const emptyRows = (semester) => {
    return [
      [{ colSpan: 4, text: `School: `, style: "subjectLabelBG" }, {}, {}, {}],
      [
        { text: `Level : `, style: "subjectLabelBG" },
        {
          colSpan: 3,
          alignment: "center",
          text: `School Year: `,
          style: "subjectLabelBG",
        },
        {},
        {},
      ],
      [
        {
          colSpan: 4,
          text: semester,
          style: "header",
          fillColor: cellColor,
        },
        {},
        {},
        {},
      ],
      [
        {},
        {},
        {},
        { text: " ", fontSize: 7.5, margin: cellmargin, alignment: "center" },
      ],
      [
        {},
        {},
        {},
        { text: " ", fontSize: 7.5, margin: cellmargin, alignment: "center" },
      ],
      [
        {},
        {},
        {},
        { text: " ", fontSize: 7.5, margin: cellmargin, alignment: "center" },
      ],
      [
        {},
        {},
        {},
        { text: " ", fontSize: 7.5, margin: cellmargin, alignment: "center" },
      ],
      [
        {},
        {},
        {},
        { text: " ", fontSize: 7.5, margin: cellmargin, alignment: "center" },
      ],
      [
        {},
        {},
        {},
        { text: " ", fontSize: 7.5, margin: cellmargin, alignment: "center" },
      ],
      [
        {},
        {},
        {},
        { text: " ", fontSize: 7.5, margin: cellmargin, alignment: "center" },
      ],
      [
        {},
        {},
        {},
        { text: " ", fontSize: 7.5, margin: cellmargin, alignment: "center" },
      ],
      [
        {},
        {},
        {},
        { text: " ", fontSize: 7.5, margin: cellmargin, alignment: "center" },
      ],
      [
        {},
        {},
        {},
        { text: " ", fontSize: 7.5, margin: cellmargin, alignment: "center" },
      ],
      [
        {},
        {},
        {},
        { text: " ", fontSize: 7.5, margin: cellmargin, alignment: "center" },
      ],
      [
        {},
        {},
        {},
        { text: " ", fontSize: 7.5, margin: cellmargin, alignment: "center" },
      ],
      [
        {},
        {},
        {},
        { text: " ", fontSize: 7.5, margin: cellmargin, alignment: "center" },
      ],
      [{}, {}, {}, {}],
      [
        { text: "AVERAGE", style: "subjectLabel", bold: true },
        {
          text: " ",
          style: "gradeNormal",
        },
        {
          text: " ",
          style: "gradeNormal",
        },
        {
          text: " ",
          style: "gradeNormal",
        },
      ],
      [
        { text: "No. of school days for 1st quarter", style: "subjectLabel" },
        {
          text: " ",
          style: "gradeNormal",
        },
        {
          colSpan: 2,
          rowSpan: 2,
          text: " ",
          style: "header",
          margin: [0, 5, 0, 0],
        },
        {},
      ],
      [
        { text: "No. of Days present", style: "subjectLabel" },
        {
          text: " ",
          style: "gradeNormal",
        },
        {},
        {},
      ],
    ];
  };

  const documentDefinition = {
    background: {
      columns: [
        {
          alignment: "right",
          margin: [0, 10, 50, 0],
          image: depEdLogo,
          fit: [60, 60],
        },
        { margin: [100, 10, 0, 0], image: logo, fit: [60, 60] },
      ],
    },
    pageMargins: [10, 20, 10, 10],
    pageOrientation: "portrait",
    pageSize: "A4",
    content: [
      [
        {
          table: {
            widths: [120, "*", 120],
            body: [
              [
                { border: [false, false, false, false], text: "" },
                {
                  border: [false, false, false, false],
                  stack: [
                    {
                      text: "Republic of the Philippines",
                      style: "headerNormal",
                      pageBreak: "",
                    },
                    {
                      text: "Department of Education",
                      style: "headerNormal",
                    },
                    {
                      text: "Region IV-A Calabarzon",
                      style: "headerNormal",
                    },
                    {
                      text: "Division of Laguna",
                      style: "headerNormal",
                      margin: [0, 0, 0, 4],
                    },
                    {
                      text: "LITTLE SHEPHERD INTEGRATED MONTESSORI",
                      style: "header",
                    },
                    {
                      text: "LITTLE SHEPHERD BUSINESS AND SCIENCE HIGH SCHOOL",
                      style: "header",
                    },
                    {
                      text: "National Road, Brgy. Lewin, Lumban, Laguna",
                      style: "headerNormal",
                      pageBreak: "",
                    },
                    {
                      text: "SENIOR HIGH SCHOOL STUDENT PERMANENT RECORD",
                      style: "header",
                      margin: [0, 6, 0, 0],
                      fontSize: 12,
                    },
                    {
                      text: "School ID.: 402547",
                      style: "gradeNormal",
                      margin: [0, 0, 0, 5],
                    },
                  ],
                },
                {
                  border: [false, false, false, false],
                  margin: [12, 0, 0, 0],
                  image: imgPath,
                  fit: [100, 100],
                },
              ],
            ],
          },
        },
        {
          table: {
            widths: [70, "*", "*", "*"],
            body: [
              [
                {
                  text: `LRN: ${permanent.student.lrn}`,
                  style: "subjectLabelBG",
                },
                {
                  text: `Last Name: ${permanent.student.lastname}`,
                  style: "subjectLabelBG",
                },
                {
                  text: `First Name: ${permanent.student.firstname}`,
                  style: "subjectLabelBG",
                },
                {
                  text: `Middle Name: ${permanent.student.middlename}`,
                  style: "subjectLabelBG",
                },
              ],
            ],
          },
          layout: thinBorder,
        },
        {
          table: {
            widths: ["*", "*", 100, 158],
            body: [
              [
                {
                  text: `Date of Birth: ${moment(
                    permanent.student.birth_day
                  ).format("MMM DD, YYYY")}`,
                  style: "subjectLabelBG",
                },
                {
                  text: `Birth Place: ${permanent.student.birth_place}`,
                  style: "subjectLabelBG",
                },
                {
                  text: `Gender: ${
                    permanent.student.gender
                      ? titleCase(permanent.student.gender)
                      : ""
                  }`,
                  style: "subjectLabelBG",
                },
                {
                  text: `Date of SHS Admission: ${
                    otherDetails.admissionDate &&
                    otherDetails.admissionDate !== ""
                      ? moment(otherDetails.admissionDate).format("MMMM - YYYY")
                      : permanent.student.shs_regs_date
                      ? moment(permanent.student.shs_regs_date).format(
                          "MMMM - YYYY"
                        )
                      : "-"
                  }`,
                  style: "subjectLabelBG",
                },
              ],
            ],
          },
          layout: thinBorder,
        },
        {
          table: {
            widths: ["*", 295],
            body: [
              [
                {
                  text: `Parents: ${permanent.student.mother} / ${permanent.student.father}`,
                  style: "subjectLabelBG",
                },
                {
                  text: `Address: ${permanent.student.house_number || ""} ${
                    permanent.student.street_barangay || ""
                  } ${permanent.student.town_city || ""} ${
                    permanent.student.province || ""
                  }`,
                  style: "subjectLabelBG",
                },
              ],
            ],
          },
          layout: thinBorder,
        },
        {
          table: {
            widths: ["*", 120, 120],
            body: [
              [
                {
                  text: `Intermediate Completed at: ${
                    permanent.student.intermediate_completed || "-"
                  } `,
                  style: "subjectLabelBG",
                },
                {
                  text: `School year: ${
                    permanent.student.intermediate_sy || "-"
                  } `,
                  style: "subjectLabelBG",
                },
                {
                  text: `Gen Ave: ${
                    permanent.student.intermediate_average || "-"
                  }`,
                  style: "subjectLabelBG",
                },
              ],
            ],
          },
          layout: thinBorder,
        },
        {
          table: {
            widths: ["*", 120, 120],
            body: [
              [
                {
                  text: `Junior High School Completed at: ${
                    permanent.student.junior_completed || "-"
                  }`,
                  style: "subjectLabelBG",
                },
                {
                  text: `School year: ${permanent.student.junior_sy || "-"}`,
                  style: "subjectLabelBG",
                },
                {
                  text: `Gen Ave: ${permanent.student.junior_average || "-"}`,
                  style: "subjectLabelBG",
                },
              ],
            ],
          },
          layout: thinBorder,
        },
        {
          margin: [0, 6, 0, 0],
          table: {
            widths: [24, 120, "*", 24, 120],
            body: [
              [
                {
                  text: "Track: ",
                  style: "subjectLabelBG",
                  bold: true,
                  alignment: "center",
                  margin: [0, 4, 0, 0],
                },
                {
                  text: "ACADEMIC",
                  style: "subjectLabelBG",
                  bold: true,
                  alignment: "center",
                  margin: [0, 4, 0, 0],
                },
                {
                  text: "SCHOLASTIC RECORD",
                  style: "header",
                  margin: [0, 2, 0, 0],
                  fontSize: 12,
                  fillColor: cellColor,
                },
                {
                  text: "Strand: ",
                  style: "subjectLabelBG",
                  bold: true,
                  alignment: "center",
                  margin: [0, 4, 0, 0],
                },
                {
                  text: "GENERAL ACADEMIC",
                  style: "subjectLabelBG",
                  bold: true,
                  alignment: "center",
                  margin: [0, 4, 0, 0],
                },
              ],
            ],
          },
          layout: thinBorder,
        },
        {
          columns: [
            {
              margin: [0, 5, 0, 0],
              table: {
                widths: ["*", 28, 28, 40],
                body: permanent.registration["14"]
                  ? renderRows(permanent.registration["14"], "1")
                  : emptyRows("FIRST SEMESTER"), // grade 11 1st semester,
              },
              layout: thinBorder,
            },
            {
              margin: [0, 5, 0, 0],
              table: {
                widths: ["*", 28, 28, 40],
                body: permanent.registration["14"]
                  ? renderRows(permanent.registration["14"], "2")
                  : emptyRows("SECOND SEMESTER"), // grade 11 1st semester,,
              },
              layout: thinBorder,
            },
          ],
        },
        {
          columns: [
            {
              margin: [0, 5, 0, 0],
              table: {
                widths: ["*", 28, 28, 40],
                body: permanent.registration["15"]
                  ? renderRows(permanent.registration["15"], "1")
                  : emptyRows("FIRST SEMESTER"), // grade 11 1st semester,,
              },
              layout: thinBorder,
            },
            {
              margin: [0, 5, 0, 0],
              table: {
                widths: ["*", 35, 40, 40],
                body: permanent.registration["15"]
                  ? renderRows(permanent.registration["15"], "2")
                  : emptyRows("SECOND SEMESTER"), //renderRows(permanent.registration["15"], "1"), // grade 11 1st semester,,
              },
              layout: thinBorder,
            },
          ],
        },
        {
          margin: [0, 6, 0, 0],
          columns: [
            {
              table: {
                widths: ["*", 120],
                body: [
                  [
                    {
                      border: [true, true, true, false],
                      text: "Prepared By:",
                      style: "subjectLabel",
                      colSpan: 2,
                    },
                    {},
                  ],
                  [
                    {
                      border: [true, false, false, false],
                      text: " ______________________________",
                      style: "gradeNormal",
                      margin: [0, 6, 0, 0],
                    },
                    {
                      border: [false, false, true, false],
                      text: "Adviser",
                      decoration: "underline",
                      style: "gradeNormal",
                      margin: [0, 6, 0, 0],
                    },
                  ],
                  [
                    {
                      border: [true, false, false, true],
                      text: adviser_senior,
                      fontSize: 9,
                      alignment: "center",
                    },
                    {
                      border: [false, false, true, true],
                      text: "Designation",
                      style: "gradeNormal",
                    },
                  ],
                ],
              },
              layout: thinBorder,
            },
            {
              table: {
                widths: ["*", 120],
                body: [
                  [
                    {
                      border: [true, true, true, false],
                      text: "Certified True and Correct:",
                      style: "subjectLabel",
                      colSpan: 2,
                    },
                    {},
                  ],
                  [
                    {
                      border: [true, false, false, false],
                      text: " ______________________________",
                      style: "gradeNormal",
                      margin: [0, 6, 0, 0],
                    },
                    {
                      border: [false, false, true, false],
                      text: "Principal",
                      decoration: "underline",
                      style: "gradeNormal",
                      margin: [0, 6, 0, 0],
                    },
                  ],
                  [
                    {
                      border: [true, false, false, true],
                      text: principal_senior,
                      fontSize: 9,
                      alignment: "center",
                    },
                    {
                      border: [false, false, true, true],
                      text: "Designation",
                      style: "gradeNormal",
                    },
                  ],
                ],
              },
              layout: thinBorder,
            },
          ],
        },
        {
          margin: [0, 6, 0, 0],
          table: {
            widths: ["*", "*", 180],
            body: [
              [
                {
                  margin: 1,
                  border: [true, true, true, false],
                  text: [
                    {
                      text: `I certify that this is a true record of ${
                        permanent.student.lastname
                      }, ${permanent.student.firstname} ${
                        permanent.student.middlename
                      }. This student is eligible on this ${moment().format(
                        "Do"
                      )} day of ${moment().format("MMMM")}, for admission to ${
                        otherDetails.admissionTo
                      }, as `,
                      style: "subjectLabel",
                      margin: [0, 15, 0, 2],
                    },
                    {
                      text:
                        "regular /irregular student, and has no monetary nor property accountability in this school.",
                      style: "subjectLabel",
                    },
                  ],
                  style: "subjectLabel",
                  colSpan: 3,
                },
                {},
                {},
              ],
              [
                {
                  border: [true, false, false, false],
                  text: " ______________________________",
                  style: "gradeNormal",
                  margin: [0, 6, 0, 0],
                },
                {
                  border: [false, false, false, false],
                  text: "Director",
                  decoration: "underline",
                  style: "gradeNormal",
                  margin: [0, 6, 0, 0],
                },
                {
                  border: [false, false, true, false],
                  text: `${moment().format("MMMM DD, YYYY")}`,
                  decoration: "underline",
                  style: "gradeNormal",
                  margin: [0, 6, 0, 0],
                },
              ],
              [
                {
                  border: [true, false, false, true],
                  text: director,
                  fontSize: 9,
                  alignment: "center",
                },
                {
                  border: [false, false, false, true],
                  text: "Designation",
                  style: "gradeNormal",
                },
                {
                  border: [false, false, true, true],
                  text: "Date",
                  style: "gradeNormal",
                },
              ],
            ],
          },
          layout: thinBorder,
        },
        {
          margin: [0, 2, 0, 0],
          text: [
            { text: "REMARKS: ", fontSize: 9 },
            {
              text: otherDetails.remarks,
              fontSize: 9,
            },
          ],
        },
      ],
    ],
    styles: {
      headerNormal: {
        fontSize: 10,
        alignment: "center",
        lineHeight: 1,
      },
      header: {
        fontSize: 9,
        bold: true,
        alignment: "center",
        lineHeight: 1.1,
      },
      subheader: {
        fontSize: normalFontSize,
        bold: true,
        alignment: "center",
      },
      title: {
        fontSize: 10,
        bold: true,
        alignment: "center",
        margin: 10,
      },
      labelBold: {
        fontSize: 8.5,
        bold: true,
      },
      labelNormal: {
        fontSize: 8.5,
      },
      labelBoldItalic: {
        fontSize: 8.5,
        bold: true,
        italics: true,
        alignment: "center",
        margin: [0, 0, 0, 0],
      },
      subjectLabel: {
        fontSize: normalFontSize,
        // 			bold: true,
        margin: cellmargin,
      },
      subjectLabelIndent: {
        fontSize: normalFontSize,
        // 			bold: true,
        margin: [4, cellmargin, cellmargin, cellmargin],
      },
      subjectLabelBold: {
        fontSize: normalFontSize,
        bold: true,
        margin: cellmargin,
      },
      subjectLabelBG: {
        fontSize: normalFontSize,
        fillColor: cellColor,
        margin: cellmargin,
      },
      subjectSubLabel: {
        fontSize: normalFontSize,
        margin: [10, cellmargin, cellmargin, cellmargin],
        italics: true,
      },
      gradeBold: {
        fontSize: normalFontSize,
        bold: true,
        margin: cellmargin,
        alignment: "center",
      },
      gradeNormal: {
        fontSize: normalFontSize,
        margin: cellmargin,
        alignment: "center",
      },
      table: {
        margin: [0, 10, 0, 10],
      },
      averageFont: {
        fontSize: 11,
        bold: true,
        margin: cellmargin,
        alignment: "center",
      },
      listBold: {
        fontSize: 8,
        bold: true,
      },
      listBoldIndent: {
        fontSize: 8,
        bold: true,
        margin: [5, 0, 0, 0],
      },
      listNormal: {
        fontSize: 8,
      },
      subjectLabelSM: {
        fontSize: 7,
        margin: 1,
      },
      subjectLabelTiny: {
        fontSize: 6,
        alignment: "center",
      },
      gradeNormalSm: {
        fontSize: 7,
        alignment: "center",
      },
    },
  };

  try {
    pdfMake.createPdf(documentDefinition).open();
  } catch (error) {}

  //   pdfMake.createPdf(documentDefinition).print({}, window);
};
