import React, { memo } from "react";
import { Empty, Input, DatePicker, Select, Radio, Form, Typography } from "antd";
import { titleCase, upperCase, lowerCase } from "helpers/textTransformer";

const { Title } = Typography;
const { Option } = Select;

const DynamicForm = ({ mode, formStructure, fields, form, setHasChanges, hasChanges }) => {
  const handlesBlur = (e, param) => {
    const { id, value } = e.target;
    const { transform } = param;
    let newValue = value;
    if (transform !== "default") {
      switch (transform) {
        case "titlecase":
          newValue = titleCase(value);
          break;
        case "uppercase":
          newValue = upperCase(value);
          break;
        case "lowercase":
          newValue = lowerCase(value);
          break;
        default:
          newValue = value;
      }
      form.setFieldsValue({ [id]: newValue });
    }
  };
  const handlesOnFormValueChanged = () => {
    if (!hasChanges) {
      setHasChanges(true);
    }
  };

  const renderFormItems = () => {
    return formStructure.map((formItem) => {
      const inputType = formItem.type;
      const name = formItem.name;
      let formItemComponent = null;
      switch (inputType) {
        case "input":
          formItemComponent = (
            <Form.Item
              name={formItem.name}
              label={formItem.label}
              rules={[
                {
                  required: formItem.required,
                  message: `${formItem.label} is required!`,
                },
              ]}
            >
              <Input onBlur={(e) => handlesBlur(e, formItem)} />
            </Form.Item>
          );
          break;
        case "autocomplete":
          break;
        case "select":
          formItemComponent = (
            <Form.Item
              name={formItem.name}
              label={formItem.label}
              rules={[
                {
                  required: formItem.required,
                  message: `${formItem.label} is required!`,
                },
              ]}
            >
              <Select>
                {formItem.options.map((option) => {
                  return (
                    <Option value={option.value} key={option.value}>
                      {option.label}
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>
          );
          break;
        case "radio":
          formItemComponent = (
            <Form.Item
              form={form}
              name={formItem.name}
              label={formItem.label}
              rules={[
                {
                  required: formItem.required,
                  message: `${formItem.label} is required!`,
                },
              ]}
            >
              <Radio.Group>
                {formItem.options.map((option) => {
                  return (
                    <Radio value={option.value} key={option.value}>
                      {option.label}
                    </Radio>
                  );
                })}
              </Radio.Group>
            </Form.Item>
          );
          break;
        // case "checkbox":
        //   formItemComponent = (
        //     <FormItemCheckbox data={formItem} error={error} onInputChange={handleInputChange} />
        //   );
        //   break;
        case "datepicker":
          formItemComponent = (
            <Form.Item
              name={formItem.name}
              label={formItem.label}
              rules={[
                {
                  required: formItem.required,
                  message: `${formItem.label} is required!`,
                },
              ]}
            >
              <DatePicker />
            </Form.Item>
          );
          break;
        case "verticalSpace":
          formItemComponent = (
            <div key={name} style={{ padding: 10, textAlign: "center" }}>
              {mode === "builder" && "--vertical space--"}
            </div>
          );
          break;
        case "title":
          formItemComponent = (
            <div key={name}>
              <Title level={4} style={{ fontSize: 14 }} id={formItem.name}>
                {formItem.label}
              </Title>
            </div>
          );
          break;
        default:
          return null;
      }
      return <div key={name}>{formItemComponent}</div>;
    });
  };

  return (
    <>
      {formStructure.length === 0 ? (
        <Empty />
      ) : (
        <>
          <Form
            form={form}
            fields={fields}
            layout="horizontal"
            labelCol={{
              span: 8,
            }}
            wrapperCol={{
              span: 16,
            }}
            style={{ width: "100%" }}
            onValuesChange={handlesOnFormValueChanged}
          >
            {renderFormItems()}
          </Form>
        </>
      )}
    </>
  );
};

export default memo(DynamicForm);
