import React, { useRef, useState, useEffect } from "react";

import { useTable } from "react-table";
import { Input, Typography } from "antd";

import keys from "views/RatingSheet/components/keymap";
import { StylesFitToWindow } from "views/RatingSheet/components/Styles";

const { Text } = Typography;

// Create an editable cell renderer
const EditableCell = ({
  value: initialValue,
  row,
  column,
  updateMySecondaryData,
  primaryIndex,
}) => {
  const { index } = row;
  const { id, columnIndex, type } = column;
  const rowIndex = index;

  const inputRef = useRef();

  // We need to keep and update the state of the cell normally
  const [value, setValue] = React.useState(initialValue);

  const onChange = (e) => {
    let newValue = "";
    if (type === "input") {
      newValue = e.target.value;
    } else if (type === "number") {
      newValue = e.target.value.replace(/[^0-9.]/g, "").replace(/(\..*)\./g, "$1");
    }
    // if (newValue !== initialValue) {
    setValue(newValue);
    // }
  };

  // We'll only update the external data when the input is blurred
  const onBlur = () => {
    // setValue(parseFloat(value).toFixed(2));
    if (value !== initialValue) {
      if (type === "number") {
        let verifiedValue = parseFloat(value);
        if (verifiedValue) {
          if (verifiedValue < 65 || verifiedValue > 100) {
            console.log("les or greater");
            verifiedValue = parseFloat(initialValue);
          }
          setValue(verifiedValue.toFixed(3));
          updateMySecondaryData(index, id, verifiedValue.toFixed(3));
        } else {
          setValue("0.00");
          updateMySecondaryData(index, id, "0.00");
        }
      } else if (type === "input") {
        updateMySecondaryData(index, id, value);
      }
    }

    // updateMySecondaryData(index, id, value);
  };

  const onKeyDown = (e) => {
    const splitName = e.target.name.split("-");
    let rowI = parseInt(splitName[0]);
    let colI = parseInt(splitName[1]);
    let elementID = "";

    switch (e.keyCode) {
      case keys.ENTER:
      case keys.RIGHT:
        e.preventDefault();
        elementID = `${rowI}-${colI + 1}-sub-${primaryIndex}`;
        break;
      case keys.LEFT:
        e.preventDefault();

        elementID = `${rowI}-${colI - 1}-sub-${primaryIndex}`;
        break;
      case keys.UP:
        e.preventDefault();
        elementID = `${rowI - 1}-${colI}-sub-${primaryIndex}`;
        break;
      case keys.DOWN:
        e.preventDefault();
        elementID = `${rowI + 1}-${colI}-sub-${primaryIndex}`;
        break;
      default:
        return null;
    }

    let targetElement = document.getElementById(elementID);
    if (targetElement) {
      targetElement.select();
    }
  };

  // If the initialValue is changed external, sync it up with our state
  React.useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  return type === "number" || type === "input" ? (
    <Input
      value={value}
      id={`${rowIndex}-${columnIndex}-sub-${primaryIndex}`}
      ref={inputRef}
      onChange={onChange}
      onBlur={onBlur}
      onKeyDown={onKeyDown}
      name={`${rowIndex}-${columnIndex}-sub-${primaryIndex}`}
      maxLength={type === "number" ? "7" : "15"}
    />
  ) : (
    <Text className="secondary"> {value}</Text>
  );
};

// Set our editable cell renderer as the default Cell renderer
const defaultColumn = {
  Cell: EditableCell,
};

// Be sure to pass our updateMyData and the skipPageReset option
function Table({ columns, data, updateMySecondaryData, primaryIndex }) {
  // For this example, we're using pagination to illustrate how to stop
  // the current page from resetting when our data changes
  // Otherwise, nothing is different here.
  const {
    getTableProps,
    getTableBodyProps,
    // headerGroups,
    prepareRow,
    rows,
  } = useTable({
    columns,
    data,
    defaultColumn,
    // use the skipPageReset option to disable page resetting temporarily
    // updateMyData isn't part of the API, but
    // anything we put into these options will
    // automatically be available on the instance.
    // That way we can call this function from our
    // cell renderer!
    updateMySecondaryData,
    primaryIndex,
  });

  // Render the UI for your table
  return (
    <>
      <table {...getTableProps()} className="secondary">
        {/* <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => {
                return column.hideHeader === true ? null : (
                  <th {...column.getHeaderProps()}>{column.render("Header")}</th>
                );
              })}
            </tr>
          ))}
        </thead> */}
        <tbody {...getTableBodyProps()}>
          {rows.map((row, i) => {
            prepareRow(row);
            return (
              <React.Fragment key={i}>
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell) => {
                    return <td {...cell.getCellProps()}>{cell.render("Cell")}</td>;
                  })}
                </tr>
              </React.Fragment>
            );
          })}
        </tbody>
      </table>
    </>
  );
}

function EditableTableSecondary({ columns, rowData, updateMyData, primaryIndex }) {
  const [primaryData, setPrimaryData] = useState({});
  const [secondaryData, setSecondaryData] = useState([]);

  useEffect(() => {
    if (rowData) {
      // console.log(rowData.sub);
      //   console.log(rowData);
      setPrimaryData(rowData);
      setSecondaryData(rowData.sub);
    }
  }, [rowData]);

  const updateMySecondaryData = (rowIndex, columnId, value) => {
    // We also turn on the flag to not reset the page
    let newSecondaryData = secondaryData.map((row, index) => {
      if (index === rowIndex) {
        return {
          ...secondaryData[rowIndex],
          [columnId]: value,
        };
      }
      return row;
    });

    //  applcable for k12 only
    newSecondaryData[rowIndex].final_grade = (
      (parseFloat(newSecondaryData[rowIndex].first_grading) +
        parseFloat(newSecondaryData[rowIndex].second_grading) +
        parseFloat(newSecondaryData[rowIndex].third_grading) +
        parseFloat(newSecondaryData[rowIndex].fourth_grading)) /
      4
    ).toFixed(3);
    //  applcable for k12 only

    setSecondaryData(newSecondaryData);
    setPrimaryData({ ...primaryData, sub: newSecondaryData });
    updateMyData(primaryIndex, "sub", newSecondaryData);
  };

  return (
    <>
      {/* <Button
        onClick={() => {
          console.log(primaryData);
          console.log(secondaryData);
        }}
      >
        Save
      </Button> */}
      <StylesFitToWindow>
        <Table
          columns={columns}
          data={secondaryData}
          updateMySecondaryData={updateMySecondaryData}
          primaryIndex={primaryIndex}
        />
      </StylesFitToWindow>
    </>
  );
}

export default EditableTableSecondary;
