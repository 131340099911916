import React, { useState, useEffect } from "react";
import {
  Spin,
  Button,
  Space,
  Typography,
  Select,
  Form,
  Descriptions,
  Row,
  Col,
  message,
  Drawer,
} from "antd";
import {
  SaveOutlined,
  CloseCircleOutlined,
  EditOutlined,
  CalculatorOutlined,
} from "@ant-design/icons";

import { useMutation, queryCache } from "react-query";
import http from "services/httpService.js";

import noimage from "assets/images/no-image.png";

import EditableTablePrimary from "views/RatingSheet/components/EditableTablePrimary";

import {
  rsK12,
  rgK12,
  rsSR,
  rgSR,
  rsTraits,
  rsAttendance,
} from "views/RatingSheet/components/tableColumns";

import { useRatingSheetAllQuery } from "views/SYSubjects/hooks/Index";
import StudentForm from "views/Student/components/StudentForm/Index";

const { Title } = Typography;
const { Option } = Select;
function StudentGrades({ credentials, setDrawerVisibility }) {
  const [ratingCredentials, setRatingCredentials] = useState(credentials);

  const ratingColumnsK12 = React.useMemo(() => rsK12, []);
  const regsColumnsK12 = React.useMemo(() => rgK12, []);
  const ratingColumnsSR = React.useMemo(() => rsSR, []);
  const regsColumnsSR = React.useMemo(() => rgSR, []);
  const traitsColumns = React.useMemo(() => rsTraits, []);
  const attendanceColumns = React.useMemo(() => rsAttendance, []);

  const { isLoading, data: ratingSheetAllData } = useRatingSheetAllQuery(ratingCredentials);
  const [registrationData, setRegistrationData] = useState([]);
  const [subjectsData, setSubjectsData] = useState([]);
  const [traitsData, setTraitsData] = useState([]);
  const [attendanceData, setAttendanceData] = useState([]);
  const [student, setStudent] = useState({});

  const [isSaving, setIsSaving] = useState(false);

  const [studentDrawerVisibility, setStudentDrawerVisibility] = useState(false);

  useEffect(() => {
    if (ratingSheetAllData) {
      // console.log(ratingSheetAllData);
      setStudent(ratingSheetAllData.student);
      setRegistrationData(ratingSheetAllData.registration);
      setSubjectsData(ratingSheetAllData.subjects);
      setTraitsData(ratingSheetAllData.traits);
      setAttendanceData(ratingSheetAllData.attendance);
    }
  }, [ratingSheetAllData]);

  const [mutate] = useMutation(
    async (values) => {
      return await http.put(`ratingsheet/update`, { ...values });
    },
    {
      onSuccess: (data) => {
        // const { values, processType } = variables;
        // const { error  } = data.data;
        setIsSaving(false);
        message.success("Save successful!");
        console.log(data);
      },
      onError: () => {
        setIsSaving(false);
        message.warning("Unable to save, Something went wrong :/");
      },
      onSettled: () => {
        // queryCache.invalidateQueries([
        //   "registration",
        //   {
        //     employee_id: selectedSyLevel.employee_id,
        //     level_code: selectedSyLevel.level_code,
        //     sy: selectedSyLevel.sy,
        //   },
        // ]);
        // Modal.success({
        //   title: "Registration Successful",
        //   content:
        //     'You need to run "Process Rating Sheet" before you can manage grades of newly registered student',
        // });
      },
    }
  );

  // We need to keep the table from resetting the pageIndex when we
  // Update data. So we can keep track of that flag with a ref.

  // When our cell renderer calls updateMyData, we'll use
  // the rowIndex, columnId and new value to update the
  // original data
  const updateMyData = (rowIndex, columnId, value) => {
    // We also turn on the flag to not reset the page
    // console.log(rowIndex, columnId, value, isSub, primaryIndex);
    setSubjectsData((old) =>
      old.map((row, index) => {
        if (index === rowIndex) {
          return {
            ...old[rowIndex],
            [columnId]: value,
          };
        }
        return row;
      })
    );
  };

  const updateRegistrationData = (rowIndex, columnId, value) => {
    // We also turn on the flag to not reset the page
    // console.log(rowIndex, columnId, value, isSub, primaryIndex);
    setRegistrationData((old) =>
      old.map((row, index) => {
        if (index === rowIndex) {
          return {
            ...old[rowIndex],
            [columnId]: value,
          };
        }
        return row;
      })
    );
  };

  const updateTraitsData = (rowIndex, columnId, value) => {
    // We also turn on the flag to not reset the page
    // console.log(rowIndex, columnId, value, isSub, primaryIndex);
    setTraitsData((old) =>
      old.map((row, index) => {
        if (index === rowIndex) {
          return {
            ...old[rowIndex],
            [columnId]: value,
          };
        }
        return row;
      })
    );
  };

  const updateAttendanceData = (rowIndex, columnId, value) => {
    // We also turn on the flag to not reset the page
    // console.log(rowIndex, columnId, value, isSub, primaryIndex);
    setAttendanceData((old) =>
      old.map((row, index) => {
        if (index === rowIndex) {
          return {
            ...old[rowIndex],
            [columnId]: value,
          };
        }
        return row;
      })
    );
  };

  const handlesOnChange = (value) => {
    setRatingCredentials({ ...ratingCredentials, sem: parseInt(value) });
  };

  const handlesClose = () => {
    queryCache.removeQueries("ratingsheetall", { exact: true });
    setDrawerVisibility(false);
  };

  const handlesSave = () => {
    // ratingsheet/update
    setIsSaving(true);
    mutate({
      credentials: ratingCredentials,
      registration: registrationData[0],
      subjects: subjectsData,
      traits: traitsData,
      attendance: attendanceData,
    });
  };

  const handleEditStudent = () => {
    console.log("edit");
    setStudentDrawerVisibility(true);
  };

  const handlesComputeAverage = () => {
    const temp = Object.assign([], subjectsData);
    let first_grading = 0;
    let second_grading = 0;
    let third_grading = 0;
    let fourth_grading = 0;
    let primary_divisor = temp.length;
    temp.forEach((element, index) => {
      if (element.with_sub === 1) {
        let first_grading_sub = 0;
        let second_grading_sub = 0;
        let third_grading_sub = 0;
        let fourth_grading_sub = 0;
        let sub_divisor = element.sub.length;

        element.sub.forEach((subitem, subindex) => {
          // add all rows for averge
          first_grading_sub += parseFloat(subitem.first_grading);
          second_grading_sub += parseFloat(subitem.second_grading);
          third_grading_sub += parseFloat(subitem.third_grading);
          fourth_grading_sub += parseFloat(subitem.fourth_grading);

          //  get all column for final grade average
          let final_grade_sub =
            (parseFloat(subitem.first_grading) +
              parseFloat(subitem.second_grading) +
              parseFloat(subitem.third_grading) +
              parseFloat(subitem.fourth_grading)) /
            4;

          // put average to final grade sub actual object
          temp[index].sub[subindex].final_grade = final_grade_sub.toFixed(3);
        });

        let fgs = first_grading_sub / sub_divisor;
        let sgs = second_grading_sub / sub_divisor;
        let tgs = third_grading_sub / sub_divisor;
        let ftgs = fourth_grading_sub / sub_divisor;

        //  get average of all rows column and assign it to primary subject grades as string
        temp[index].first_grading = fgs.toFixed(3);
        temp[index].second_grading = sgs.toFixed(3);
        temp[index].third_grading = tgs.toFixed(3);
        temp[index].fourth_grading = ftgs.toFixed(3);
        temp[index].final_grade = ((fgs + sgs + tgs + ftgs) / 4).toFixed(3);

        //  get average of all rows column and add it to primary grading loops for averaging
        first_grading += fgs; //first_grading_sub / sub_divisor;
        second_grading += sgs; // second_grading_sub / sub_divisor;
        third_grading += tgs; //third_grading_sub / sub_divisor;
        fourth_grading += ftgs; //fourth_grading_sub / sub_divisor;
      } else {
        temp[index].final_grade = (
          (parseFloat(element.first_grading) +
            parseFloat(element.second_grading) +
            parseFloat(element.third_grading) +
            parseFloat(element.fourth_grading)) /
          4
        ).toFixed(3);

        first_grading += parseFloat(element.first_grading);
        second_grading += parseFloat(element.second_grading);
        third_grading += parseFloat(element.third_grading);
        fourth_grading += parseFloat(element.fourth_grading);
      }
    });

    let fave = first_grading / primary_divisor;
    let save = second_grading / primary_divisor;
    let tave = third_grading / primary_divisor;
    let ftave = fourth_grading / primary_divisor;

    setSubjectsData(temp);
    setRegistrationData((old) =>
      old.map((row, index) => {
        if (index === 0) {
          return {
            ...old[0],
            first_average: fave.toFixed(3),
            second_average: save.toFixed(3),
            third_average: tave.toFixed(3),
            fourth_average: ftave.toFixed(3),
            final_average: ((fave + save + tave + ftave) / 4).toFixed(3),
            final_grade: ((fave + save + tave + ftave) / 4).toFixed(3),
          };
        }
        return row;
      })
    );
    // console.log(temp);
  };

  const handlesComputeAverageSR = () => {
    const { sem } = ratingCredentials;
    console.log(ratingCredentials);
    const temp = Object.assign([], subjectsData);
    let first_grading = 0;
    let second_grading = 0;
    let primary_divisor = temp.length;
    temp.forEach((element, index) => {
      temp[index].final_grade = (
        (parseFloat(element.first_grading) + parseFloat(element.second_grading)) /
        2
      ).toFixed(3);

      first_grading += parseFloat(element.first_grading);
      second_grading += parseFloat(element.second_grading);
    });

    let fave = first_grading / primary_divisor;
    let save = second_grading / primary_divisor;

    setSubjectsData(temp);
    if (sem === 1) {
      console.log("sem 1");
      setRegistrationData((old) =>
        old.map((row, index) => {
          if (index === 0) {
            return {
              ...old[0],
              sem_one_first_ave: fave.toFixed(3),
              sem_one_second_ave: save.toFixed(3),
              sem_one_ave: ((fave + save) / 2).toFixed(3),
            };
          }
          return row;
        })
      );
    } else {
      console.log("sem 2");
      setRegistrationData((old) =>
        old.map((row, index) => {
          if (index === 0) {
            return {
              ...old[0],
              sem_two_first_ave: fave.toFixed(3),
              sem_two_second_ave: save.toFixed(3),
              sem_two_ave: ((fave + save) / 2).toFixed(3),
            };
          }
          return row;
        })
      );
    }

    // console.log(temp);
  };

  return isLoading ? (
    <div className="spinner-container">
      <Spin tip="Loading..." />
    </div>
  ) : (
    <>
      <Spin tip="Saving, please wait..." spinning={isSaving}>
        <Row gutter={16}>
          <Col span={10}>
            <Space style={{ marginBottom: 10 }}>
              <Button
                onClick={handlesClose}
                //   type="warning"
                icon={<CloseCircleOutlined />}
              >
                Close
              </Button>
              <Button
                onClick={() => {
                  handlesSave();
                }}
                type="primary"
                icon={<SaveOutlined />}
              >
                Save
              </Button>
              {registrationData.length > 0 && ratingCredentials.school_level_category_id === 2 ? (
                <Form
                  layout={{
                    labelCol: { span: 8 },
                    wrapperCol: { span: 16 },
                  }}
                  style={{ padding: "14px 14px 0px 14px", border: "1px solid #91d5ff" }}
                >
                  <Form.Item label="Select Semester">
                    <Select
                      defaultValue={ratingCredentials.sem.toString()}
                      style={{ width: 200 }}
                      onChange={handlesOnChange}
                    >
                      <Option value="1">First Semester</Option>
                      <Option value="2">Second Semester</Option>
                    </Select>
                  </Form.Item>
                </Form>
              ) : null}
            </Space>
          </Col>
          <Col span={14}>
            {student ? (
              <Row gutter={8}>
                <Col span={7}>
                  <img
                    width={160}
                    src={student.img_path ? student.img_path.replace(".jpg", "-card.jpg") : noimage}
                    style={{ borderRadius: 10 }}
                    alt="Rating Sheet Students"
                  />
                </Col>
                <Col span={17}>
                  <Descriptions bordered size="small">
                    <Descriptions.Item
                      label={
                        <Space>
                          <span>Name</span>
                          <Button type="link" icon={<EditOutlined />} onClick={handleEditStudent} />
                        </Space>
                      }
                      span={3}
                    >
                      {student.lastname}, {student.firstname} {student.middlename}
                    </Descriptions.Item>
                    <Descriptions.Item label="LRN" span={3}>
                      {student.lrn || "{none}"}
                    </Descriptions.Item>
                    <Descriptions.Item label="SY / Level" span={3}>
                      {registrationData.length > 0 ? registrationData[0].sy : ""} /
                      {registrationData.length > 0 ? registrationData[0].level : ""}
                    </Descriptions.Item>
                    <Descriptions.Item label="Teacher" span={3}>
                      {registrationData.length > 0 ? registrationData[0].employee : ""}
                    </Descriptions.Item>
                  </Descriptions>
                </Col>
              </Row>
            ) : null}
          </Col>
        </Row>

        <Space>
          <Title level={4} style={{ paddingTop: 6 }}>
            Other Record
          </Title>
          <Button
            icon={<CalculatorOutlined />}
            type="dashed"
            onClick={() =>
              ratingCredentials.school_level_category_id === 1
                ? handlesComputeAverage()
                : handlesComputeAverageSR()
            }
          >
            Compute Average
          </Button>
        </Space>

        <EditableTablePrimary
          columns={
            ratingCredentials.school_level_category_id === 1 ? regsColumnsK12 : regsColumnsSR
          }
          rowData={registrationData}
          updateMyData={updateRegistrationData}
          suffix={"regs"}
          fullWidth={true}
        />
        <div style={{ marginBottom: 20 }}></div>

        <Title level={4}>Subjects</Title>
        <EditableTablePrimary
          columns={
            ratingCredentials.school_level_category_id === 1 ? ratingColumnsK12 : ratingColumnsSR
          }
          rowData={subjectsData}
          updateMyData={updateMyData}
          enableSub={true}
          suffix={"subject"}
          category={ratingCredentials.school_level_category_id}
        />

        <div style={{ marginBottom: 20 }}></div>

        <Title level={4}>Traits</Title>
        <EditableTablePrimary
          columns={traitsColumns}
          rowData={traitsData}
          updateMyData={updateTraitsData}
          suffix={"traits"}
        />

        <div style={{ marginBottom: 20 }}></div>
        <Title level={4}>Attendance</Title>
        <EditableTablePrimary
          columns={attendanceColumns}
          rowData={attendanceData}
          updateMyData={updateAttendanceData}
          suffix={"att"}
        />
      </Spin>

      <Drawer
        width={840}
        placement="right"
        closable={false}
        // onClose={() => setDrawerVisibility(false)}
        visible={studentDrawerVisibility}
        destroyOnClose={true}
      >
        <StudentForm
          currentData={student}
          isEditing={true}
          setDrawerVisibility={setStudentDrawerVisibility}
          isEditedFromOthercomponent={true}
          setStudent={setStudent}
          // student={student}
        />
      </Drawer>
    </>
  );
}

export default StudentGrades;
