import React, { useState, useCallback, useRef } from "react";
import {
  Table,
  Button,
  Tag,
  Space,
  Typography,
  Form,
  Select,
  Drawer,
  message,
  Row,
  Col,
  Empty,
  Alert,
  Popconfirm,
} from "antd";
import { DndProvider, useDrag, useDrop, createDndContext } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import update from "immutability-helper";
import { useMutation } from "react-query";
import http from "services/httpService.js";
import moment from "moment";
import { DeleteOutlined, PlusOutlined, SaveOutlined } from "@ant-design/icons";
import { useEffect } from "react";

import { useSchoolLevelCategoryQuery, useSYTraits, useTraits } from "views/SYSubjects/hooks/Index";

const RNDContext = createDndContext(HTML5Backend);
const type = "DragableBodyRow";
const { Option } = Select;
const { Text } = Typography;

// for extraction
const DragableBodyRow = ({ index, moveRow, className, style, ...restProps }) => {
  const ref = React.useRef();
  const [{ isOver, dropClassName }, drop] = useDrop({
    accept: type,
    collect: (monitor) => {
      const { index: dragIndex } = monitor.getItem() || {};
      if (dragIndex === index) {
        return {};
      }
      return {
        isOver: monitor.isOver(),
        dropClassName: dragIndex < index ? " drop-over-downward" : " drop-over-upward",
      };
    },

    drop: (item) => {
      moveRow(item.index, index);
    },
  });
  const [, drag] = useDrag({
    item: { type, index },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });
  drop(drag(ref));
  return (
    <tr
      ref={ref}
      className={`${className}${isOver ? dropClassName : ""}`}
      style={{ cursor: "move", ...style }}
      {...restProps}
    />
  );
};
// for extraction
const SelectionForm = ({ form, selectedSyLevelCategory, setSelectedSyLevelCategory }) => {
  const { isLoading, data } = useSchoolLevelCategoryQuery();
  const sySelections = generateSy();
  function generateSy() {
    let yearArray = [];
    let yearNow = Number(moment().format("YYYY"));
    for (let i = yearNow; i > 2000; i--) {
      yearArray.push(`${i}-${i + 1}`);
    }
    return yearArray;
  }

  const onSelectionChange = (value, option, type) => {
    switch (type) {
      case "sy":
        setSelectedSyLevelCategory({ ...selectedSyLevelCategory, sy: value });
        break;
      case "school_level_category_id":
        setSelectedSyLevelCategory({
          ...selectedSyLevelCategory,
          school_level_category_id: value,
          school_level_category: option.children,
        });
        break;
      default:
        return;
    }
  };
  return (
    <Form layout="inline" form={form}>
      <Form.Item label="Select School Year" name="sy">
        <Select
          placeholder="none"
          style={{ width: 150 }}
          onChange={(value, option) => {
            onSelectionChange(value, option, "sy");
          }}
          rules={[
            {
              required: true,
              message: "Please select School Year!",
            },
          ]}
        >
          {sySelections.map((item) => {
            return (
              <Option value={item} key={item}>
                {item}
              </Option>
            );
          })}
        </Select>
      </Form.Item>

      <Form.Item label="Select Level Category" name="school_level_category_id">
        <Select
          placeholder="none"
          style={{ width: 180 }}
          loading={isLoading}
          onChange={(value, option) => {
            onSelectionChange(value, option, "school_level_category_id");
          }}
          rules={[
            {
              required: true,
              message: "Please select Level!",
            },
          ]}
        >
          {isLoading
            ? null
            : data.map((item, index) => {
                return (
                  <Option value={item.school_level_category_id} key={item.school_level_category_id}>
                    {item.school_level_category}
                  </Option>
                );
              })}
        </Select>
      </Form.Item>
    </Form>
  );
};

// for extraction
const TraitsSelection = ({
  modalVisibility,
  setModalVisibility,
  traitsSYCodes,
  handlesAddTraits,
}) => {
  const columns = [
    {
      title: "Traits Code",
      dataIndex: "traits_code",
      key: "traits_code",
      render: (text, record) => {
        return (
          <span className={traitsSYCodes.includes(record.traits_code) ? "disabled-row" : ""}>
            {record.traits_code}
          </span>
        );
      },
    },
    {
      title: "Traits",
      dataIndex: "traits",
      key: "traits",
      render: (text, record) => (
        <span className={traitsSYCodes.includes(record.traits_code) ? "disabled-row" : ""}>
          {record.traits}
        </span>
      ),
    },
  ];
  const { data: traitsData } = useTraits();
  const [tableData, setTableData] = useState([]);

  useEffect(() => {
    setTableData(traitsData);
  }, [traitsData]);

  const handlesRowClick = (event, record) => {
    if (traitsSYCodes.includes(record.traits_code)) {
      message.warning("Already in the list. ");
    } else {
      handlesAddTraits(record);
    }
  };
  return (
    <Drawer
      title="Add Traits"
      width={600}
      placement="right"
      closable={true}
      onClose={() => setModalVisibility(false)}
      visible={modalVisibility}
      footer={
        <div
          style={{
            textAlign: "right",
          }}
        >
          <Button onClick={() => setModalVisibility(false)} type="primary">
            Close
          </Button>
        </div>
      }
    >
      <Table
        columns={columns}
        dataSource={tableData}
        rowKey="traits_code"
        pagination={false}
        size="small"
        onRow={(record, rowIndex) => {
          return {
            onClick: (event) => {
              handlesRowClick(event, record);
            }, // click row
          };
        }}
      />
    </Drawer>
  );
};

const SYTraits = () => {
  const [selectionForm] = Form.useForm();
  const [selectedSyLevelCategory, setSelectedSyLevelCategory] = useState({
    sy: null,
    school_level_category: null,
    school_level_category_id: null,
  });
  const [modalVisibility, setModalVisibility] = useState(false);
  const [hasSelection, setHasSelection] = useState(false);

  //------------------
  const [traitsSYCodes, setTraitsSYCodes] = useState([]);

  const [enableMainQuery, setEnableMainQuery] = useState(false);
  const [data, setData] = useState([]);

  //------------------
  const { isLoading, data: syTraitsData } = useSYTraits(selectedSyLevelCategory, {
    enabled: enableMainQuery,
  });

  //------------------
  const manager = useRef(RNDContext);

  const [isLocked, setIsLocked] = useState();

  //------------------
  const [mutate] = useMutation(
    async (values) => {
      console.log(values);
      await http.put(
        `traitssy/update/${selectedSyLevelCategory.sy}/${selectedSyLevelCategory.school_level_category_id}`,
        {
          values: values,
        }
      );
    },
    {
      onSuccess: (data, variables) => {
        message.success("Record saved.");
      },
      onError: () => {
        message.warning("Unable to save, Something went wrong :/");
      },
    }
  );

  const columns = [
    {
      title: "",
      name: "numbering",
      dataIndex: "subject",
      width: 60,
      render: (text, record, index) => <span style={{ paddingLeft: 10 }}>{index + 1}</span>,
    },

    {
      title: () => (
        <Space>
          <Text>Traits</Text>
          {selectedSyLevelCategory.sy && selectedSyLevelCategory.school_level_category_id ? (
            <Text>
              {`for (SY - ${selectedSyLevelCategory.sy}) (Level Category - ${selectedSyLevelCategory.school_level_category})`}
            </Text>
          ) : null}
        </Space>
      ),
      name: "subject",
      dataIndex: "subject",
      //   width: 300,
      render: (text, record) => {
        return (
          <Space>
            <span>{record.traits}</span>
            {record.with_sub === 1 ? (
              <Tag color="geekblue">with sub ({record.children.length})</Tag>
            ) : null}
          </Space>
        );
      },
    },
    {
      title: isLocked ? null : (
        <Button
          shape="circle"
          icon={<PlusOutlined />}
          type="primary"
          disabled={!hasSelection}
          onClick={() => {
            setModalVisibility(true);
          }}
        ></Button>
      ),
      // <Button
      //   shape="circle"
      //   icon={<ReloadOutlined />}
      //   type="primary"
      //   disabled={!hasSelection}
      // ></Button>
      key: "manage",
      dataIndex: "manage",
      width: 60,
      render: (text, record, index) => {
        if (data.length <= 0) {
          return null;
        } else {
          return isLocked ? null : (
            <Popconfirm
              title="Sure to delete?"
              onConfirm={() => handlesDeleteTraits(record, index)}
            >
              <Button type="link" icon={<DeleteOutlined />} />
            </Popconfirm>
          );
        }
      },
    },
  ];

  useEffect(() => {
    if (selectedSyLevelCategory.sy && selectedSyLevelCategory.school_level_category_id) {
      setHasSelection(true);
      setEnableMainQuery(true);
    }
    if (syTraitsData) {
      setData(syTraitsData.traitsSY);
      setTraitsSYCodes(syTraitsData.traitsSYCodes);
      setIsLocked(syTraitsData.isLocked);
    }
  }, [syTraitsData, selectedSyLevelCategory.sy, selectedSyLevelCategory.school_level_category_id]);

  const components = {
    body: {
      row: DragableBodyRow,
    },
  };

  const moveRow = useCallback(
    (dragIndex, hoverIndex) => {
      const dragRow = data[dragIndex];
      setData(
        update(data, {
          $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, dragRow],
          ],
        })
      );
    },
    [data]
  );

  const handlesDeleteTraits = (record, index) => {
    setData(
      update(data, {
        $splice: [[index, 1]],
      })
    );
  };

  const handlesAddTraits = (record) => {
    setData([...data, { traits_code: record.traits_code, traits: record.traits }]);
    setTraitsSYCodes([...traitsSYCodes, record.traits_code]);
  };

  const handlesSave = (event) => {
    event.preventDefault();

    if (data.length <= 0) {
      message.warning("Save failed, Traits list cannot be empty.");
    } else {
      mutate([...data]);
    }
  };
  return (
    <>
      <SelectionForm
        form={selectionForm}
        selectedSyLevelCategory={selectedSyLevelCategory}
        setSelectedSyLevelCategory={setSelectedSyLevelCategory}
      />

      {!hasSelection ? (
        <Empty description="Select School Year And Level" style={{ marginTop: 60 }} />
      ) : (
        <>
          {isLocked ? (
            <Alert
              message="Locked!"
              description="This SY / Category is locked, you cannot make any changes once student traits has been created. "
              type="warning"
              showIcon
              style={{ marginBottom: 20 }}
            />
          ) : null}
          <DndProvider manager={manager.current.dragDropManager}>
            <Table
              columns={columns}
              dataSource={data}
              components={components}
              onRow={(record, index) => ({
                index,
                moveRow,
              })}
              pagination={false}
              rowKey="traits_code"
              loading={isLoading}
              size="small"
              //   style={{ pointerEvents: "none", opacity: "0.4" }}
            />
          </DndProvider>

          <Row gutter={8} style={{ marginTop: 20 }}>
            <Col>
              <Alert
                message="Please click save button to apply changes on Traits. "
                type="info"
                showIcon
              />
            </Col>
            <Col>
              <Space>
                <Button
                  icon={<SaveOutlined />}
                  type="primary"
                  block
                  onClick={(event) => {
                    handlesSave(event);
                  }}
                  size="large"
                  disabled={isLocked ? true : !hasSelection}
                >
                  Save
                </Button>
                {/* <Button
                  icon={<CopyOutlined />}
                  block
                  onClick={(event) => {
                    console.log("copy to ");
                  }}
                  size="large"
                  disabled={!hasSelection}
                >
                  Copy To
                </Button> */}
              </Space>
            </Col>
          </Row>
        </>
      )}
      <TraitsSelection
        modalVisibility={modalVisibility}
        setModalVisibility={setModalVisibility}
        traitsSYCodes={traitsSYCodes}
        handlesAddTraits={handlesAddTraits}
      />
    </>
  );
};

export default SYTraits;
