import pdfMake from "pdfmake/build/pdfmake";
import vfsFonts from "pdfmake/build/vfs_fonts";
import { queryCache } from "react-query";

export default (data) => {
  const { vfs } = vfsFonts.pdfMake;
  pdfMake.vfs = vfs;

  // playground requires you to assign document definition to a variable called dd
  var cellmargin = 1.2;
  var normalFontSize = 7.5;
  var tableLayout = {
    hLineWidth: function (i, node) {
      return i === 0 || i === node.table.body.length ? 0.1 : 0.1;
    },
    vLineWidth: function (i, node) {
      return i === 0 || i === node.table.widths.length ? 0.1 : 0.1;
    },
  };
  const { director, principal_pre_elem, principal_elem, principal_highschool } =
    queryCache.getQueryData("settings");

  const documentDefinition = {
    pageMargins: [10, 20, 10, 5],
    pageOrientation: "landscape",
    pageSize: "LETTER",
    content: [
      data.map((d, index) => {
        return [
          {
            table: {
              widths: [160, "*", 160],
              body: [
                [
                  { border: [false, false, false, false], text: "" },
                  {
                    border: [false, false, false, false],
                    stack: [
                      {
                        text: " ",
                        style: "header",
                        pageBreak: index !== 0 ? "before" : "",
                      },
                      {
                        text: "LITTLE SHEPHERD INTEGRATED MONTESSORI",
                        style: "header",
                      },
                      {
                        text: "LITTLE SHEPHERD BUSINESS AND SCIENCE HIGH SCHOOL",
                        style: "header",
                      },
                      {
                        text: "Lumban, Laguna",
                        style: "subheader",
                      },
                      {
                        text: "PROGRESS REPORT CARD",
                        style: "title",
                      },
                    ],
                  },
                  {
                    border: [false, false, false, false],
                    margin: [20, 0, 0, 0],
                    image: d.img_path,
                    fit: [85, 85],
                  },
                ],
              ],
            },
          },
          {
            width: "*",
            margin: [30, 0, 20, 0],
            columns: [
              {
                width: 160,
                margin: [0, 0, 20, 0],
                columns: [
                  {
                    width: 28,
                    text: "LRN.:",
                    style: "labelNormal",
                  },
                  {
                    width: "*",
                    text: d.lrn,
                    style: "studentDetails",
                    // decoration: "underline",
                    // fontSize: 10,
                  },
                ],
              },
              {
                width: "*",
                columns: [
                  {
                    width: 28,
                    text: "Name:",
                    style: "labelNormal",
                  },
                  {
                    width: "*",
                    text: d.fullname.toUpperCase(),
                    style: "studentDetails",
                    // decoration: "underline",
                    // fontSize: 10,
                  },
                ],
              },
              {
                width: 160,
                margin: [20, 0, 20, 0],
                columns: [
                  {
                    width: 28,
                    text: "Level:",
                    style: "labelNormal",
                    fontSize: 10,
                  },
                  {
                    width: "*",
                    text: d.level.toUpperCase(),
                    style: "studentDetails",
                    // decoration: "underline",
                    // fontSize: 10,
                  },
                ],
              },
              {
                width: 160,
                margin: [20, 0, 20, 0],
                columns: [
                  {
                    width: 60,
                    text: "School Year:",
                    style: "labelNormal",
                  },
                  {
                    width: "*",
                    text: d.sy,
                    style: "studentDetails",
                    // decoration: "underline",
                    // fontSize: 10,
                  },
                ],
              },
            ],
          },
          {
            width: "*",
            columns: [
              {
                width: 228,
                // margin: [0, 10, 20, 2],
                text: "",
              },
              {
                width: 120,
                text: "PERIODIC RATING",
                style: "title2",
              },
              {
                width: 180,
                // margin: [0, 10, 20, 2],
                text: "GRADING SYSTEM:  AVERAGING",
                style: "title2",
              },
            ],
          },
          {
            columns: [
              {
                width: 500,
                margin: [0, 0, 20, 0],
                table: {
                  widths: ["*", 38, 38, 38, 38, 45, 50],
                  body: d.subjects,
                },
                layout: tableLayout,
              },
              [
                {
                  margin: [0, 10, 0, 2],
                  text: "Character Building Activities",
                  style: "subheader",
                },
                {
                  table: {
                    widths: ["*", 15, 15, 15, 15],
                    body: [
                      [
                        { text: "Traits", style: "subjectLabel" },
                        { text: "1", style: "gradeNormal" },
                        { text: "2", style: "gradeNormal" },
                        { text: "3", style: "gradeNormal" },
                        { text: "4", style: "gradeNormal" },
                      ],
                      ...d.traits.map((trait) => {
                        return [
                          { text: trait.traits, style: "subjectLabel" },
                          { text: trait.first, style: "gradeNormal" },
                          { text: trait.second, style: "gradeNormal" },
                          { text: trait.third, style: "gradeNormal" },
                          { text: trait.fourth, style: "gradeNormal" },
                        ];
                      }),
                      // ,
                      // [
                      //   { text: "Honesty", style: "subjectLabel" },
                      //   { text: "A", style: "gradeNormal" },
                      //   { text: "B", style: "gradeNormal" },
                      //   { text: "C", style: "gradeNormal" },
                      //   { text: "D", style: "gradeNormal" },
                      // ],
                      // [
                      //   { text: "Honesty", style: "subjectLabel" },
                      //   { text: "A", style: "gradeNormal" },
                      //   { text: "B", style: "gradeNormal" },
                      //   { text: "C", style: "gradeNormal" },
                      //   { text: "D", style: "gradeNormal" },
                      // ],
                      // [
                      //   { text: "Honesty", style: "subjectLabel" },
                      //   { text: "A", style: "gradeNormal" },
                      //   { text: "B", style: "gradeNormal" },
                      //   { text: "C", style: "gradeNormal" },
                      //   { text: "D", style: "gradeNormal" },
                      // ],
                      // [
                      //   { text: "Honesty", style: "subjectLabel" },
                      //   { text: "A", style: "gradeNormal" },
                      //   { text: "B", style: "gradeNormal" },
                      //   { text: "C", style: "gradeNormal" },
                      //   { text: "D", style: "gradeNormal" },
                      // ],
                      // [
                      //   { text: "Honesty", style: "subjectLabel" },
                      //   { text: "A", style: "gradeNormal" },
                      //   { text: "B", style: "gradeNormal" },
                      //   { text: "C", style: "gradeNormal" },
                      //   { text: "D", style: "gradeNormal" },
                      // ],
                      // [
                      //   { text: "Honesty", style: "subjectLabel" },
                      //   { text: "A", style: "gradeNormal" },
                      //   { text: "B", style: "gradeNormal" },
                      //   { text: "C", style: "gradeNormal" },
                      //   { text: "D", style: "gradeNormal" },
                      // ],
                      // [
                      //   { text: "Honesty", style: "subjectLabel" },
                      //   { text: "A", style: "gradeNormal" },
                      //   { text: "B", style: "gradeNormal" },
                      //   { text: "C", style: "gradeNormal" },
                      //   { text: "D", style: "gradeNormal" },
                      // ],
                      // [
                      //   { text: "Honesty", style: "subjectLabel" },
                      //   { text: "A", style: "gradeNormal" },
                      //   { text: "B", style: "gradeNormal" },
                      //   { text: "C", style: "gradeNormal" },
                      //   { text: "D", style: "gradeNormal" },
                      // ],
                      // [
                      //   { text: "Honesty", style: "subjectLabel" },
                      //   { text: "A", style: "gradeNormal" },
                      //   { text: "B", style: "gradeNormal" },
                      //   { text: "C", style: "gradeNormal" },
                      //   { text: "D", style: "gradeNormal" },
                      // ],
                      // [
                      //   { text: "Honesty", style: "subjectLabel" },
                      //   { text: "A", style: "gradeNormal" },
                      //   { text: "B", style: "gradeNormal" },
                      //   { text: "C", style: "gradeNormal" },
                      //   { text: "D", style: "gradeNormal" },
                      // ],
                    ],
                  },
                  layout: tableLayout,
                },
                {
                  margin: [0, 10, 0, 0],
                  table: {
                    widths: ["*", 60, 75],
                    body: [
                      [
                        { text: "Legend", style: "listBold" },
                        { text: " ", style: "gradeNormal" },
                        { text: "Guidelines for Rating", style: "listBold" },
                      ],
                      [
                        { text: "Beginning (B)", style: "listBold" },
                        { text: "74% and Below", style: "gradeNormal" },
                        { text: "A - Very Good", style: "gradeNormal" },
                      ],
                      [
                        { text: "Developing (D)", style: "listBold" },
                        { text: "75-79%", style: "gradeNormal" },
                        { text: "B - good", style: "gradeNormal" },
                      ],
                      [
                        {
                          text: "Approaching Proficiency (AP)",
                          style: "listBold",
                        },
                        { text: "80-84%", style: "gradeNormal" },
                        { text: "C - Fair", style: "gradeNormal" },
                      ],
                      [
                        { text: "Proficient (P)", style: "listBold" },
                        { text: "85-89%", style: "gradeNormal" },
                        { text: "D - Poor", style: "gradeNormal" },
                      ],
                      [
                        { text: "Advanced (A)", style: "listBold" },
                        { text: "90% and Above", style: "gradeNormal" },
                        { text: " ", style: "gradeNormal" },
                      ],
                    ],
                  },
                  layout: tableLayout,
                },
              ],
            ],
          },
          {
            columns: [
              {
                width: 500,
                margin: [0, 10, 20, 2],
                text: "Attendance Record",
                style: "subheader",
              },
              {},
            ],
          },
          {
            columns: [
              {
                width: 500,
                margin: [0, 0, 20, 0],
                table: {
                  widths: ["*", 20, 20, 20, 20, 20, 20, 20, 20, 20, 20, 20, 20, 35],
                  body: [
                    [
                      { text: "", style: "subjectLabel" },
                      { text: "AUG", style: "gradeNormal" },
                      { text: "SEP", style: "gradeNormal" },
                      { text: "OCT", style: "gradeNormal" },
                      { text: "NOV", style: "gradeNormal" },
                      { text: "DEC", style: "gradeNormal" },
                      { text: "JAN", style: "gradeNormal" },
                      { text: "FEB", style: "gradeNormal" },
                      { text: "MAR", style: "gradeNormal" },
                      { text: "APR", style: "gradeNormal" },
                      { text: "MAY", style: "gradeNormal" },
                      { text: "JUN", style: "gradeNormal" },
                      { text: "JUL", style: "gradeNormal" },
                      { text: "TOTAL", style: "gradeNormal" },
                    ],
                    ...d.attendance.map((att) => {
                      return [
                        { text: att.attendance, style: "subjectLabel" },
                        {
                          text: Number(att.aug).toFixed(0),
                          style: "gradeNormal",
                        },
                        {
                          text: Number(att.sep).toFixed(0),
                          style: "gradeNormal",
                        },
                        {
                          text: Number(att.oct).toFixed(0),
                          style: "gradeNormal",
                        },
                        {
                          text: Number(att.nov).toFixed(0),
                          style: "gradeNormal",
                        },
                        {
                          text: Number(att.dec).toFixed(0),
                          style: "gradeNormal",
                        },
                        {
                          text: Number(att.jan).toFixed(0),
                          style: "gradeNormal",
                        },
                        {
                          text: Number(att.feb).toFixed(0),
                          style: "gradeNormal",
                        },
                        {
                          text: Number(att.mar).toFixed(0),
                          style: "gradeNormal",
                        },
                        {
                          text: Number(att.apr).toFixed(0),
                          style: "gradeNormal",
                        },
                        {
                          text: Number(att.may).toFixed(0),
                          style: "gradeNormal",
                        },
                        {
                          text: Number(att.jun).toFixed(0),
                          style: "gradeNormal",
                        },
                        {
                          text: Number(att.jul).toFixed(0),
                          style: "gradeNormal",
                        },
                        {
                          text: Number(att.total_attendance).toFixed(0),
                          style: "gradeNormal",
                        },
                      ];
                    }),

                    // [
                    //   { text: "No. of School Days", style: "subjectLabel" },
                    //   { text: "2", style: "gradeNormal" },
                    //   { text: "20", style: "gradeNormal" },
                    //   { text: "20", style: "gradeNormal" },
                    //   { text: "20", style: "gradeNormal" },
                    //   { text: "20", style: "gradeNormal" },
                    //   { text: "20", style: "gradeNormal" },
                    //   { text: "20", style: "gradeNormal" },
                    //   { text: "20", style: "gradeNormal" },
                    //   { text: "20", style: "gradeNormal" },
                    //   { text: "20", style: "gradeNormal" },
                    //   { text: "20", style: "gradeNormal" },
                    //   { text: "20", style: "gradeNormal" },
                    // ],
                    // [
                    //   { text: "No. of School Days Present", style: "subjectLabel" },
                    //   { text: "2", style: "gradeNormal" },
                    //   { text: "20", style: "gradeNormal" },
                    //   { text: "20", style: "gradeNormal" },
                    //   { text: "20", style: "gradeNormal" },
                    //   { text: "20", style: "gradeNormal" },
                    //   { text: "20", style: "gradeNormal" },
                    //   { text: "20", style: "gradeNormal" },
                    //   { text: "20", style: "gradeNormal" },
                    //   { text: "20", style: "gradeNormal" },
                    //   { text: "20", style: "gradeNormal" },
                    //   { text: "20", style: "gradeNormal" },
                    //   { text: "20", style: "gradeNormal" },
                    // ],
                    // [
                    //   { text: "No. of Times Tardy", style: "subjectLabel" },
                    //   { text: "2", style: "gradeNormal" },
                    //   { text: "20", style: "gradeNormal" },
                    //   { text: "20", style: "gradeNormal" },
                    //   { text: "20", style: "gradeNormal" },
                    //   { text: "20", style: "gradeNormal" },
                    //   { text: "20", style: "gradeNormal" },
                    //   { text: "20", style: "gradeNormal" },
                    //   { text: "20", style: "gradeNormal" },
                    //   { text: "20", style: "gradeNormal" },
                    //   { text: "20", style: "gradeNormal" },
                    //   { text: "20", style: "gradeNormal" },
                    //   { text: "20", style: "gradeNormal" },
                    // ],
                  ],
                },
                layout: tableLayout,
              },
              {
                table: {
                  widths: [80, "*"],
                  body: [
                    [
                      {
                        border: [false, false, false, false],
                        text: "Promoted To: ",
                        style: "subjectLabel",
                      },
                      {
                        border: [false, false, false, true],
                        text: d.classified_as,
                        style: "gradeNormal",
                      },
                    ],
                    [
                      {
                        border: [false, false, false, false],
                        text: "Retained In: ",
                        style: "subjectLabel",
                      },
                      {
                        border: [false, false, false, true],
                        text: d.retained_in,
                        style: "gradeNormal",
                      },
                    ],
                  ],
                },
                layout: tableLayout,
              },
            ],
          },
          {
            margin: [0, 30, 0, 0],
            width: "*",
            columns: [
              [
                {
                  text: "_________________________________________________",
                  fontSize: 8,
                  bold: false,
                  margin: [20, 0, 20, 0],
                },
                {
                  text: director,
                  fontSize: 8,
                  bold: true,
                  margin: [20, 0, 20, 0],
                  alignment: "center",
                },
                { text: "Director", style: "labelBoldItalic", fontSize: 7 },
              ],
              [
                {
                  text: "__________________________________________________",
                  style: "title",
                  margin: [0, 0, 20, 0],
                },
                {
                  text:
                    d.level_code <= 4
                      ? principal_pre_elem
                      : d.level_code > 4 && d.level_code <= 9
                      ? principal_elem
                      : principal_highschool,
                  fontSize: 8,
                  bold: true,
                  margin: [20, 0, 20, 0],
                  alignment: "center",
                },
                { text: "Principal", style: "labelBoldItalic", fontSize: 7 },
              ],
              [
                {
                  text: "__________________________________________________",
                  style: "title",
                  margin: [0, 0, 0, 0],
                },
                {
                  text: d.employee,
                  fontSize: 8,
                  bold: true,
                  margin: [20, 0, 20, 0],
                  alignment: "center",
                },
                { text: "Teacher", style: "labelBoldItalic", fontSize: 7 },
              ],
            ],
          },
        ];
      }),
    ],
    styles: {
      header: {
        fontSize: 10,
        bold: true,
        alignment: "center",
        lineHeight: 1.1,
      },
      subheader: {
        fontSize: normalFontSize,
        bold: true,
        alignment: "center",
      },
      title: {
        fontSize: 10,
        bold: true,
        alignment: "center",
        margin: 10,
      },
      title2: {
        fontSize: 9,
        bold: true,
        alignment: "left",
        margin: [0, 8, 0, 4],
      },
      labelBold: {
        fontSize: 8.5,
        bold: true,
      },
      labelNormal: {
        fontSize: 8.5,
      },
      labelBoldItalic: {
        fontSize: 8.5,
        bold: true,
        italics: true,
        alignment: "center",
        margin: [0, 0, 0, 0],
      },
      subjectLabel: {
        fontSize: normalFontSize,
        bold: true,
        margin: cellmargin,
      },
      gradeBold: {
        fontSize: normalFontSize,
        bold: true,
        margin: cellmargin,
        alignment: "center",
      },
      gradeNormal: {
        fontSize: normalFontSize,
        margin: cellmargin,
        alignment: "center",
      },
      table: {
        margin: [0, 10, 0, 10],
      },
      averageFont: {
        fontSize: 11,
        bold: true,
        margin: cellmargin,
        alignment: "center",
      },
      listBold: {
        fontSize: 8,
        bold: true,
      },
      listBoldIndent: {
        fontSize: 8,
        bold: true,
        margin: [5, 0, 0, 0],
      },
      listNormal: {
        fontSize: 8,
      },
      studentDetails: {
        fontSize: 10,
        margin: cellmargin,
        alignment: "center",
        bold: true,
      },
    },
  };

  try {
    pdfMake.createPdf(documentDefinition).open();
  } catch (error) {}

  //   pdfMake.createPdf(documentDefinition).print({}, window);
};
