import React, { useState, useEffect } from "react";
import { Spin, Button, Space, Typography, Row, Col, message } from "antd";
import { SaveOutlined, CloseCircleOutlined } from "@ant-design/icons";

import { useMutation, queryCache } from "react-query";
import http from "services/httpService.js";

// /import noimage from "assets/images/no-image.png";

import EditableTablePrimary from "views/PermanentRecord/components/GradesComposer/EditableTablePrimary";

import {
  rsK12,
  rgK12,
  rsSR,
  rgSR,
  // rsTraits,
  // rsAttendance,
} from "views/PermanentRecord/components/GradesComposer/tableColumns";

// import { useRatingSheetAllQuery } from "views/SYSubjects/hooks/Index";

const { Title } = Typography;

function GradesComposer({ setDrawerVisibility, customGradesData, getRegistrations, studentData }) {
  const [ratingCredentials, setRatingCredentials] = useState({});

  const ratingColumnsK12 = React.useMemo(() => rsK12, []);
  const regsColumnsK12 = React.useMemo(() => rgK12, []);
  const ratingColumnsSR = React.useMemo(() => rsSR, []);
  const regsColumnsSR = React.useMemo(() => rgSR, []);

  const [isLoading, setIsLoading] = useState(false);
  // const traitsColumns = React.useMemo(() => rsTraits, []);
  // const attendanceColumns = React.useMemo(() => rsAttendance, []);

  // const { isLoading, data: ratingSheetAllData } = useRatingSheetAllQuery(ratingCredentials);
  const [registrationData, setRegistrationData] = useState([]);
  const [subjectsData, setSubjectsData] = useState([]);
  // const [traitsData, setTraitsData] = useState([]);
  // const [attendanceData, setAttendanceData] = useState([]);
  // const [student, setStudent] = useState({});

  const [isSaving, setIsSaving] = useState(false);

  useEffect(() => {
    if (!customGradesData.newCustomGrades) {
      setIsLoading(true);
      setRatingCredentials(customGradesData.credentials);
      getRatingSheet(customGradesData.credentials);
    } else {
      setIsLoading(true);
      setRatingCredentials(customGradesData.credentials);
      composeRatingSheet(customGradesData.regs, customGradesData.subjects);
    }
    // if (ratingSheetAllData && !isNew) {
    //   console.log(ratingSheetAllData);
    //   // setStudent(ratingSheetAllData.student);
    //   setRegistrationData(ratingSheetAllData.registration);
    //   setSubjectsData(ratingSheetAllData.subjects);
    //   // setTraitsData(ratingSheetAllData.traits);
    //   // setAttendanceData(ratingSheetAllData.attendance);
    // }
  }, [customGradesData]);

  async function getRatingSheet(obj) {
    const { data } = await http.get(
      `ratingsheet/getcustom/${obj.registration_no}/${obj.school_level_category_id}/${obj.sem}`
    );

    setRegistrationData(data.registration);
    setSubjectsData(data.subjects);
    setIsLoading(false);
  }

  async function composeRatingSheet(regs, subjects) {
    setRegistrationData(regs);
    setSubjectsData(subjects);
    setIsLoading(false);
  }

  const [mutate] = useMutation(
    async (values) => {
      return await http.put(`ratingsheet/update`, { ...values });
    },
    {
      onSuccess: (data) => {
        // const { values, processType } = variables;
        // const { error  } = data.data;
        setIsSaving(false);
        message.success("Save successful!");
        console.log(data);
      },
      onError: () => {
        setIsSaving(false);
        message.warning("Unable to save, Something went wrong :/");
      },
      onSettled: () => {
        getRegistrations(studentData.student_id);
        // queryCache.invalidateQueries([
        //   "registration",
        //   {
        //     employee_id: selectedSyLevel.employee_id,
        //     level_code: selectedSyLevel.level_code,
        //     sy: selectedSyLevel.sy,
        //   },
        // ]);
        // Modal.success({
        //   title: "Registration Successful",
        //   content:
        //     'You need to run "Process Rating Sheet" before you can manage grades of newly registered student',
        // });
      },
    }
  );

  const [mutateInsert] = useMutation(
    async (values) => {
      return await http.post(`ratingsheet/custom`, { ...values });
    },
    {
      onSuccess: (data) => {
        // const { values, processType } = variables;
        // const { error  } = data.data;
        setIsSaving(false);
        message.success("Save successful!");
      },
      onError: () => {
        setIsSaving(false);
        message.warning("Unable to save, Something went wrong :/");
      },
      onSettled: (data, error, variables) => {
        getRegistrations(studentData.student_id);

        // queryCache.invalidateQueries([
        //   "registration",
        //   {
        //     employee_id: selectedSyLevel.employee_id,
        //     level_code: selectedSyLevel.level_code,
        //     sy: selectedSyLevel.sy,
        //   },
        // ]);
        // Modal.success({
        //   title: "Registration Successful",
        //   content:
        //     'You need to run "Process Rating Sheet" before you can manage grades of newly registered student',
        // });
      },
    }
  );

  // We need to keep the table from resetting the pageIndex when we
  // Update data. So we can keep track of that flag with a ref.

  // When our cell renderer calls updateMyData, we'll use
  // the rowIndex, columnId and new value to update the
  // original data
  const updateMyData = (rowIndex, columnId, value) => {
    // We also turn on the flag to not reset the page
    // console.log(rowIndex, columnId, value, isSub, primaryIndex);
    setSubjectsData((old) =>
      old.map((row, index) => {
        if (index === rowIndex) {
          return {
            ...old[rowIndex],
            [columnId]: value,
          };
        }
        return row;
      })
    );
  };

  const updateRegistrationData = (rowIndex, columnId, value) => {
    // We also turn on the flag to not reset the page
    // console.log(rowIndex, columnId, value, isSub, primaryIndex);
    setRegistrationData((old) =>
      old.map((row, index) => {
        if (index === rowIndex) {
          return {
            ...old[rowIndex],
            [columnId]: value,
          };
        }
        return row;
      })
    );
  };

  // const updateTraitsData = (rowIndex, columnId, value) => {
  //   // We also turn on the flag to not reset the page
  //   // console.log(rowIndex, columnId, value, isSub, primaryIndex);
  //   setTraitsData((old) =>
  //     old.map((row, index) => {
  //       if (index === rowIndex) {
  //         return {
  //           ...old[rowIndex],
  //           [columnId]: value,
  //         };
  //       }
  //       return row;
  //     })
  //   );
  // };

  // const updateAttendanceData = (rowIndex, columnId, value) => {
  //   // We also turn on the flag to not reset the page
  //   // console.log(rowIndex, columnId, value, isSub, primaryIndex);
  //   setAttendanceData((old) =>
  //     old.map((row, index) => {
  //       if (index === rowIndex) {
  //         return {
  //           ...old[rowIndex],
  //           [columnId]: value,
  //         };
  //       }
  //       return row;
  //     })
  //   );
  // };

  // const handlesOnChange = (value) => {
  //   setRatingCredentials({ ...ratingCredentials, sem: parseInt(value) });
  // };

  const handlesClose = () => {
    queryCache.removeQueries("ratingsheetall", { exact: true });
    setDrawerVisibility(false);
  };

  const handlesSave = () => {
    // ratingsheet/update

    setIsSaving(true);

    if (customGradesData.newCustomGrades) {
      mutateInsert({
        credentials: ratingCredentials,
        registration: registrationData[0],
        subjects: subjectsData,
      });
    } else {
      mutate({
        credentials: ratingCredentials,
        registration: registrationData[0],
        subjects: subjectsData,
        traits: [],
        attendance: [],
      });
    }
  };

  // const handlesComputeAverage = () => {
  //   const temp = Object.assign([], subjectsData);
  //   let first_grading = 0;
  //   let second_grading = 0;
  //   let third_grading = 0;
  //   let fourth_grading = 0;
  //   let primary_divisor = temp.length;
  //   temp.forEach((element, index) => {
  //     if (element.with_sub === 1) {
  //       let first_grading_sub = 0;
  //       let second_grading_sub = 0;
  //       let third_grading_sub = 0;
  //       let fourth_grading_sub = 0;
  //       let sub_divisor = element.sub.length;

  //       element.sub.forEach((subitem, subindex) => {
  //         // add all rows for averge
  //         first_grading_sub += parseFloat(subitem.first_grading);
  //         second_grading_sub += parseFloat(subitem.second_grading);
  //         third_grading_sub += parseFloat(subitem.third_grading);
  //         fourth_grading_sub += parseFloat(subitem.fourth_grading);

  //         //  get all column for final grade average
  //         let final_grade_sub =
  //           (parseFloat(subitem.first_grading) +
  //             parseFloat(subitem.second_grading) +
  //             parseFloat(subitem.third_grading) +
  //             parseFloat(subitem.fourth_grading)) /
  //           4;

  //         // put average to final grade sub actual object
  //         temp[index].sub[subindex].final_grade = final_grade_sub.toFixed(3);
  //       });

  //       let fgs = first_grading_sub / sub_divisor;
  //       let sgs = second_grading_sub / sub_divisor;
  //       let tgs = third_grading_sub / sub_divisor;
  //       let ftgs = fourth_grading_sub / sub_divisor;

  //       //  get average of all rows column and assign it to primary subject grades as string
  //       temp[index].first_grading = fgs.toFixed(3);
  //       temp[index].second_grading = sgs.toFixed(3);
  //       temp[index].third_grading = tgs.toFixed(3);
  //       temp[index].fourth_grading = ftgs.toFixed(3);
  //       temp[index].final_grade = ((fgs + sgs + tgs + ftgs) / 4).toFixed(3);

  //       //  get average of all rows column and add it to primary grading loops for averaging
  //       first_grading += fgs; //first_grading_sub / sub_divisor;
  //       second_grading += sgs; // second_grading_sub / sub_divisor;
  //       third_grading += tgs; //third_grading_sub / sub_divisor;
  //       fourth_grading += ftgs; //fourth_grading_sub / sub_divisor;
  //     } else {
  //       temp[index].final_grade = (
  //         (parseFloat(element.first_grading) +
  //           parseFloat(element.second_grading) +
  //           parseFloat(element.third_grading) +
  //           parseFloat(element.fourth_grading)) /
  //         4
  //       ).toFixed(3);

  //       first_grading += parseFloat(element.first_grading);
  //       second_grading += parseFloat(element.second_grading);
  //       third_grading += parseFloat(element.third_grading);
  //       fourth_grading += parseFloat(element.fourth_grading);
  //     }
  //   });

  //   let fave = first_grading / primary_divisor;
  //   let save = second_grading / primary_divisor;
  //   let tave = third_grading / primary_divisor;
  //   let ftave = fourth_grading / primary_divisor;

  //   setSubjectsData(temp);
  //   setRegistrationData((old) =>
  //     old.map((row, index) => {
  //       if (index === 0) {
  //         return {
  //           ...old[0],
  //           first_average: fave.toFixed(3),
  //           second_average: save.toFixed(3),
  //           third_average: tave.toFixed(3),
  //           fourth_average: ftave.toFixed(3),
  //           final_average: ((fave + save + tave + ftave) / 4).toFixed(3),
  //           final_grade: ((fave + save + tave + ftave) / 4).toFixed(3),
  //         };
  //       }
  //       return row;
  //     })
  //   );
  //   // console.log(temp);
  // };

  // const handlesComputeAverageSR = () => {
  //   const { sem } = ratingCredentials;
  //   console.log(ratingCredentials);
  //   const temp = Object.assign([], subjectsData);
  //   let first_grading = 0;
  //   let second_grading = 0;
  //   let primary_divisor = temp.length;
  //   temp.forEach((element, index) => {
  //     temp[index].final_grade = (
  //       (parseFloat(element.first_grading) + parseFloat(element.second_grading)) /
  //       2
  //     ).toFixed(3);

  //     first_grading += parseFloat(element.first_grading);
  //     second_grading += parseFloat(element.second_grading);
  //   });

  //   let fave = first_grading / primary_divisor;
  //   let save = second_grading / primary_divisor;

  //   setSubjectsData(temp);
  //   if (sem === 1) {
  //     console.log("sem 1");
  //     setRegistrationData((old) =>
  //       old.map((row, index) => {
  //         if (index === 0) {
  //           return {
  //             ...old[0],
  //             sem_one_first_ave: fave.toFixed(3),
  //             sem_one_second_ave: save.toFixed(3),
  //             sem_one_ave: ((fave + save) / 2).toFixed(3),
  //           };
  //         }
  //         return row;
  //       })
  //     );
  //   } else {
  //     console.log("sem 2");
  //     setRegistrationData((old) =>
  //       old.map((row, index) => {
  //         if (index === 0) {
  //           return {
  //             ...old[0],
  //             sem_two_first_ave: fave.toFixed(3),
  //             sem_two_second_ave: save.toFixed(3),
  //             sem_two_ave: ((fave + save) / 2).toFixed(3),
  //           };
  //         }
  //         return row;
  //       })
  //     );
  //   }

  //   // console.log(temp);
  // };

  return isLoading ? (
    <div className="spinner-container">
      <Spin tip="Loading..." />
    </div>
  ) : (
    <>
      <Spin tip="Saving, please wait..." spinning={isSaving}>
        <Row style={{ marginTop: -20 }}>
          <Col span={18}>
            <Title level={2}>{studentData.fullname}</Title>
          </Col>
          <Col span={6}>
            <div style={{ textAlign: "right" }}>
              <Space style={{ marginBottom: 10 }}>
                <Button
                  onClick={handlesClose}
                  //   type="warning"
                  icon={<CloseCircleOutlined />}
                >
                  Close
                </Button>
                <Button
                  onClick={() => {
                    handlesSave();
                  }}
                  type="primary"
                  icon={<SaveOutlined />}
                >
                  Save
                </Button>
                {/* {registrationData.length > 0 && ratingCredentials.school_level_category_id === 2 ? (
                  <Form
                    layout={{
                      labelCol: { span: 8 },
                      wrapperCol: { span: 16 },
                    }}
                    style={{ padding: "14px 14px 0px 14px", border: "1px solid #91d5ff" }}
                  >
                    <Form.Item label="Select Semester">
                      <Select
                        defaultValue={ratingCredentials.sem.toString()}
                        style={{ width: 200 }}
                        onChange={handlesOnChange}
                      >
                        <Option value="1">First Semester</Option>
                        <Option value="2">Second Semester</Option>
                      </Select>
                    </Form.Item>
                  </Form>
                ) : null} */}
              </Space>
            </div>
          </Col>
        </Row>

        <Space>
          <Title level={4} style={{ paddingTop: 6 }}>
            Other Record
          </Title>
          {/* <Button
            icon={<CalculatorOutlined />}
            type="dashed"
            onClick={() =>
              ratingCredentials.school_level_category_id === 1
                ? handlesComputeAverage()
                : handlesComputeAverageSR()
            }
          >
            Compute Average
          </Button> */}
        </Space>

        <EditableTablePrimary
          columns={
            ratingCredentials.school_level_category_id === 1 ? regsColumnsK12 : regsColumnsSR
          }
          rowData={registrationData}
          updateMyData={updateRegistrationData}
          suffix={"regs"}
          fullWidth={true}
        />
        <div style={{ marginBottom: 20 }}></div>

        <Title level={4}>Subjects</Title>
        <EditableTablePrimary
          columns={
            ratingCredentials.school_level_category_id === 1 ? ratingColumnsK12 : ratingColumnsSR
          }
          rowData={subjectsData}
          updateMyData={updateMyData}
          enableSub={true}
          suffix={"subject"}
          category={ratingCredentials.school_level_category_id}
        />

        {/* <div style={{ marginBottom: 20 }}></div>

        <Title level={4}>Traits</Title>
        <EditableTablePrimary
          columns={traitsColumns}
          rowData={traitsData}
          updateMyData={updateTraitsData}
          suffix={"traits"}
        />

        <div style={{ marginBottom: 20 }}></div>
        <Title level={4}>Attendance</Title>
        <EditableTablePrimary
          columns={attendanceColumns}
          rowData={attendanceData}
          updateMyData={updateAttendanceData}
          suffix={"att"}
        /> */}
      </Spin>

      {/* <Drawer
        width={840}
        placement="right"
        closable={false}
        // onClose={() => setDrawerVisibility(false)}
        visible={studentDrawerVisibility}
        destroyOnClose={true}
      ></Drawer> */}
    </>
  );
}

export default GradesComposer;
