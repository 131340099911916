import React, { useState } from "react";
import { connect } from "react-redux";
import { Layout, Result, Button } from "antd";

import Topbar from "./TopBar";
import Sidebar from "./Sidebar";
import Footer from "./Footer";

const Main = ({
  breakpoint,
  children,
  isRequestError,
  requestErrorMsg,
  requestErrorSubMsg,
  ...props
}) => {
  const { Content } = Layout;
  const [isCollapsedMain, setIsCollapsedMain] = useState(false);

  let pageTitle = "";
  let activePage = "";
  children.props.children[0].forEach((views) => {
    let viewsPath = views.props.path;
    viewsPath = viewsPath.replace(/\/:.*/g, "");
    if (props.location.pathname.indexOf(viewsPath) !== -1) {
      pageTitle = views.props.title;
      activePage = viewsPath.replace("/", "");
    }
  });

  return isRequestError ? (
    <Result
      status="500"
      title={requestErrorMsg}
      subTitle={requestErrorSubMsg}
      extra={
        <Button type="primary" onClick={() => window.location.reload(true)}>
          Refresh Page
        </Button>
      }
    />
  ) : (
    <Layout>
      <Sidebar
        activeMenu={activePage}
        breakpoint={breakpoint}
        setIsCollapsedMain={setIsCollapsedMain}
      />
      <Layout style={{ minHeight: "calc(100vh)", marginLeft: !isCollapsedMain ? 200 : 80 }}>
        <Topbar title={pageTitle} breakpoint={breakpoint} />
        <Content style={{ margin: "16px 16px 0 16px" }}>{children}</Content>
        <Footer />
      </Layout>
    </Layout>
  );
};

const mapStateToProps = (state) => ({
  isRequestError: state.System.isRequestError,
  requestErrorMsg: state.System.requestErrorMsg,
  requestErrorSubMsg: state.System.requestErrorSubMsg,
});

export default connect(mapStateToProps)(Main);
