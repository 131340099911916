import React from "react";
import { Route, Redirect } from "react-router-dom";

const PrivateRoute = ({ breakpoint, component: Component, ...props }) => {
  const isUserLogin = localStorage.getItem("token") ? true : false;

  return (
    <Route
      {...props}
      render={(props) => {
        return isUserLogin ? (
          <Component {...props} breakpoint={breakpoint} />
        ) : (
          <Redirect to={`/`} />
        );
      }}
    />
  );
};

export default PrivateRoute;
