import React, { useState, useEffect } from "react";
import { queryCache, useMutation } from "react-query";
import moment from "moment";
import { Row, Col, Form, Button, Space, message, Spin, Upload, Modal } from "antd";
import http from "services/httpService.js";
import DynamicForm from "globalComponents/DynamicForm/Index";
import {
  UploadOutlined,
  CameraOutlined,
  ExclamationCircleOutlined,
  CloseOutlined,
} from "@ant-design/icons";

// ---new
import Webcam from "react-webcam";
import b64ImgToFileHelper from "helpers/b64ImgToFileHleper";

import noimage from "assets/images/no-image.png";

// ---new
function getBase64(img, callback) {
  const reader = new FileReader();
  reader.addEventListener("load", () => callback(reader.result));
  reader.readAsDataURL(img);
}

const EmployeeForm = ({ currentData, isEditing, setDrawerVisibility }) => {
  const [formEInfo] = Form.useForm();

  const [eInfoValue, setEInfoValue] = useState([]);
  const [eInfo, setEInfo] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [currentDataTemp, setCurrentDataTemp] = useState(currentData);
  const [isLoading, setIsLoading] = useState(true);

  // ---new
  const [imageData, setImageData] = useState(null);
  const [imageDataTemp, setImageDataTemp] = useState({ src: null, filename: null });
  const [hasChanges, setHasChanges] = useState(false);
  const [webcamVisible, setWebcamVisible] = useState(false);

  const [mutateUpdate] = useMutation(
    async (data) => {
      await http.put(`employee/update/${data.employee_id}`, {
        employeeInfo: data.finalInfoData,
      });
    },
    {
      onSuccess: (data, variables) => {
        queryCache.invalidateQueries("employees");
        setDrawerVisibility(false);
        message.success("Record updated");
      },
      onError: () => {
        message.warning("Unable to update, Something went wrong :/");
      },
    }
  );

  const [mutateSave] = useMutation(
    async (data) => {
      await http.post(`employee/store`, {
        employeeInfo: data.finalInfoData,
      });
    },
    {
      onSuccess: (data, variables) => {
        queryCache.invalidateQueries("employees");
        setDrawerVisibility(false);
        message.success("Record saved.");
      },
      onError: () => {
        // setDrawerVisibility(false);
        message.warning("Unable to save, Something went wrong :/");
      },
    }
  );

  // ---new
  const webcamRef = React.useRef(null);
  // ---new
  const capture = React.useCallback(() => {
    const imageSrc = webcamRef.current.getScreenshot();
    setImageData(imageSrc);
    setImageDataTemp({ src: imageSrc, filename: "webcam-snapshot.jpg" });
    setHasChanges(true);
    setWebcamVisible(false);
  }, [webcamRef]);

  useEffect(() => {
    setIsLoading(true);
    const settings = queryCache.getQueryData("settings");
    setEInfo(settings.employee_info);

    if (isEditing) {
      setEInfoValue(
        Object.entries(currentDataTemp).map(([key, val]) => {
          if (key !== "birth_day") {
            return { name: key, value: val };
          } else {
            return { name: key, value: val ? moment(val) : null };
          }
        })
      );
    }
    setIsLoading(false);
    if (currentDataTemp && currentDataTemp.img_path) {
      setImageData(currentDataTemp.img_path.replace(".jpg", "-card.jpg"));
    } else {
      setImageData(noimage);
    }
  }, [currentDataTemp, isEditing]);

  // ---new
  const handleFormSubmit = () => {
    if (hasChanges) {
      setIsSubmitting(true);
      formEInfo
        .validateFields()
        .then(async (values) => {
          if (imageDataTemp.src) {
            saveImage();
          } else {
            saveOrUpdate();
          }
        })
        .catch((errorInfo) => {
          message.warning("Unable to save, look's like you missed something.");
          setIsSubmitting(false);
        });
    } else {
      handlesClose();
    }
  };

  const ButtonGroup = () => {
    return (
      <div style={{ textAlign: "right" }}>
        <Space>
          <Button type="primary" onClick={handleFormSubmit} loading={isSubmitting}>
            Submit
          </Button>
          <Button onClick={handlesCancel} disabled={isSubmitting}>
            Cancel
          </Button>
        </Space>
      </div>
    );
  };

  // ---new
  const handlesCancel = () => {
    if (hasChanges) {
      Modal.confirm({
        title: "Confirm Cancel.",
        icon: <ExclamationCircleOutlined />,
        content: "Any changes made will not be save, proceed cancel?",
        cancelText: "No",
        okText: "Yes",
        onOk: handlesClose,
      });
    } else {
      handlesClose();
    }
  };

  // ---new
  const handlesClose = () => {
    setImageDataTemp({ src: null, filename: null });
    setHasChanges(false);
    setDrawerVisibility(false);
  };

  const saveOrUpdate = (filename = null) => {
    let finalInfoData = {};
    setIsSubmitting(true);
    formEInfo
      .validateFields()
      .then(async (values) => {
        finalInfoData = values;
        Object.entries(finalInfoData).forEach(([key, val]) => {
          if (key !== "birth_day") {
            // consider null here
            finalInfoData[key] = val !== undefined ? val : "";
          } else {
            finalInfoData[key] = val.format("YYYY-MM-DD");
          }
        });

        if (filename) finalInfoData = { ...finalInfoData, img_path: filename };

        if (isEditing) {
          finalInfoData = { ...finalInfoData, employee_id: currentDataTemp.employee_id };
          setCurrentDataTemp(finalInfoData);
          await mutateUpdate({ finalInfoData, employee_id: currentDataTemp.employee_id });
        } else {
          await mutateSave({
            finalInfoData,
          });
        }
        setIsSubmitting(false);
      })
      .catch((errorInfo) => {
        message.warning("Unable to save, look's like you missed something.");
        setIsSubmitting(false);
      });
  };

  // ---new
  const handleUploadChange = (info) => {
    if (!["image/jpeg", "image/png"].includes(info.file.type)) {
      message.error("You can only upload JPG/PNG file!");
      return;
    } else if (info.file.size / 1024 / 1024 > 2) {
      message.error("Image too large, must 2MB or below!");
      return;
    } else {
      getBase64(info.file.originFileObj, (imageUrl) => {
        setImageData(imageUrl);
        setImageDataTemp({ src: imageUrl, filename: info.file.name });
        setHasChanges(true);
      });
    }
  };

  // ---new
  const saveImage = async () => {
    let toBlob = b64ImgToFileHelper(imageDataTemp.src, imageDataTemp.filename);
    var fd = new FormData();
    fd.append("image", toBlob);
    await http
      .post("s3upload", fd, {
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        if (response.data.error === false) {
          setImageDataTemp({ src: null, filename: null });
          saveOrUpdate(response.data.img_path);
        }
      });
  };
  return (
    <>
      {isLoading ? (
        <div className="spinner-container">
          <Spin tip="Loading..." />
        </div>
      ) : (
        <>
          <Modal
            title="Webcam Snapshot"
            style={{ top: 20 }}
            visible={webcamVisible}
            footer={null}
            bodyStyle={{ margin: 0, paddingLeft: 60 }}
            closable={false}
          >
            <Webcam
              style={{ border: "1px solid #e4e4e4" }}
              audio={false}
              height={400}
              width={400}
              minScreenshotWidth={400}
              screenshotQuality={0.9}
              ref={webcamRef}
              screenshotFormat="image/jpeg"
              videoConstraints={{
                facingMode: "user",
              }}
            />
            <div style={{ textAlign: "center" }}>
              <Space>
                <Button type="primary" icon={<CameraOutlined />} onClick={capture}>
                  Take Picture
                </Button>
                <Button danger icon={<CloseOutlined />} onClick={() => setWebcamVisible(false)}>
                  Cancel
                </Button>
              </Space>
            </div>
          </Modal>

          <Row>
            <Col span={8}>
              <img
                src={imageData}
                style={{
                  border: "0.2px solid grey",
                  borderWidth: "thin",
                  borderRadius: 10,
                  width: 200,
                  height: 200,
                  objectFit: "contain",
                  cursor: "pointer",
                }}
                alt="Student Record"
                onClick={() => console.log("clicked")}
              />

              <div style={{ textAlign: "center", marginTop: 20, marginLeft: -60 }}>
                <Space>
                  <Upload
                    customRequest={() => {}}
                    showUploadList={false}
                    onChange={handleUploadChange}
                  >
                    <Button icon={<UploadOutlined />}></Button>
                  </Upload>
                  <Button icon={<CameraOutlined />} onClick={() => setWebcamVisible(true)}></Button>
                </Space>
              </div>
            </Col>
            <Col span={16}>
              <ButtonGroup />
              <DynamicForm
                mode="form"
                formStructure={eInfo}
                fields={eInfoValue}
                form={formEInfo}
                setHasChanges={setHasChanges}
                hasChanges={hasChanges}
              />
              <ButtonGroup />
            </Col>
          </Row>
        </>
      )}
    </>
  );
};
export default EmployeeForm;
