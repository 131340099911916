import React, { useEffect, useState } from "react";
import { Button, Row, Col, Form, Input, message, Alert } from "antd";
import { MailOutlined, LockOutlined } from "@ant-design/icons";
import logo from "assets/images/logo.png";
import sketch from "assets/images/sketch.png";
import { useMutation } from "react-query";
import http from "services/httpService.js";

const Login = (props) => {
  const [isLogInFailed, setIsLogInFailed] = useState(false);
  const [mutate] = useMutation(
    async (credentials) => {
      return await http.post(`login`, { ...credentials });
    },
    {
      onSuccess: (data, variables) => {
        const { error, user, token } = data.data;
        if (error) {
          setIsLogInFailed(true);
        } else {
          setIsLogInFailed(false);
          localStorage.setItem("token", token);
          localStorage.setItem("user", JSON.stringify(user));
          window.location = "/dashboard";
        }
      },
      onError: () => {
        message.warning("Login failed, Something went wrong :/");
      },
    }
  );
  useEffect(() => {
    const isUserLogin = localStorage.getItem("token") ? true : false;
    if (isUserLogin) {
      return props.history.push(`/dashboard`);
    }
  }, [props.history]);

  const onFinish = (values) => {
    mutate(values);
  };

  return (
    <>
      <Row>
        <Col span={16}>
          <div
            style={{
              height: "100vh",
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              padding: 30,
              paddingRight: 70,
            }}
          >
            <img src={sketch} width={650} height={600} alt="SWA Banner" />
          </div>
        </Col>
        <Col span={6}>
          <div
            style={{
              height: "100vh",
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              padding: 30,
              paddingRight: 70,
            }}
          >
            <div style={{ textAlign: "center" }}>
              <img src={logo} width={200} height={98} style={{ marginBottom: 30 }} alt="SWA Logo" />
              {isLogInFailed && (
                <Alert
                  message="Invalid login!"
                  type="error"
                  showIcon
                  style={{ marginBottom: 10 }}
                />
              )}
              <Form
                name="normal_login"
                className="login-form"
                // initialValues={{ remember: true }}
                onFinish={onFinish}
                style={{ width: 350 }}
              >
                <Form.Item
                  name="email"
                  rules={[
                    {
                      type: "email",
                      message: "The input is not valid E-mail!",
                    },
                    { required: true, message: "Please input your Username!" },
                  ]}
                >
                  <Input
                    prefix={<MailOutlined className="site-form-item-icon" />}
                    placeholder="Email"
                    onKeyPress={() => setIsLogInFailed(false)}
                    style={{ height: 45 }}
                  />
                </Form.Item>
                <Form.Item
                  name="password"
                  rules={[{ required: true, message: "Please input your Password!" }]}
                >
                  <Input
                    prefix={<LockOutlined className="site-form-item-icon" />}
                    type="password"
                    placeholder="Password"
                    onKeyPress={() => setIsLogInFailed(false)}
                    style={{ height: 45 }}
                  />
                </Form.Item>
                <Form.Item>
                  <Form.Item>
                    <Button type="primary" htmlType="submit" className="login-form-button">
                      Log in
                    </Button>
                  </Form.Item>
                </Form.Item>
              </Form>

              {/* <Button type="primary" onClick={handleClick}>
                Login
              </Button> */}
            </div>
          </div>
        </Col>
      </Row>
    </>
  );
};

export default Login;
