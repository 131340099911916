import styled from "styled-components";

const StylesFitToWindow = styled.div`
  table.secondary {
    border-spacing: 0;
    border: 0.5px solid #ff9c6e;
    background-color: #ffffff;
    margin-left: 10px;
  }

  table {
    border-spacing: 0;
    border: 0.5px solid #f0f0f0;
    background-color: #ffffff;

    thead tr {
      border-top: 0.5px solid #f0f0f0;
      border-bottom: 0.5px solid #f0f0f0;
      th {
        text-align: center;
      }
    }

    tr {
      :last-child {
        td {
          border-bottom: 0;
        }
      }
    }

    th,
    td {
      margin: 0;
      padding: 0.1rem;
      border-bottom: 0.5px solid #f0f0f0;
      border-right: 0.5px solid #f0f0f0;

      :last-child {
        border-right: 0;
      }

      input {
        font-size: 0.9rem;
        padding: 4px;
        margin: 0;
        border: 0;
        height: 35px;
        text-align: center;
      }
      input:focus {
        font-weight: 600;
        color: #d4380d;
        border-bottom: 1px solid #d4380d;
      }
      input.input-disabled {
        background-color: transparent !important;
      }

      span {
        font-size: 0.9rem;
        padding: 4px;
        margin: 0;
        border: 0;
        min-width: 200px;
        display: block;
      }
      span.secondary {
        font-size: 0.9rem;
        padding: 4px;
        margin: 0;
        border: 0;
        min-width: 187px;
        display: block;
      }
      span.title {
        font-weight: 600;
      }
    }

    tr.secondary {
      background-color: #fff2e8;
    }
  }
`;

const StylesFullWidth = styled.div`
  /* This is required to make the table full-width */
  display: block;
  max-width: 100%;

  /* This will make the table scrollable when it gets too small */
  .tableWrap {
    display: block;
    max-width: 100%;
    overflow-x: scroll;
    overflow-y: hidden;
    border-bottom: 0.5px solid #f0f0f0;
    border-top: 0.5px solid #f0f0f0;
    padding-bottom: 10px;
    margin-top: 10px;
  }

  .tableWrap::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 2px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    background-color: #f5f5f5;
  }

  .tableWrap::-webkit-scrollbar {
    height: 6px;
    background-color: #f5f5f5;
  }
  .tableWrap::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: #d9d9d9;
  }

  table.secondary {
    border-spacing: 0;
    border: 0.5px solid #ff9c6e;
    background-color: #ffffff;
    margin-left: 10px;
  }

  table {
    /* Make sure the inner table is always as wide as needed */
    width: 100%;
    border-spacing: 0;
    border: 1px solid #f0f0f0;
    background-color: #ffffff;

    thead tr {
      border-bottom: 0.5px solid #f0f0f0;
      th {
        text-align: center;
      }
    }

    tr {
      :last-child {
        td {
          border-bottom: 0;
        }
      }
    }

    th,
    td {
      margin: 0;
      padding: 0.1rem;
      border-bottom: 0.5px solid #f0f0f0;
      border-right: 0.5px solid #f0f0f0;

      /* The secret sauce */
      /* Each cell should grow equally */
      width: 1%;
      /* But "collapsed" cells should be as small as possible */
      &.collapse {
        width: 0.0000000001%;
      }

      :last-child {
        border-right: 0;
      }

      input {
        font-size: 0.9rem;
        padding: 4px;
        margin: 0;
        border: 0;
        height: 35px;
        text-align: center;
        width: 120px;
      }

      input:focus {
        font-weight: 600;
        color: #d4380d;
        border-bottom: 1px solid #d4380d;
      }
      input.input-disabled {
        background-color: transparent !important;
      }
      span {
        font-size: 0.9rem;
        padding: 4px;
        margin: 0;
        border: 0;
        min-width: 200px;
        display: block;
      }
      span.secondary {
        font-size: 0.9rem;
        padding: 4px;
        margin: 0;
        border: 0;
        min-width: 187px;
        display: block;
      }
    }
    tr.secondary {
      background-color: #fff2e8;
    }
  }
`;

export { StylesFitToWindow, StylesFullWidth };
