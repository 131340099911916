import React from "react";
import { BrowserRouter as Router, Route, Switch, Redirect } from "react-router-dom";
import Media from "react-media";
import { ReactQueryDevtools } from "react-query-devtools";
import { ReactQueryConfigProvider } from "react-query";

import Main from "layouts/Main";
import Login from "views/Login/Index";
import Dashboard from "views/Dashboard/Index";
import Student from "views/Student/Index";
import Employee from "views/Employee/Index";
import About from "views/About/Index";

import ReportCards from "views/Reports/ReportCards";

import Registration from "views/Registration/Index";
import PermanentRecord from "views/PermanentRecord/Index";

import SYSubjectsK12 from "views/SYSubjects/SYSubjectsK12";
import SYSubjectsSenior from "views/SYSubjects/SYSubjectsSenior";
import SubjectsK12 from "views/Subjects/SubjectsK12";
import Traits from "views/Traits/Traits";
import SYTraits from "views/Traits/SYTraits";
import SubjectsSR from "views/Subjects/SubjectsSR";
import ProcessSubjects from "views/RatingSheet/ProcessSubjects";
import ManageGrades from "views/RatingSheet/ManageGrades";

import PrivateRoute from "globalComponents/ProtectedRoute";

const queryConfig = { queries: { refetchOnWindowFocus: false } };

function App() {
  const mainRoutes = [
    {
      title: "Dashboard",
      path: "/dashboard",
      component: Dashboard,
    },
    {
      title: "Student",
      path: "/student",
      component: Student,
    },
    {
      title: "Employee",
      path: "/employee",
      component: Employee,
    },
    {
      title: "Subjects K12",
      path: "/subjectsk12",
      component: SubjectsK12,
    },
    {
      title: "Subjects Senior High",
      path: "/subjectssr",
      component: SubjectsSR,
    },
    {
      title: "SY Subjects K12",
      path: "/sy-subjects-k12",
      component: SYSubjectsK12,
    },
    {
      title: "SY Subjects Senior High",
      path: "/sy-subjects-senior",
      component: SYSubjectsSenior,
    },
    {
      title: "Registration",
      path: "/registration",
      component: Registration,
    },
    {
      title: "Process Subjects",
      path: "/processsubjects",
      component: ProcessSubjects,
    },
    {
      title: "About",
      path: "/about",
      component: About,
    },
    {
      title: "Report Cards",
      path: "/reports-cards",
      component: ReportCards,
    },
    {
      title: "Traits",
      path: "/traits",
      component: Traits,
    },
    {
      title: "SY Traits",
      path: "/sytraits",
      component: SYTraits,
    },
    {
      title: "Rating Sheet - Manage Grades",
      path: "/managegrades",
      component: ManageGrades,
    },
    {
      title: "Permanent Records",
      path: "/permanent-record",
      component: PermanentRecord,
    },
  ];

  return (
    <>
      <ReactQueryConfigProvider config={queryConfig}>
        <Media
          queries={{
            xxs: "(max-width: 320px)", // iphone 5s (small)
            xs: "(max-width: 480px)", // 5.5 inches phones (medium)
            sm: "(max-width: 576px)", // 5.5 inches phones (medium)
            md: "(max-width: 768px)", // tablet portrait
            lg: "(max-width: 992px)", // tablet portrait
            xl: "(max-width: 1200px)", // tablet landscape
            xxl: "(max-width: 1600px)", //desktops
          }}
        >
          {(matches) => (
            <Router>
              <Switch>
                <Route exact path="/">
                  <Redirect from="/" to="/login" />
                </Route>
                <Route path="/login" component={Login} />
                <Route path="/not-found" component={() => <h1>404 not found</h1>} />
                <Main
                  breakpoint={{
                    isSmallMobile: matches.xxs,
                    isNormalMobile: matches.xs || matches.sm,
                    isTabletPortrait: matches.md || matches.lg,
                    isTabletLandscape: matches.xl,
                  }}
                >
                  <Switch>
                    {mainRoutes.map((route, index) => (
                      <PrivateRoute
                        key={index}
                        exact
                        path={route.path}
                        component={route.component}
                        title={route.title}
                        breakpoint={{
                          isSmallMobile: matches.xxs,
                          isNormalMobile: matches.xs || matches.sm,
                          isTabletPortrait: matches.md || matches.lg,
                          isTabletLandscape: matches.xl,
                        }}
                      />
                    ))}
                    <Route render={() => <Redirect to="/not-found" />} />
                  </Switch>
                </Main>
              </Switch>
            </Router>
          )}
        </Media>
      </ReactQueryConfigProvider>
      <ReactQueryDevtools initialIsOpen />
    </>
  );
}

export default App;
