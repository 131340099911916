import pdfMake from "pdfmake/build/pdfmake";
import vfsFonts from "pdfmake/build/vfs_fonts";

export default (data) => {
  const { vfs } = vfsFonts.pdfMake;
  pdfMake.vfs = vfs;

  const cellmargin = 1;
  const normalFontSize = 8;
  const leftLineFeed = "\n";
  const rightLineFeed = "\n\n";
  const subjectMargins = [0, 6];

  const documentDefinition = {
    pageMargins: [20, 10, 10, 10],
    pageSize: "LETTER",
    content: [
      data.map((d, index) => {
        return [
          {
            text: "LITTLE SHEPHERD INTEGRATED MONTESSORI",
            style: "header",
            pageBreak: index !== 0 && index % 3 === 0 ? "before" : "",
          },
          {
            text: "LITTLE SHEPHERD BUSINESS AND SCIENCE HIGH SCHOOL",
            style: "header",
          },
          {
            text: "Lumban, Laguna",
            style: "subheader",
          },
          {
            text: `${d.header}`,
            style: "title",
          },
          {
            margin: [20, 0, 0, 0],
            columns: [
              {
                width: 120,
                columns: [
                  {
                    width: 28,
                    text: "LRN:",
                    style: "labelBold",
                  },
                  {
                    width: "*",
                    text: d.lrn,
                    style: "labelNormal",
                  },
                ],
              },
              {
                width: "*",
                columns: [
                  {
                    width: 28,
                    text: "Name:",
                    style: "labelBold",
                  },
                  {
                    width: "*",
                    text: d.fullname,
                    style: "labelNormal",
                  },
                ],
              },
              {
                width: 120,
                columns: [
                  {
                    width: 28,
                    text: "SY:",
                    style: "labelBold",
                  },
                  {
                    width: "*",
                    text: d.sy,
                    style: "labelNormal",
                  },
                ],
              },
              {
                width: 120,
                columns: [
                  {
                    width: 28,
                    text: "Level:",
                    style: "labelBold",
                  },
                  {
                    width: "*",
                    text: d.level,
                    style: "labelNormal",
                  },
                ],
              },
            ],
          },
          {
            margin: subjectMargins,
            columns: [
              {
                text: "SUBJECTS",
                style: "labelBold",
                alignment: "center",
              },
              {
                text: "SUBJECTS",
                style: "labelBold",
                alignment: "center",
              },
              {
                text: "",
                style: "labelBold",
                alignment: "center",
              },
            ],
          },
          {
            columns: [
              {
                style: "table",
                table: {
                  widths: ["*", 50],
                  body: d.column_1,
                },
              },
              {
                style: "table",
                table: {
                  widths: ["*", 50],
                  body: d.column_2,
                },
              },

              [
                {
                  style: "table",
                  margin: [0, 5, 0, 60],
                  table: {
                    widths: ["*", 50],
                    body: [
                      [
                        {
                          border: [true, true, false, false],
                          text: "AVERAGE",
                          style: "subjectLabel",
                        },
                        {
                          border: [false, true, true, false],
                          text: d[d.grading_average],
                          style: "averageFont",
                        },
                      ],
                      [
                        {
                          border: [true, false, false, false],
                          text: "Days of School",
                          style: "subjectLabel",
                        },
                        {
                          border: [false, false, true, false],
                          text: d.school_days,
                          style: "gradeBold",
                        },
                      ],
                      [
                        {
                          border: [true, false, false, true],
                          text: "Days Present",
                          style: "subjectLabel",
                        },
                        {
                          border: [false, false, true, true],
                          text: d.days_present,
                          style: "gradeBold",
                        },
                      ],
                    ],
                  },
                },
                { text: d.employee, style: "title", margin: [0, 0] },
                { text: "Teacher", style: "subheader", bold: false },
              ],
            ],
          },
          {
            text:
              index % 3 < 2
                ? leftLineFeed +
                  "---  ----- ---  ----- ---  ----- ---  ----- ---  ----- ---  ----- ---  ----- ---  ----- ---  ----- ---  ----- ---  ----- ---  ----- ---  ----- ---  ----- ---  ----- ---  ----- ---  ----- ---  ----- ---  ----- ---  ----- ---  ----- ---  ----- ---  ----- ---  ----- ---  ----- ---  ----- ---  ----- ---  ----- ---  ----- ---  ----- ---  ----- ---  ----- --- " +
                  rightLineFeed
                : null,
            fontSize: 6,
          },
        ];
      }),
    ],
    styles: {
      header: {
        fontSize: 10,
        bold: true,
        alignment: "center",
        lineHeight: 1.1,
      },
      subheader: {
        fontSize: normalFontSize,
        bold: true,
        alignment: "center",
      },
      title: {
        fontSize: 10,
        bold: true,
        alignment: "center",
        margin: 10,
      },
      labelBold: {
        fontSize: 8.5,
        bold: true,
      },
      labelNormal: {
        fontSize: 8.5,
      },
      subjectLabel: {
        fontSize: normalFontSize,
        bold: true,
        margin: cellmargin,
      },
      gradeBold: {
        fontSize: normalFontSize,
        bold: true,
        margin: cellmargin,
        alignment: "center",
      },
      gradeNormal: {
        fontSize: normalFontSize,
        margin: cellmargin,
        alignment: "center",
      },
      table: {
        margin: [2, 5, 10, 0],
      },
      averageFont: {
        fontSize: 11,
        bold: true,
        margin: cellmargin,
        alignment: "center",
      },
    },
  };

  try {
    pdfMake.createPdf(documentDefinition).open();
  } catch (error) {}

  //   pdfMake.createPdf(documentDefinition).print({}, window);
};
