import React, { useEffect, useState } from "react";
import { Layout, Dropdown, Menu, Row, Col, Avatar, Button, Typography } from "antd";
import { LogoutOutlined, UserOutlined } from "@ant-design/icons";
import { queryCache } from "react-query";
const Topbar = ({ breakpoint, title }) => {
  const [userData, setUserData] = useState(null);
  const { Header } = Layout;
  const { Title } = Typography;

  const menu = (
    <Menu>
      <Menu.Item
        onClick={() => {
          localStorage.clear();
          queryCache.clear();
          window.location = `/`;
        }}
      >
        <LogoutOutlined /> Logout
      </Menu.Item>
    </Menu>
  );
  useEffect(() => {
    const udata = localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user")) : null;
    setUserData({ ...udata });
  }, []);

  return (
    <Header style={{ padding: "0 16px", backgroundColor: "#fff" }}>
      {userData ? (
        <Row type="flex" justify="space-between" align="middle">
          <Col>
            <Title level={4}>{title}</Title>
          </Col>
          <Col>
            <Dropdown overlay={menu} placement="bottomRight" trigger={["click"]}>
              <Button
                style={{
                  height: 55,
                  border: "none",
                }}
              >
                <Avatar
                  icon={<UserOutlined />}
                  style={{
                    color: "#2196F3",
                    backgroundColor: "#ddf0ff",
                    marginRight: 10,
                  }}
                />
                Welcome! {userData.firstname}
              </Button>
            </Dropdown>
          </Col>
        </Row>
      ) : null}
    </Header>
  );
};

export default Topbar;
