import React, { useState, useEffect, useCallback } from "react";
import {
  Table,
  Button,
  Tag,
  Space,
  Typography,
  Form,
  Select,
  Drawer,
  message,
  Input,
  Tooltip,
  Modal,
  Tabs,
} from "antd";
import { useDrag, useDrop } from "react-dnd";
import update from "immutability-helper";
import { useMutation, queryCache } from "react-query";
import http from "services/httpService.js";
import { generateString } from "helpers/randomStringGenerator";
import { titleCase } from "helpers/textTransformer";
import moment from "moment";
import {
  customRegsSH,
  customSubjectsSH,
} from "views/PermanentRecord/components/GradesComposer/CustomData/Index";
import {
  DeleteOutlined,
  PlusOutlined,
  SaveOutlined,
  CopyOutlined,
  UndoOutlined,
  FileTextOutlined,
  CloseOutlined,
} from "@ant-design/icons";

import DragAndDrop from "views/PermanentRecord/components/SubjectComposer/DragAndDrop";
import ComposerSHSecondSem from "views/PermanentRecord/components/SubjectComposer/ComposerSHSecondSem";

import { useSubjectsSR, useSubjectsSRCategory } from "views/SYSubjects/hooks/Index";

const type = "DragableBodyRow";
const { Option } = Select;
const { Text } = Typography;
const { confirm } = Modal;
const { TabPane } = Tabs;

const DragableBodyRow = ({ index, moveRow, record, className, style, ...restProps }) => {
  const ref = React.useRef();
  const [{ isOver, dropClassName }, drop] = useDrop({
    accept: type,
    collect: (monitor) => {
      const { index: dragIndex } = monitor.getItem() || {};
      if (dragIndex === index) {
        return {};
      }
      return {
        isOver: monitor.isOver(),
        dropClassName: dragIndex < index ? " drop-over-downward" : " drop-over-upward",
      };
    },

    drop: (item) => {
      moveRow(item.index, index);
    },
  });
  const [, drag] = useDrag({
    item: { type, index },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  drop(drag(ref));

  return (
    <tr
      ref={ref}
      className={`${className}${isOver ? dropClassName : ""}`}
      style={{ cursor: "move", ...style }}
      {...restProps}
    />
  );
};

const SelectionForm = ({
  form,
  levelDetails,
  handlesAddPrimarySubjectbySem,
  regs,
  setRegs,
  getSySubjectCustom,
  setData,
  setSubjectSYCodes,
  setCategoryCodes,
  setDataSem2,
  setSubjectSYCodesSem2,
  setCategoryCodesSem2,
}) => {
  const [newSubjectform] = Form.useForm();

  const [mutate] = useMutation(
    async (values) => {
      await http.post(`subjectsr/store`, {
        values: values,
      });
    },
    {
      onSuccess: (data, variables) => {
        message.success("Subject saved.");
        queryCache.invalidateQueries("subjectsr");
      },
      onError: () => {
        message.warning("Unable to save, Something went wrong :/");
      },
    }
  );
  const sySelections = generateSy();

  function generateSy() {
    let yearArray = [];
    let yearNow = Number(moment().format("YYYY")) - 1;
    for (let i = yearNow; i > 2000; i--) {
      yearArray.push(`${i}-${i + 1}`);
    }
    return yearArray;
  }

  useEffect(() => {
    form.setFieldsValue({ level_code: levelDetails.level, sy: null, is_transferee: "0" });
  }, [levelDetails, form]);

  const onSelectionChange = (value, option, type) => {
    switch (type) {
      case "sy":
        setRegs({ ...regs, sy: value });
        break;
      case "type":
        setRegs({ ...regs, is_transferee: Number(value) });
        break;
      default:
        return;
    }
  };

  const handlesCopySySubjects = () => {
    if (form.getFieldValue("sy")) {
      getSySubjectCustom(form.getFieldValue("sy"), levelDetails.level_code);
    } else {
      message.warning("Please select School Year.");
    }
  };
  const handlesClearSubjects = (e) => {
    e.preventDefault();
    getSySubjectCustom("9999", 1000);
    // console.log("clear");
    // // setData([]);
    // // setSubjectSYCodes([]);
    // setCategoryCodes([]);
    // // setDataSem2([]);
    // // setSubjectSYCodesSem2([]);
    // // setCategoryCodesSem2([]);

    // setData(data.subjectsSRSY);
    // setSubjectSYCodes(data.subjectSRSYCodes);
    // setCategoryCodes(data.categoryCodes);
    // setDataSem2(data.subjectsSRSYSem2);
    // setSubjectSYCodesSem2(data.subjectSRSYCodesSem2);
    // setCategoryCodesSem2(data.categoryCodesSem2);
  };

  const handlesAddNewSubject = () => {
    confirm({
      title: "Create New Subject.",
      icon: <FileTextOutlined />,
      content: (
        <>
          <p>Newly created subject/s will be added to global subject list.</p>
          <Form layout="vertical" form={newSubjectform}>
            <Form.Item label="Subject Name" name="newsubject">
              <Input />
            </Form.Item>
          </Form>
        </>
      ),
      onOk() {
        if (
          !newSubjectform.getFieldValue("newsubject") ||
          newSubjectform.getFieldValue("newsubject") === ""
        ) {
          message.error("Subject name cannot be blank.");
          return true;
        } else {
          handlesAppendSubject();
        }
      },
      onCancel() {
        newSubjectform.setFieldsValue({ newsubject: null });
      },
    });
  };
  const handlesAppendSubject = () => {
    let newSubjectID = Number(generateString(5, null, "6339854632145214985419271323541123456789"));
    let subjectName = newSubjectform.getFieldValue("newsubject");
    newSubjectform.setFieldsValue({ newsubject: null });

    mutate({ subject_code: newSubjectID, subject: titleCase(subjectName), units: 0, locked: 1 });
    handlesAddPrimarySubjectbySem({
      subject_code: newSubjectID,
      subject: titleCase(subjectName),
      units: 0,
      locked: 1,
    });
  };

  return (
    <>
      <Form layout="inline" form={form}>
        <Form.Item label="Select School Year" name="sy">
          <Select
            placeholder="none"
            style={{ width: 120 }}
            onChange={(value, option) => {
              onSelectionChange(value, option, "sy");
            }}
            rules={[
              {
                required: true,
                message: "Please select School Year!",
              },
            ]}
          >
            {sySelections.map((item) => {
              return (
                <Option value={item} key={item}>
                  {item}
                </Option>
              );
            })}
          </Select>
        </Form.Item>
        <Form.Item label="Select Level" name="level_code">
          <Input readOnly />
        </Form.Item>
        <Form.Item label="Type" name="is_transferee" initialValue="0">
          <Select
            style={{ width: 120 }}
            onChange={(value, option) => {
              onSelectionChange(value, option, "type");
            }}
            rules={[
              {
                required: true,
                message: "Please select type!",
              },
            ]}
          >
            <Option value="0">Regular</Option>
            <Option value="1">Transfree</Option>
          </Select>
        </Form.Item>
      </Form>
      <div>
        <Tooltip title="Load from SY Subjects">
          <Button
            type="dashed"
            icon={<CopyOutlined />}
            style={{ marginRight: 8 }}
            onClick={handlesCopySySubjects}
          >
            Load from Sy Subjects
          </Button>
        </Tooltip>
        <Tooltip title="Add New Subject">
          <Button
            type="dashed"
            icon={<PlusOutlined />}
            style={{ marginRight: 8 }}
            onClick={handlesAddNewSubject}
          >
            Add New Subject
          </Button>
        </Tooltip>

        <Tooltip title="Clear Subjects">
          <Button type="dashed" icon={<UndoOutlined />} onClick={handlesClearSubjects}>
            Clear Subjects
          </Button>
        </Tooltip>
      </div>
    </>
  );
};

const CategorySelection = ({ categoryCodes, handlesAddPrimarySubject }) => {
  const { isLoading, data: subjectsrcategory } = useSubjectsSRCategory();
  const [value, setValue] = useState(null);

  const onSelectionChange = (value, option) => {
    handlesAddPrimarySubject(
      { subjects_sr_category_id: value, subjects_sr_category: option.children },
      true
    );
    setValue(null);
  };

  return (
    <Select
      style={{ width: 200 }}
      onChange={(value, option) => {
        onSelectionChange(value, option);
      }}
      loading={isLoading}
      value={value}
      placeholder="Select Category"
    >
      {!isLoading
        ? subjectsrcategory.map((item) => {
            return (
              <Option
                value={item.subjects_sr_category_id}
                key={item.subjects_sr_category_id}
                disabled={categoryCodes.includes(item.subjects_sr_category_id) ? true : false}
              >
                {item.subjects_sr_category}
              </Option>
            );
          })
        : null}
    </Select>
  );
};
// for extraction
const SubjectSRSelection = ({
  modalVisibility,
  setModalVisibility,
  subjectSYCodes,
  handlesAddPrimarySubject,
}) => {
  const columns = [
    {
      title: "Subject Code",
      dataIndex: "subject_code",
      key: "subject_code",
      render: (text, record) => (
        <span className={subjectSYCodes.includes(record.subject_code) ? "disabled-row" : ""}>
          {record.subject_code}
        </span>
      ),
    },
    {
      title: "Subject",
      dataIndex: "subject",
      key: "subject",
      render: (text, record) => (
        <span className={subjectSYCodes.includes(record.subject_code) ? "disabled-row" : ""}>
          {record.subject}
        </span>
      ),
    },
    {
      title: "Units",
      dataIndex: "units",
      key: "units",
      render: (text, record) => (
        <span className={subjectSYCodes.includes(record.subject_code) ? "disabled-row" : ""}>
          {record.units}
        </span>
      ),
    },
  ];
  const { data: subjectsr } = useSubjectsSR();
  const [tableData, setTableData] = useState([]);
  useEffect(() => {
    setTableData(subjectsr);
  }, [subjectsr]);

  const handlesRowClick = (event, record) => {
    if (subjectSYCodes.includes(record.subject_code)) {
      message.warning("Already in the list. ");
    } else {
      handlesAddPrimarySubject(record, false);
    }
  };
  return (
    <Drawer
      title="Subject Senior High School"
      width={650}
      placement="right"
      closable={true}
      onClose={() => setModalVisibility(false)}
      visible={modalVisibility}
      footer={
        <div
          style={{
            textAlign: "right",
          }}
        >
          <Button onClick={() => setModalVisibility(false)} type="primary">
            Close
          </Button>
        </div>
      }
    >
      <Table
        columns={columns}
        dataSource={tableData}
        rowKey="subject_code"
        pagination={false}
        size="small"
        onRow={(record, rowIndex) => {
          return {
            onClick: (event) => {
              handlesRowClick(event, record);
            }, // click row
          };
        }}
      />
    </Drawer>
  );
};

const ComposerSH = ({
  levelDetails,
  setSubjectComposerDrawerSH,
  setLevelDetails,
  regsDetails,
  customGradesData,
  setCustomGradesData,
  setGradesComposerDrawer,
}) => {
  const [selectionForm] = Form.useForm();
  const [selectedSyLevel, setSelectedSyLevel] = useState({
    sy: null,
    level_code: null,
    level: null,
    sem: null,
  });
  const [modalVisibility, setModalVisibility] = useState(false);
  const [subjectSYCodes, setSubjectSYCodes] = useState([]);
  const [categoryCodes, setCategoryCodes] = useState([]);
  const [data, setData] = useState([]);
  const [regs, setRegs] = useState({});
  const [subjectSYCodesSem2, setSubjectSYCodesSem2] = useState([]);
  const [categoryCodesSem2, setCategoryCodesSem2] = useState([]);
  const [dataSem2, setDataSem2] = useState([]);

  const [activeSem, setActiveSem] = useState("1");

  useEffect(() => {
    setRegs(regsDetails);
  }, [regsDetails]);

  async function getSySubjectCustom(sy, levelCode) {
    try {
      const { data } = await http.get(`subjectsrsycustom/${sy}/${levelCode}`);
      setData(data.subjectsSRSY);
      setSubjectSYCodes(data.subjectSRSYCodes);
      setCategoryCodes(data.categoryCodes);
      setDataSem2(data.subjectsSRSYSem2);
      setSubjectSYCodesSem2(data.subjectSRSYCodesSem2);
      setCategoryCodesSem2(data.categoryCodesSem2);
    } catch (error) {
      alert(error.message);
    }
  }
  const columns = [
    {
      title: () => (
        <Space>
          <Text>Subjects</Text>
          {selectedSyLevel.sy && selectedSyLevel.level_code ? (
            <Text> {`for (SY - ${selectedSyLevel.sy}) (Level - ${selectedSyLevel.level})`}</Text>
          ) : null}

          <CategorySelection
            categoryCodes={categoryCodes}
            handlesAddPrimarySubject={handlesAddPrimarySubject}
          />
        </Space>
      ),
      name: "subject",
      dataIndex: "subject",
      //   width: 300,
      render: (text, record) => {
        let iscateg = record.isCategory === 1 ? true : false;
        return (
          <Space>
            {iscateg ? <Tag color="geekblue">Category</Tag> : null}
            <span
              style={{ paddingLeft: iscateg ? 3 : 50, fontWeight: iscateg ? "bold" : "normal" }}
            >
              {iscateg ? record.subject.toUpperCase() : record.subject}
            </span>
          </Space>
        );
      },
    },
    {
      title: (
        <Button
          shape="circle"
          icon={<PlusOutlined />}
          type="primary"
          onClick={() => {
            setModalVisibility(true);
          }}
        ></Button>
      ),
      key: "manage",
      dataIndex: "manage",
      width: 60,
      render: (text, record, index) => (
        <Button
          size="small"
          type="link"
          danger
          icon={<DeleteOutlined />}
          onClick={() => handlesDeleteRow(record, index, record.isCategory === 1 ? true : false)}
        />
      ),
    },
  ];
  const components = {
    body: {
      row: DragableBodyRow,
    },
  };

  const moveRow = useCallback(
    (dragIndex, hoverIndex) => {
      const dragRow = data[dragIndex];
      setData(
        update(data, {
          $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, dragRow],
          ],
        })
      );
    },
    [data]
  );

  const handlesDeleteRow = (record, index, isCategory) => {
    if (isCategory) {
      setCategoryCodes(categoryCodes.filter((item) => item !== record.subjects_sr_category_id));
    } else {
      setSubjectSYCodes(subjectSYCodes.filter((item) => item !== record.subject_code));
    }
    setData(
      update(data, {
        $splice: [[index, 1]],
      })
    );
  };

  const handlesAddPrimarySubject = (record, isCategory) => {
    if (isCategory) {
      let scode = Math.floor(Math.random() * 1000);
      setData([
        ...data,
        {
          subject_code: scode,
          subject: record.subjects_sr_category,
          with_sub: 0,
          isCategory: 1,
          subjects_sr_category_id: record.subjects_sr_category_id,
          sem: activeSem,
        },
      ]);
      setCategoryCodes([...categoryCodes, record.subjects_sr_category_id]);
    } else {
      setData([
        ...data,
        {
          subject_code: record.subject_code,
          subject: record.subject,
          with_sub: 0,
          isCategory: 0,
          sem: activeSem,
        },
      ]);
      setSubjectSYCodes([...subjectSYCodes, record.subject_code]);
    }
  };

  const handlesAddPrimarySubjectbySem = (record) => {
    if (Number(activeSem) === 1) {
      setData([
        ...data,
        {
          subject_code: record.subject_code,
          subject: record.subject,
          with_sub: 0,
          isCategory: 0,
          sem: activeSem,
        },
      ]);
      setSubjectSYCodes([...subjectSYCodes, record.subject_code]);
    } else if (Number(activeSem) === 2) {
      setDataSem2([
        ...dataSem2,
        {
          subject_code: record.subject_code,
          subject: record.subject,
          with_sub: 0,
          isCategory: 0,
          sem: activeSem,
        },
      ]);
      setSubjectSYCodesSem2([...subjectSYCodesSem2, record.subject_code]);
    }
  };

  const handlesSave = (event) => {
    event.preventDefault();

    let dataLength = data.length;
    let dataLengthSem2 = dataSem2.length;
    let syData = selectionForm.getFieldValue("sy");

    if (!syData) {
      message.warning("Please select School Year.");
    } else if (dataLength <= 0) {
      message.warning("First Semester Subject list cannot be empty.");
    } else if (dataLengthSem2 <= 0) {
      message.warning("Second Semester Subject list cannot be empty.");
    } else if (data[0].isCategory !== 1) {
      message.warning(
        "Invalid Subject structure on Semester 1, First item must be Subject Category."
      );
    } else if (dataSem2[0].isCategory !== 1) {
      message.warning(
        "Invalid Subject structure on Semester 2, First item must be Subject Category."
      );
    } else {
      let mergedData = [...data, ...dataSem2];
      let customRegs = customRegsSH(regs);
      let customSubjects = customSubjectsSH(regs, mergedData);

      setCustomGradesData({
        ...customGradesData,
        credentials: {
          registration_no: regs.registration_no,
          school_level_category_id: 2,
          sem: 1,
        },
        newCustomGrades: true,
        regs: customRegs,
        subjects: customSubjects,
      });
      setLevelDetails({ ...levelDetails, sy: regs.sy });
      handlesCancel();
      setGradesComposerDrawer(true);
    }
  };

  const handlesCancel = () => {
    setData([]);
    setSubjectSYCodes([]);
    setCategoryCodes([]);
    setDataSem2([]);
    setSubjectSYCodesSem2([]);
    setCategoryCodesSem2([]);
    // setLevelDetails({});
    setActiveSem("1");
    setSelectedSyLevel({
      ...selectedSyLevel,
      level_code: null,
      sy: null,
      level: null,
    });
    selectionForm.setFieldsValue({ level_code: null, sy: null, is_transferee: "0" });
    setSubjectComposerDrawerSH(false);
  };

  const onChangeTab = (key) => {
    setActiveSem(key);
  };
  return (
    <>
      <SelectionForm
        form={selectionForm}
        levelDetails={levelDetails}
        handlesAddPrimarySubjectbySem={handlesAddPrimarySubjectbySem}
        regs={regs}
        setRegs={setRegs}
        getSySubjectCustom={getSySubjectCustom}
        setData={setData}
        setSubjectSYCodes={setSubjectSYCodes}
        setCategoryCodes={setSubjectSYCodes}
        setDataSem2={setSubjectSYCodes}
        setSubjectSYCodesSem2={setSubjectSYCodes}
        setCategoryCodesSem2={setSubjectSYCodes}
      />

      <Tabs onChange={onChangeTab} activeKey={activeSem}>
        <TabPane tab="First Semester" key="1">
          <DragAndDrop>
            <Table
              columns={columns}
              dataSource={data}
              components={components}
              onRow={(record, index) => ({
                index,
                moveRow,
              })}
              pagination={false}
              rowKey="subject_code"
              // loading={isLoading}
              size="small"
              //   style={{ pointerEvents: "none", opacity: "0.4" }}
            />
          </DragAndDrop>
        </TabPane>
        <TabPane tab="Second Semester" key="2">
          <ComposerSHSecondSem
            subjectSYCodesSem2={subjectSYCodesSem2}
            setSubjectSYCodesSem2={setSubjectSYCodesSem2}
            categoryCodesSem2={categoryCodesSem2}
            setCategoryCodesSem2={setCategoryCodesSem2}
            dataSem2={dataSem2}
            setDataSem2={setDataSem2}
            activeSem={activeSem}
          />
        </TabPane>
      </Tabs>

      <Space style={{ marginTop: 20 }}>
        <Button
          icon={<SaveOutlined />}
          type="primary"
          onClick={(event) => {
            handlesSave(event);
          }}
        >
          Save
        </Button>
        <Button icon={<CloseOutlined />} onClick={handlesCancel}>
          Cancel
        </Button>
      </Space>

      <SubjectSRSelection
        modalVisibility={modalVisibility}
        setModalVisibility={setModalVisibility}
        subjectSYCodes={subjectSYCodes}
        handlesAddPrimarySubject={handlesAddPrimarySubject}
      />
    </>
  );
};

export default ComposerSH;
