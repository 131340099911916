import pdfMake from "pdfmake/build/pdfmake";
import vfsFonts from "pdfmake/build/vfs_fonts";

export default (data, grading_period) => {
  const { vfs } = vfsFonts.pdfMake;
  pdfMake.vfs = vfs;

  console.log(grading_period);

  const cellmargin = 0;
  const normalFontSize = 8;
  const leftLineFeed = "\n\n";
  const rightLineFeed = "\n\n\n";

  const documentDefinition = {
    pageMargins: [20, 20, 10, 10],
    pageSize: "LETTER",
    content: [
      data.map((d, index) => {
        return [
          {
            text: "LITTLE SHEPHERD INTEGRATED MONTESSORI",
            style: "header",
            pageBreak: index !== 0 && index % 3 === 0 ? "before" : "",
          },
          {
            text: "LITTLE SHEPHERD BUSINESS AND SCIENCE HIGH SCHOOL",
            style: "header",
          },
          {
            text: "Lumban, Laguna",
            style: "subheader",
          },
          {
            width: "*",
            margin: [0, 10, 0, 0],
            columns: [
              {
                columns: [
                  {
                    width: 40,
                    text: "TRACK: ",
                    style: "gradeNormal",
                  },
                  {
                    width: "*",
                    text: "ACADEMIC",
                    style: "labelBold",
                  },
                ],
              },
              {
                margin: [0, 0, 0, 0],
                text: d.header,
                style: "title",
              },
              {
                columns: [
                  {
                    text: "STRAND: ",
                    style: "gradeNormal",
                    alignment: "right",
                  },
                  {
                    text: "GENERAL ACADEMIC",
                    style: "labelBold",
                    alignment: "right",
                    margin: [0, 0, 15, 0],
                  },
                ],
              },
            ],
          },
          {
            text:
              "_______________________________________________________________________________________________________________________________________________________________________________________",
            margin: [0, -3, 0, 4],
            fontSize: 7,
          },

          {
            columns: [
              {
                width: 120,
                columns: [
                  {
                    width: 28,
                    text: "LRN:",
                    style: "labelBold",
                  },
                  {
                    width: "*",
                    text: d.lrn,
                    style: "labelNormal",
                  },
                ],
              },
              {
                width: "*",
                columns: [
                  {
                    width: 28,
                    text: "Name:",
                    style: "labelBold",
                  },
                  {
                    width: "*",
                    text: d.fullname,
                    style: "labelNormal",
                  },
                ],
              },
              {
                width: 120,
                columns: [
                  {
                    width: 28,
                    text: "Level:",
                    style: "labelBold",
                  },
                  {
                    width: "*",
                    text: d.level,
                    style: "labelNormal",
                  },
                ],
              },
              {
                width: 120,
                columns: [
                  {
                    width: 28,
                    text: "SY:",
                    style: "labelBold",
                  },
                  {
                    width: "*",
                    text: d.sy,
                    style: "labelNormal",
                  },
                ],
              },
            ],
          },
          {
            columns: [
              {
                width: 380,
                margin: [0, 5, 20, 0],
                style: "table",
                layout: "noBorders",
                table: {
                  widths: ["*", 100],
                  body: d.subjects,
                  //   [
                  //     [
                  //       { text: "CORE SUBJECT", style: "subjectLabel" },
                  //       { text: "First Grading Period", style: "subjectLabel", alignment: "center" },
                  //     ],
                  //     [
                  //       { text: "Oral Communicatio", style: "subjectLabelNormal" },
                  //       { text: "88.251", style: "gradeNormal" },
                  //     ],
                  //     [
                  //       {
                  //         text: "General Mathematics",
                  //         style: "subjectLabelNormal",
                  //       },
                  //       { text: "88.251", style: "gradeNormal" },
                  //     ],
                  //     [
                  //       {
                  //         text: "General Mathematics",
                  //         style: "subjectLabelNormal",
                  //       },
                  //       { text: "88.251", style: "gradeNormal" },
                  //     ],
                  //     [
                  //       {
                  //         text: "General Mathematics",
                  //         style: "subjectLabelNormal",
                  //       },
                  //       { text: "88.251", style: "gradeNormal" },
                  //     ],
                  //     [
                  //       {
                  //         text: "General Mathematics",
                  //         style: "subjectLabelNormal",
                  //       },
                  //       { text: "88.251", style: "gradeNormal" },
                  //     ],
                  //     [
                  //       {
                  //         text: "General Mathematics",
                  //         style: "subjectLabelNormal",
                  //       },
                  //       { text: "88.251", style: "gradeNormal" },
                  //     ],
                  //     [
                  //       {
                  //         text: "Komunikasyon at Pananaliksik Sa wika at Kulturang Pilipin",
                  //         style: "subjectLabelNormal",
                  //       },
                  //       { text: "88.251", style: "gradeNormal" },
                  //     ],
                  //     [
                  //       { text: "Physical Education and Health ", style: "subjectLabelNormal" },
                  //       { text: "88.251", style: "gradeNormal" },
                  //     ],
                  //     [{ text: "APPLIED TRACK SUBJECTS", style: "subjectLabel" }, {}],
                  //     [
                  //       {
                  //         text: "Music",
                  //         style: "subjectLabelNormal",
                  //         margin: [10, 0, 0, 0],
                  //       },
                  //       { text: "88.251", style: "gradeNormal" },
                  //     ],
                  //     [{ text: "SPECIALIZATION", style: "subjectLabel" }, {}],
                  //     [
                  //       {
                  //         text: "Music",
                  //         style: "subjectLabelNormal",
                  //         margin: [10, 0, 0, 0],
                  //       },
                  //       { text: "88.251", style: "gradeNormal" },
                  //     ],
                  //   ],
                },
              },

              [
                {
                  style: "table",
                  margin: [0, 40, 0, 40],
                  table: {
                    widths: ["*", 50],
                    body: [
                      [
                        {
                          border: [true, true, false, false],
                          text: "AVERAGE",
                          style: "subjectLabel",
                        },
                        {
                          border: [false, true, true, false],
                          text:
                            d.sem === 1
                              ? grading_period === "first_grading"
                                ? d.sem_one_first_ave
                                : d.sem_one_second_ave
                              : grading_period === "first_grading"
                              ? d.sem_two_first_ave
                              : d.sem_two_second_ave,
                          style: "averageFont",
                        },
                      ],
                      [
                        {
                          border: [true, false, false, false],
                          text: "Days of School",
                          style: "subjectLabel",
                        },
                        {
                          border: [false, false, true, false],
                          text: d.school_days,
                          style: "gradeBold",
                        },
                      ],
                      [
                        {
                          border: [true, false, false, true],
                          text: "Days Present",
                          style: "subjectLabel",
                        },
                        {
                          border: [false, false, true, true],
                          text: d.days_present,
                          style: "gradeBold",
                        },
                      ],
                    ],
                  },
                },
                { text: d.employee, style: "title", margin: [0, 0] },
                { text: "Teacher", style: "subheader", bold: false },
              ],
            ],
          },
          {
            text:
              index % 3 < 2
                ? leftLineFeed +
                  "---  ----- ---  ----- ---  ----- ---  ----- ---  ----- ---  ----- ---  ----- ---  ----- ---  ----- ---  ----- ---  ----- ---  ----- ---  ----- ---  ----- ---  ----- ---  ----- ---  ----- ---  ----- ---  ----- ---  ----- ---  ----- ---  ----- ---  ----- ---  ----- ---  ----- ---  ----- ---  ----- ---  ----- ---  ----- ---  ----- ---  ----- ---  ----- --- " +
                  rightLineFeed
                : null,
            fontSize: 6,
          },
        ];
      }),
    ],
    styles: {
      header: {
        fontSize: 10,
        bold: true,
        alignment: "center",
        lineHeight: 1.1,
      },
      subheader: {
        fontSize: normalFontSize,
        bold: true,
        alignment: "center",
      },
      title: {
        fontSize: 10,
        bold: true,
        alignment: "center",
        margin: 10,
      },
      labelBold: {
        fontSize: 8,
        bold: true,
      },
      labelNormal: {
        fontSize: 8.5,
      },
      subjectLabel: {
        fontSize: 7.8,
        bold: true,
        margin: cellmargin,
      },
      gradeBold: {
        fontSize: normalFontSize,
        bold: true,
        margin: cellmargin,
        alignment: "center",
      },
      gradeNormal: {
        fontSize: 7,
        margin: 0,
        alignment: "center",
      },
      table: {
        margin: [2, 5, 10, 0],
      },
      averageFont: {
        fontSize: 11,
        bold: true,
        margin: cellmargin,
        alignment: "center",
      },
      subjectLabelNormal: {
        fontSize: 7,
        margin: [10, 0, 0, 0],
      },
    },
  };

  try {
    pdfMake.createPdf(documentDefinition).open();
  } catch (error) {}

  //   pdfMake.createPdf(documentDefinition).print({}, window);
};
