import React, { useState } from "react";

import { Table, Tag, Input, Button, Row, Col, Drawer, Avatar } from "antd";
import { PlusOutlined, UserOutlined } from "@ant-design/icons";
import { useEmployeeQuery, useSettingsQuery } from "views/SYSubjects/hooks/Index";

import EmployeeForm from "views/Employee/components/EmployeeForm/Index";
import { formatAge } from "helpers/ageFormatter";
import { titleCase } from "helpers/textTransformer";

const { Search } = Input;

const columns = [
  {
    title: "Name",
    key: "name",
    width: 320,
    render: (text, record) =>
      record.img_path ? (
        <span>
          <Avatar src={record.img_path.replace(".jpg", "-thumb.jpg")} style={{ marginRight: 10 }} />
          {`${record.lastname}, ${record.firstname} ${record.middlename}`}
        </span>
      ) : (
        <span>
          <Avatar icon={<UserOutlined />} style={{ marginRight: 10 }} />{" "}
          {`${record.lastname}, ${record.firstname} ${record.middlename}`}
        </span>
      ),
  },

  {
    title: "BirthDay",
    key: "birth_day",
    width: 100,
    render: (text, record) => <span>{record.birth_day}</span>,
  },
  {
    title: "Age",
    key: "age",
    width: 70,
    render: (text, record) => (
      <span>{record.birth_day ? formatAge("Y", record.birth_day) : record.birth_day}</span>
    ),
  },
  {
    title: "Gender",
    key: "gender",
    width: 80,
    render: (text, record) => {
      let tagColor = "geekblue";
      if (record.gender === "female") {
        tagColor = "volcano";
      }
      return <Tag color={tagColor}>{record.gender ? titleCase(record.gender) : record.gender}</Tag>;
    },
  },
  {
    title: "Position",
    key: "designation",
    width: 140,
    render: (text, record) => <span>{record.designation}</span>,
  },
  {
    title: "Entry SY",
    key: "entry_sy",
    width: 140,
    render: (text, record) => <span>{record.entry_sy}</span>,
  },
];
const tablePagination = {
  showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} Employees`,
};
const Employees = () => {
  const [drawerVisibility, setDrawerVisibility] = useState(false);
  const [currentData, setCurrentData] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [searchText, setSearchText] = useState(null);

  const { isLoading: isLoadingEmployees, data: employees } = useEmployeeQuery(searchText);
  useSettingsQuery();
  const handleRowclick = (evt, record) => {
    console.log(record);
    setIsEditing(true);
    setCurrentData(record);
    setDrawerVisibility(true);
  };

  const createStudentRecord = () => {
    setIsEditing(false);
    setCurrentData(null);
    setDrawerVisibility(true);
  };
  const handleSearchKeyUp = (e) => {
    if (e.target.value.trim() === "") {
      setSearchText(null);
    }
  };
  const handleSearch = (value) => {
    if (value.length <= 0) {
      setSearchText(null);
    } else {
      setSearchText(value);
    }
  };
  return (
    <>
      <Row type="flex" justify="space-between">
        <Col>
          <Search
            placeholder="Search Employee "
            enterButton
            allowClear
            onKeyUp={handleSearchKeyUp}
            onSearch={
              (value) => {
                handleSearch(value.trim());
              }
              //
            }
            style={{
              width: 300,
              marginBottom: 10,
            }}
          />
        </Col>
        <Col>
          <div
            style={{
              textAlign: "right",
              width: "auto",
            }}
          >
            <Button
              type="primary"
              style={{
                marginBottom: 10,
              }}
              icon={<PlusOutlined />}
              onClick={createStudentRecord}
            >
              Add Employee
            </Button>
          </div>
        </Col>
      </Row>
      <Table
        columns={columns}
        rowKey="employee_id"
        dataSource={employees || []}
        pagination={tablePagination}
        loading={isLoadingEmployees}
        onRow={(record) => {
          return {
            onClick: (event) => {
              handleRowclick(event, record);
            },
          };
        }}
      />
      <Drawer
        width={840}
        placement="right"
        closable={false}
        visible={drawerVisibility}
        destroyOnClose={true}
      >
        <EmployeeForm
          currentData={currentData}
          isEditing={isEditing}
          setDrawerVisibility={setDrawerVisibility}
        />
      </Drawer>
    </>
  );
};

export default Employees;
