import React, { useState, useEffect, useCallback } from "react";
import { Table, Button, Tag, Space, Typography, Select, Drawer, message } from "antd";

import { useDrag, useDrop } from "react-dnd";
import update from "immutability-helper";
import { DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import DragAndDrop from "views/PermanentRecord/components/SubjectComposer/DragAndDrop";
import { useSubjectsSR, useSubjectsSRCategory } from "views/SYSubjects/hooks/Index";

const type = "DragableBodyRow";
const { Option } = Select;
const { Text } = Typography;

// for extraction
const DragableBodyRow = ({ index, moveRow, record, className, style, ...restProps }) => {
  const ref = React.useRef();
  const [{ isOver, dropClassName }, drop] = useDrop({
    accept: type,
    collect: (monitor) => {
      const { index: dragIndex } = monitor.getItem() || {};
      if (dragIndex === index) {
        return {};
      }
      return {
        isOver: monitor.isOver(),
        dropClassName: dragIndex < index ? " drop-over-downward" : " drop-over-upward",
      };
    },

    drop: (item) => {
      moveRow(item.index, index);
    },
  });
  const [, drag] = useDrag({
    item: { type, index },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  drop(drag(ref));

  return (
    <tr
      ref={ref}
      className={`${className}${isOver ? dropClassName : ""}`}
      style={{ cursor: "move", ...style }}
      {...restProps}
    />
  );
};

// for extraction

const CategorySelection = ({ categoryCodesSem2, handlesAddPrimarySubject }) => {
  const { isLoading, data: subjectsrcategory } = useSubjectsSRCategory();
  const [value, setValue] = useState(null);

  const onSelectionChange = (value, option) => {
    handlesAddPrimarySubject(
      { subjects_sr_category_id: value, subjects_sr_category: option.children },
      true
    );
    setValue(null);
  };

  return (
    <Select
      style={{ width: 200 }}
      onChange={(value, option) => {
        onSelectionChange(value, option);
      }}
      loading={isLoading}
      value={value}
      placeholder="Select Category"
    >
      {!isLoading
        ? subjectsrcategory.map((item) => {
            return (
              <Option
                value={item.subjects_sr_category_id}
                key={item.subjects_sr_category_id}
                disabled={categoryCodesSem2.includes(item.subjects_sr_category_id) ? true : false}
              >
                {item.subjects_sr_category}
              </Option>
            );
          })
        : null}
    </Select>
  );
};
// for extraction
const SubjectSRSelection = ({
  modalVisibility,
  setModalVisibility,
  subjectSYCodesSem2,
  handlesAddPrimarySubject,
}) => {
  const columns = [
    {
      title: "Subject Code",
      dataIndex: "subject_code",
      key: "subject_code",
      render: (text, record) => (
        <span className={subjectSYCodesSem2.includes(record.subject_code) ? "disabled-row" : ""}>
          {record.subject_code}
        </span>
      ),
    },
    {
      title: "Subject",
      dataIndex: "subject",
      key: "subject",
      render: (text, record) => (
        <span className={subjectSYCodesSem2.includes(record.subject_code) ? "disabled-row" : ""}>
          {record.subject}
        </span>
      ),
    },
    {
      title: "Units",
      dataIndex: "units",
      key: "units",
      render: (text, record) => (
        <span className={subjectSYCodesSem2.includes(record.subject_code) ? "disabled-row" : ""}>
          {record.units}
        </span>
      ),
    },
  ];
  const { data: subjectsr } = useSubjectsSR();
  const [tableData, setTableData] = useState([]);
  useEffect(() => {
    setTableData(subjectsr);
  }, [subjectsr]);

  const handlesRowClick = (event, record) => {
    if (subjectSYCodesSem2.includes(record.subject_code)) {
      message.warning("Already in the list. ");
    } else {
      handlesAddPrimarySubject(record, false);
    }
  };
  return (
    <Drawer
      title="Subject Senior High School"
      width={650}
      placement="right"
      closable={true}
      onClose={() => setModalVisibility(false)}
      visible={modalVisibility}
      footer={
        <div
          style={{
            textAlign: "right",
          }}
        >
          <Button onClick={() => setModalVisibility(false)} type="primary">
            Close
          </Button>
        </div>
      }
    >
      <Table
        columns={columns}
        dataSource={tableData}
        rowKey="subject_code"
        pagination={false}
        size="small"
        onRow={(record, rowIndex) => {
          return {
            onClick: (event) => {
              handlesRowClick(event, record);
            }, // click row
          };
        }}
      />
    </Drawer>
  );
};

const ComposerSHSecondSem = ({
  subjectSYCodesSem2,
  setSubjectSYCodesSem2,
  categoryCodesSem2,
  setCategoryCodesSem2,
  dataSem2,
  setDataSem2,
  activeSem,
}) => {
  const [modalVisibility, setModalVisibility] = useState(false);

  const columns = [
    {
      title: () => (
        <Space>
          <Text>Subjects</Text>

          <CategorySelection
            categoryCodesSem2={categoryCodesSem2}
            handlesAddPrimarySubject={handlesAddPrimarySubject}
          />
        </Space>
      ),
      name: "subject",
      dataIndex: "subject",
      render: (text, record) => {
        let iscateg = record.isCategory === 1 ? true : false;
        return (
          <Space>
            {iscateg ? <Tag color="geekblue">Category</Tag> : null}
            <span
              style={{ paddingLeft: iscateg ? 3 : 50, fontWeight: iscateg ? "bold" : "normal" }}
            >
              {iscateg ? record.subject.toUpperCase() : record.subject}
            </span>
          </Space>
        );
      },
    },
    {
      title: (
        <Button
          shape="circle"
          icon={<PlusOutlined />}
          type="primary"
          onClick={() => {
            setModalVisibility(true);
          }}
        ></Button>
      ),
      key: "manage",
      dataIndex: "manage",
      width: 60,
      render: (text, record, index) => (
        <Button
          size="small"
          type="link"
          danger
          icon={<DeleteOutlined />}
          onClick={() => handlesDeleteRow(record, index, record.isCategory === 1 ? true : false)}
        />
      ),
    },
  ];

  const components = {
    body: {
      row: DragableBodyRow,
    },
  };

  const moveRow = useCallback(
    (dragIndex, hoverIndex) => {
      const dragRow = dataSem2[dragIndex];
      setDataSem2(
        update(dataSem2, {
          $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, dragRow],
          ],
        })
      );
    },
    [dataSem2, setDataSem2]
  );

  const handlesDeleteRow = (record, index, isCategory) => {
    if (isCategory) {
      setCategoryCodesSem2(
        categoryCodesSem2.filter((item) => item !== record.subjects_sr_category_id)
      );
    } else {
      setSubjectSYCodesSem2(subjectSYCodesSem2.filter((item) => item !== record.subject_code));
    }
    setDataSem2(
      update(dataSem2, {
        $splice: [[index, 1]],
      })
    );
  };

  const handlesAddPrimarySubject = (record, isCategory) => {
    if (isCategory) {
      let scode = Math.floor(Math.random() * 1000);
      setDataSem2([
        ...dataSem2,
        {
          subject_code: scode,
          subject: record.subjects_sr_category,
          with_sub: 0,
          isCategory: 1,
          subjects_sr_category_id: record.subjects_sr_category_id,
          sem: activeSem,
        },
      ]);
      setCategoryCodesSem2([...categoryCodesSem2, record.subjects_sr_category_id]);
    } else {
      setDataSem2([
        ...dataSem2,
        {
          subject_code: record.subject_code,
          subject: record.subject,
          with_sub: 0,
          isCategory: 0,
          sem: activeSem,
        },
      ]);
      setSubjectSYCodesSem2([...subjectSYCodesSem2, record.subject_code]);
    }
  };

  return (
    <>
      <DragAndDrop>
        <Table
          columns={columns}
          dataSource={dataSem2}
          components={components}
          onRow={(record, index) => ({
            index,
            moveRow,
          })}
          pagination={false}
          rowKey="subject_code"
          size="small"
        />
      </DragAndDrop>

      <SubjectSRSelection
        modalVisibility={modalVisibility}
        setModalVisibility={setModalVisibility}
        subjectSYCodesSem2={subjectSYCodesSem2}
        handlesAddPrimarySubject={handlesAddPrimarySubject}
      />
    </>
  );
};

export default ComposerSHSecondSem;
