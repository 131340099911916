import React from "react";
import { Menu } from "antd";
import {
  DeleteOutlined,
  FileAddOutlined,
  ClusterOutlined,
  ClearOutlined,
  ProfileOutlined,
} from "@ant-design/icons";
const SYMenu = ({
  record,
  index,
  handlesDeleteSubject,
  handlesAddChildren,
  handlesClearChildren,
  setPrimarySubjectCredentials,
  setIsPrimary,
  setModalVisibility,
}) => {
  const hasSub = record.with_sub === 1 ? true : false;
  return (
    <Menu>
      <Menu.Item icon={<DeleteOutlined />} onClick={() => handlesDeleteSubject(record, index)}>
        Remove
      </Menu.Item>
      {!hasSub ? (
        <Menu.Item icon={<ClusterOutlined />} onClick={() => handlesAddChildren(record, index)}>
          Create Secondary Subjects
        </Menu.Item>
      ) : null}
      {hasSub ? (
        <Menu.Item icon={<ClearOutlined />} onClick={() => handlesClearChildren(record, index)}>
          Remove Secondary Subjects
        </Menu.Item>
      ) : null}
      {hasSub ? (
        <Menu.Item
          icon={<FileAddOutlined />}
          onClick={() => {
            setPrimarySubjectCredentials({
              primaryIndex: index,
              subject_code_primary: record.subject_code,
            });
            setIsPrimary(false);
            setModalVisibility(true);
          }}
        >
          Add Secondary Subjects
        </Menu.Item>
      ) : null}
      {hasSub ? (
        <Menu.Item icon={<ProfileOutlined />} disabled>
          Arrange Sub
        </Menu.Item>
      ) : null}
    </Menu>
  );
};

export default SYMenu;
