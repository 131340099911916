import { useQuery } from "react-query";
import http from "services/httpService.js";

//  ------------------- School Levels Grouped

function useSchoolLevelQuery(category) {
  return useQuery("schoolevel", async () => {
    const { data } = await http.get(`schoollevel/${category}`);
    return data;
  });
}

//  ------------------- School Levels

function useSchoolLevelListingQuery() {
  return useQuery("schoollevellist", async () => {
    const { data } = await http.get(`schoollevellist`);
    return data;
  });
}

//  ------------------- School Levels Category

function useSchoolLevelCategoryQuery() {
  return useQuery("schoollevelcategory", async () => {
    const { data } = await http.get(`schoollevelcategory`);
    return data;
  });
}

//  ------------------- SY Subjects k12

function useSYSubjects(obj) {
  return useQuery(
    ["subjectk12sy", { sy: obj.sy, level_code: obj.level_code }],
    async (key, obj) => {
      const { data } = await http.get(`subjectk12sy/${obj.sy}/${obj.level_code}`);
      return data;
    }
  );
}

function useSubjectsK12() {
  return useQuery("subjectk12", async () => {
    const { data } = await http.get(`subjectk12`);
    return data;
  });
}

// ----- for traits
function useTraits() {
  return useQuery("traits", async () => {
    const { data } = await http.get(`traits`);
    return data;
  });
}

function useSYTraits(obj) {
  return useQuery(
    ["traitssy", { sy: obj.sy, school_level_category_id: obj.school_level_category_id }],
    async (key, obj) => {
      const { data } = await http.get(`traitssy/${obj.sy}/${obj.school_level_category_id}`);
      return data;
    }
  );
}

// ---------- for Senior High

function useSRSYSubjects(obj) {
  return useQuery(
    ["subjectk12sy", { sy: obj.sy, level_code: obj.level_code, sem: obj.sem }],
    async (key, obj) => {
      const { data } = await http.get(`subjectsrsy/${obj.sy}/${obj.level_code}/${obj.sem}`);
      return data;
    }
  );
}

function useSubjectsSR() {
  return useQuery("subjectsr", async () => {
    const { data } = await http.get(`subjectsr`);
    return data;
  });
}

function useSubjectsSRCategory() {
  return useQuery("subjectsrcategory", async () => {
    const { data } = await http.get(`subjectsrcategory`);
    return data;
  });
}

// for teacher
function useSYTeachers(obj) {
  return useQuery(["teachersy", { sy: obj.sy, level_code: obj.level_code }], async (key, obj) => {
    const { data } = await http.get(`teachersy/${obj.sy}/${obj.level_code}`);
    return data;
  });
}

// for Registration
function useRegistration(obj) {
  return useQuery(
    ["registration", { sy: obj.sy, level_code: obj.level_code, employee_id: obj.employee_id }],
    async (key, obj) => {
      const { data } = await http.get(
        `registration/${obj.sy}/${obj.level_code}/${obj.employee_id}`
      );
      return data;
    }
  );
}

// Rating Sheet / Process Subjects
function useProcessSubjects(obj) {
  return useQuery(
    ["processsubjects", { sy: obj.sy, level_code: obj.level_code }],
    async (key, obj) => {
      const { data } = await http.get(`processsubjects/${obj.sy}/${obj.level_code}`);
      return data;
    }
  );
}

function useProcessSubjectsNone(obj) {
  return useQuery(
    ["processsubjectsnone", { sy: obj.sy, level_code: obj.level_code }],
    async (key, obj) => {
      const { data } = await http.get(`processsubjectsnone/${obj.sy}/${obj.level_code}`);
      return data;
    }
  );
}

function useStudentQuery(searchText) {
  return useQuery(["students", searchText], async (key, searchText) => {
    const { data } = await http.get(`students/${searchText}`);
    return data;
  });
}

function useSettingsQuery() {
  return useQuery(
    "settings",
    async () => {
      const { data } = await http.get("settings");
      return data;
    },
    {
      cacheTime: Infinity,
    }
  );
}

function useSYLockedQuery() {
  return useQuery(
    "sylocked",
    async () => {
      const { data } = await http.get("sylocked");
      return data;
    },
    {
      cacheTime: Infinity,
    }
  );
}

function useEmployeeQuery(searchText) {
  return useQuery(["employees", searchText], async (key, searchText) => {
    const { data } = await http.get(`employees/${searchText}`);
    return data;
  });
}

//  Rating All K12 and SR
function useRatingSheetAllQuery(obj) {
  return useQuery(
    [
      "ratingsheetall",
      {
        registration_no: obj.registration_no,
        school_level_category_id: obj.school_level_category_id,
        sem: obj.sem,
      },
    ],
    async (key, obj) => {
      const { data } = await http.get(
        `ratingsheet/all/${obj.registration_no}/${obj.school_level_category_id}/${obj.sem}`
      );
      return data;
    }
  );
}

//  Rating All K12 and SR
function useGradingReportQuery(obj) {
  return useQuery(
    [
      "gradingReport",
      {
        credentials: obj.credentials,
        regnos: obj.regnos,
        type: obj.type,
      },
    ],
    async (key, obj) => {
      const { data } = await http.get(
        `ratingsheet/gradingreport/${obj.credentials}/${obj.regnos}/${obj.type}`
      );
      return data;
    }
  );
}

function usePermanentRegistration(student_id) {
  return useQuery(["permamentRegistration", student_id], async (key, student_id) => {
    const { data } = await http.get(`permanent/registration/${student_id}`);
    return data;
  });
}

function usePermanentRecord(obj) {
  return useQuery(
    ["permamentRecord", { student_id: obj.student_id, permanentType: obj.permanentType }],
    async (key, obj) => {
      const { data } = await http.get(
        `permanent/${
          obj.permanentType === 2 ? "junior" : obj.permanentType === 3 ? "senior" : "elementary"
        }/${obj.student_id}`
      );
      return data;
    }
  );
}

export {
  useSchoolLevelQuery,
  useSYSubjects,
  useSubjectsK12,
  useSRSYSubjects,
  useSubjectsSR,
  useSubjectsSRCategory,
  useSYTeachers,
  useRegistration,
  useSchoolLevelListingQuery,
  useProcessSubjects,
  useTraits,
  useSYTraits,
  useSchoolLevelCategoryQuery,
  useStudentQuery,
  useSettingsQuery,
  useEmployeeQuery,
  useSYLockedQuery,
  useRatingSheetAllQuery,
  useGradingReportQuery,
  useProcessSubjectsNone,
  usePermanentRegistration,
  usePermanentRecord,
};
