import React from "react";
import { connect } from "react-redux";
import { setRequestError } from "redux/system/system.actions";
import { Typography } from "antd";

const { Title } = Typography;
const About = ({ setRequestError }) => {
  //   const handleClick = () => {
  //     setRequestError({
  //       errorMessage: "Something went wrong on your last operation :(",
  //       //errorSubMessage: error.message,
  //       errorSubMessage: "Sub error message",
  //     });
  //   };

  return (
    <>
      <Title level={3}>School Web Assistant (SWA) Versoin 1.0.0</Title>
      {/* <Button onClick={handleClick}>Trigger Error</Button> */}
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  setRequestError: (data) => dispatch(setRequestError(data)),
});

export default connect(null, mapDispatchToProps)(About);
