import React from "react";
import { Layout } from "antd";

const Footer = () => {
  const { Footer } = Layout;
  return (
    <Footer>
      School Web Assistant Versio v1.0. Created and Maintained by{" "}
      <a href="https://itmgent.net" rel="noopener noreferrer" target="_blank">
        {" "}
        ITMG Enterprises
      </a>
    </Footer>
  );
};

export default Footer;
