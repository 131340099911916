import React, { useState, useEffect } from "react";
import {
  Table,
  Row,
  Col,
  message,
  Select,
  Form,
  Typography,
  Button,
  Empty,
  Tag,
  Spin,
  Card,
  Space,
  Switch,
  Badge,
  Alert,
} from "antd";
import {
  ExclamationCircleOutlined,
  CheckCircleOutlined,
} from "@ant-design/icons";
import moment from "moment";
import update from "immutability-helper";
import pdfMakeReportsGradingK12 from "views/Reports/components/ReportsGradingK12";
import pdfMakeReportsGradingSR from "views/Reports/components/ReportsGradingSR";
import pdfMakeProgressK12 from "views/Reports/components/ProgressCardK12";
import pdfMakeProgressSR from "views/Reports/components/ProgressCardSR";
import getBase64ImageFromURL from "helpers/base64ImageFromURL";

// import { upperCase } from "helpers/textTransformer";

import {
  useSchoolLevelQuery,
  useProcessSubjectsNone,
  useGradingReportQuery,
  useSettingsQuery,
} from "views/SYSubjects/hooks/Index";

const { Option, OptGroup } = Select;
const { Text } = Typography;

// const { Title, Link } = Typography;
// const { confirm } = Modal;
// const { TabPane } = Tabs;

const SelectionForm = ({ selectedSyLevel, setSelectedSyLevel }) => {
  const { isLoading, data: levelListing } = useSchoolLevelQuery(3);
  // const { data } = useSchoolLevelQuery(3);
  const [form] = Form.useForm();

  const sySelections = generateSy();
  function generateSy() {
    let yearArray = [];
    let yearNow = Number(moment().format("YYYY"));
    for (let i = yearNow; i > 2000; i--) {
      yearArray.push(`${i}-${i + 1}`);
    }
    return yearArray;
  }
  const onSelectionChange = (value, option) => {
    setSelectedSyLevel({
      ...selectedSyLevel,
      sy: value,
      grading_period: null,
      sem: null,
    });
    form.setFieldsValue({ grading_period: null, sem: null });
  };

  const onSelectionChangeLevel = (value, option) => {
    setSelectedSyLevel({
      ...selectedSyLevel,
      level_code: value,
      grading_period: null,
      sem: null,
    });
    form.setFieldsValue({ grading_period: null, sem: null });
  };

  const onSelectionChangeGradingPeriod = (value, option) => {
    setSelectedSyLevel({ ...selectedSyLevel, grading_period: value });
  };
  const onSelectionChangeSem = (value, option) => {
    setSelectedSyLevel({ ...selectedSyLevel, sem: value });
  };

  return (
    <>
      <Form layout="inline" form={form}>
        <Form.Item label="Select School Year" name="sy">
          <Select
            placeholder="none"
            style={{ width: 120 }}
            onChange={(value, option) => {
              onSelectionChange(value, option);
            }}
            rules={[
              {
                required: true,
                message: "Please select School Year!",
              },
            ]}
          >
            {sySelections.map((item) => {
              return (
                <Option value={item} key={item}>
                  {item}
                </Option>
              );
            })}
          </Select>
        </Form.Item>

        <Form.Item label="Select Level" name="level">
          <Select
            placeholder="none"
            style={{ width: 160 }}
            loading={isLoading}
            onChange={(value, option) => {
              onSelectionChangeLevel(value, option);
            }}
            rules={[
              {
                required: true,
                message: "Please select Level!",
              },
            ]}
          >
            {isLoading
              ? null
              : levelListing.map((item, index) => {
                  return (
                    <OptGroup label={item.school_level_category} key={index}>
                      {item.school_level.map((element) => {
                        return (
                          <Option
                            value={element.level_code}
                            key={element.level_code}
                          >
                            {element.level}
                          </Option>
                        );
                      })}
                    </OptGroup>
                  );
                })}
          </Select>
        </Form.Item>
        <Form.Item
          label="Select Level"
          name="grading_period"
          initialValue={selectedSyLevel.grading_period}
        >
          <Select
            placeholder="none"
            style={{ width: 140 }}
            onChange={(value, option) => {
              onSelectionChangeGradingPeriod(value, option);
            }}
            rules={[
              {
                required: true,
                message: "Please select Level!",
              },
            ]}
          >
            <Option value="first_grading">First Grading </Option>
            <Option value="second_grading">Second Grading </Option>
            {selectedSyLevel.level_code < 14 && (
              <>
                <Option value="third_grading">Third Grading </Option>
                <Option value="fourth_grading">Fourth Grading </Option>
              </>
            )}
            );
          </Select>
        </Form.Item>

        {selectedSyLevel.level_code > 13 && (
          <Form.Item
            label="Select Level"
            name="sem"
            initialValue={selectedSyLevel.sem}
          >
            <Select
              placeholder="none"
              style={{ width: 160 }}
              onChange={(value, option) => {
                onSelectionChangeSem(value, option);
              }}
              rules={[
                {
                  required: true,
                  message: "Please select Level!",
                },
              ]}
            >
              <Option value="1">First Semester</Option>
              <Option value="2">Second Semester</Option>
              );
            </Select>
          </Form.Item>
        )}
      </Form>
    </>
  );
};

const RegisteredByTeacher = ({ selectedSyLevel, setParam, setGoPrint }) => {
  const { isLoading, data } = useProcessSubjectsNone(selectedSyLevel);
  const [registeredByTeacherData, setRegisteredByTeacherData] = useState([]);
  const [sySummary, setSySummary] = useState([]);
  const [sySummaryStatus, setSySummaryStatus] = useState(false);
  const [sRowKeys, setSRowKeys] = useState([]);
  const [sRows, setSRows] = useState([]);
  const [switchStatus, setSwitchStatus] = useState(false);

  //

  useEffect(() => {
    if (data) {
      setRegisteredByTeacherData(data.registeredByTeacher);
      setSySummary(data.sySummary.summary);
      setSySummaryStatus(data.sySummary.status === "passed" ? true : false);
      setSRowKeys(
        data.registeredByTeacher.map((i) => {
          return [];
        })
      );
      setSRows(
        data.registeredByTeacher.map((i) => {
          return [];
        })
      );
    }
  }, [data]);

  const columns = [
    {
      title: "Name",
      dataIndex: "fullname",
      key: "fullname",
    },
    // ,
    // {
    //   title: "",
    //   dataIndex: "subject_count",
    //   key: "subject_count",

    //   render: (text, record) => {
    //     return record.subject_count > 0 ? (
    //       <Tag color="blue">subjects ({text})</Tag>
    //     ) : (
    //       <Tag color="orange">none</Tag>
    //     );
    //   },
    // },
  ];
  const handlesSwitchChange = (checked) => {
    setSwitchStatus(checked);
  };

  const RegisteredTable = ({
    columns,
    dataSource,
    index,
    sRowKeys,
    setSRowKeys,
    switchStatus,
  }) => {
    const handlesOnChange = (selectedRowKeys, selectedRows) => {
      setSRowKeys(
        update(sRowKeys, {
          [index]: { $set: selectedRowKeys },
        })
      );
      setSRows(
        update(sRows, {
          [index]: { $set: selectedRows },
        })
      );
    };

    return (
      <Table
        disab
        rowKey="student_id"
        columns={columns}
        dataSource={dataSource}
        rowSelection={
          switchStatus
            ? {
                hideSelectAll: true,
                // onSelect: handlesOnSelection,
                onChange: (selectedRowKeys, selectedRows) => {
                  handlesOnChange(selectedRowKeys, selectedRows);
                  // setSRowKeys([...sRowKeys, [0], selectedRowKeys]);
                },
                selectedRowKeys: sRowKeys[index],
              }
            : false
        }
        showHeader={false}
        size="small"
        pagination={true}
      />
    );
  };

  const clearSelection = () => {
    setSRowKeys(
      data.registeredByTeacher.map((i) => {
        return [];
      })
    );
    setSRows(
      data.registeredByTeacher.map((i) => {
        return [];
      })
    );
  };

  return (
    <Spin spinning={isLoading} size="large" tip="Loading, please wait...">
      <Row gutter={10} style={{ paddingBottom: 10 }}>
        <Col span={12}>
          {sySummaryStatus ? (
            <Space>
              <Switch onChange={handlesSwitchChange} />
              {switchStatus ? (
                <Space>
                  <Text>By Selection: </Text>
                  <Button
                    // ----------------------------- by selection to k12 and senior

                    onClick={() => {
                      let toPrintRegsOnly = [];
                      sRows.forEach((i) => {
                        i.forEach((j) => {
                          toPrintRegsOnly.push({
                            registration_no: j.registration_no,
                          });
                        });
                      });
                      //   if (selectedSyLevel.level_code <= 13) {
                      if (!selectedSyLevel.grading_period) {
                        message.warning("Please select grading period...");
                      } else if (
                        selectedSyLevel.level_code > 13 &&
                        !selectedSyLevel.sem
                      ) {
                        message.warning("Please select semester...");
                      } else if (toPrintRegsOnly.length <= 0) {
                        message.warning("Please select students/s...");
                      } else {
                        setParam({
                          credentials: JSON.stringify(selectedSyLevel),
                          regnos: JSON.stringify(toPrintRegsOnly),
                          type: 0,
                        });
                        setGoPrint(true);
                      }
                    }}
                  >
                    Grading Report
                  </Button>
                  <Button
                    onClick={() => {
                      let toPrintRegsOnly = [];
                      sRows.forEach((i) => {
                        i.forEach((j) => {
                          toPrintRegsOnly.push({
                            registration_no: j.registration_no,
                          });
                        });
                      });

                      //   if (!selectedSyLevel.grading_period) {
                      //     message.warning("Please select grading period...");
                      //   } else if (selectedSyLevel.level_code > 13 && !selectedSyLevel.sem) {
                      //     message.warning("Please select semester...");
                      //   } else

                      if (toPrintRegsOnly.length <= 0) {
                        message.warning("Please select students/s...");
                      } else {
                        setParam({
                          credentials: JSON.stringify(selectedSyLevel),
                          regnos: JSON.stringify(toPrintRegsOnly),
                          type: 1,
                        });
                        setGoPrint(true);
                      }
                    }}
                  >
                    Progress Report
                  </Button>

                  <Button
                    onClick={() => {
                      clearSelection();
                    }}
                  >
                    Clear
                  </Button>
                </Space>
              ) : (
                <>
                  <Text>All: </Text>
                  <Button
                    onClick={() => {
                      //   let toPrintRegsOnly = [];
                      //   registeredByTeacherData.forEach((i) => {
                      //     i.students.forEach((j) => {
                      //       toPrintRegsOnly.push({ registration_no: j.registration_no });
                      //     });
                      //   });

                      //   if (selectedSyLevel.level_code <= 13) {
                      if (!selectedSyLevel.grading_period) {
                        message.warning("Please select grading period...");
                      } else {
                        setParam({
                          credentials: JSON.stringify(selectedSyLevel),
                          regnos: null,
                          type: 0,
                        });
                        setGoPrint(true);
                      }
                      //   } else if (selectedSyLevel.level_code > 13) {
                      //     if (!selectedSyLevel.grading_period && !selectedSyLevel.grading_period) {
                      //       message.warning("Please select grading period and sem are required...");
                      //     } else if (toPrintRegsOnly.length <= 0) {
                      //       message.warning("Please select students/s...");
                      //     } else {
                      //       setParam({
                      //         credentials: JSON.stringify(selectedSyLevel),
                      //         regnos: null,
                      //       });
                      //       setGoPrint(true);
                      //     }
                      //   }
                    }}
                  >
                    Grading Report
                  </Button>
                  <Button
                    onClick={() => {
                      //  batch to no need loop
                      //   let toPrint = [];
                      //   let toPrintRegsOnly = [];
                      //   registeredByTeacherData.forEach((i) => {
                      //     i.students.forEach((j) => {
                      //       toPrint.push(j);
                      //       toPrintRegsOnly.push({ registration_no: j.registration_no });
                      //     });
                      //   });

                      //   if (!selectedSyLevel.grading_period) {
                      //     message.warning("Please select grading period...");
                      //   } else {
                      setParam({
                        credentials: JSON.stringify(selectedSyLevel),
                        regnos: null,
                        type: 1,
                      });
                      setGoPrint(true);
                      //   }

                      //   console.log(toPrint);
                      //   console.log(toPrintRegsOnly);
                      //   console.log(selectedSyLevel);
                      // if (toRegister.length <= 0) {
                      //   setIsSaving(false);
                      //   message.warning("No registered student/s found.");
                      // } else {
                      //   saveConfirm(toRegister);
                      //   // mutate([...toRegister]);
                      // }
                    }}
                  >
                    Progress Report
                  </Button>
                </>
              )}
            </Space>
          ) : (
            <Alert
              message="Please check for incomplete SY Subjects or SY Traits"
              type="warning"
              showIcon
            />
          )}
        </Col>
        <Col span={12}>
          {sySummary.length > 0 ? (
            <div style={{ paddingTop: 4, paddingBottom: 4 }}>
              <Space>
                {sySummary.map((item, index) => {
                  return (
                    <Tag
                      color={item.status === "passed" ? "green" : "orange"}
                      icon={
                        item.status === "passed" ? (
                          <CheckCircleOutlined />
                        ) : (
                          <ExclamationCircleOutlined />
                        )
                      }
                      key={index}
                    >{`${item.title}-${item.message}`}</Tag>
                  );
                })}
              </Space>
            </div>
          ) : null}
        </Col>
      </Row>

      {registeredByTeacherData.length > 0 ? (
        registeredByTeacherData.map((item, index) => {
          return (
            <Card
              size="small"
              title={
                <Badge count={item.students.length} offset={[16, 6]}>
                  <b>Teacher - </b> {item.fullname}
                </Badge>
              }
              key={item.employee_id}
              style={{ marginBottom: 6 }}
            >
              <RegisteredTable
                columns={columns}
                dataSource={item.students}
                index={index}
                sRowKeys={sRowKeys}
                setSRowKeys={setSRowKeys}
                switchStatus={switchStatus}
              />
            </Card>
          );
        })
      ) : (
        <Empty
          description="No registered students found."
          image={Empty.PRESENTED_IMAGE_SIMPLE}
        />
      )}
    </Spin>
  );
};

const ReportCards = () => {
  const [selectedSyLevel, setSelectedSyLevel] = useState({
    sy: null,
    level_code: null,
    grading_period: null,
    sem: null,
  });

  const [param, setParam] = useState({
    credentials: null,
    regnos: null,
    type: 0,
  });
  const [goPrint, setGoPrint] = useState(false);
  useSettingsQuery();

  const { isLoading, data: gradingReport } = useGradingReportQuery(
    { ...param },
    {
      enabled: false,
    }
  );

  const convertImages = async (data, lvl_code) => {
    const _blank = await getBase64ImageFromURL(
      "https://itmg.sgp1.digitaloceanspaces.com/swa/_blank.jpg "
    );
    const newData = await Promise.all(
      data.map(async (element) => {
        return {
          ...element,
          img_path: element.img_path
            ? await getBase64ImageFromURL(
                element.img_path.replace(".jpg", "-card.jpg")
              )
            : _blank,
        };
      })
    );

    if (lvl_code < 14) {
      pdfMakeProgressK12(newData);
    } else {
      pdfMakeProgressSR(newData);
    }
  };

  useEffect(() => {
    if (gradingReport && gradingReport.length > 0 && goPrint) {
      if (param.type === 0) {
        console.log(selectedSyLevel);
        if (selectedSyLevel.level_code < 14) {
          pdfMakeReportsGradingK12(gradingReport);
        } else {
          pdfMakeReportsGradingSR(
            gradingReport,
            selectedSyLevel.grading_period
          );
        }
      } else {
        // if (selectedSyLevel.level_code < 14) {
        //   pdfMakeProgressK12(gradingReport);
        // } else {
        convertImages(gradingReport, selectedSyLevel.level_code);
        // pdfMakeProgressSR(gradingReport);
        // }
      }
      setGoPrint(false);
    }
  }, [goPrint, gradingReport, param, selectedSyLevel]);

  return (
    <>
      <Spin spinning={isLoading} size="large" tip="Processing, please wait...">
        <SelectionForm
          selectedSyLevel={selectedSyLevel}
          setSelectedSyLevel={setSelectedSyLevel}
        />
        {selectedSyLevel.sy && selectedSyLevel.level_code ? (
          <RegisteredByTeacher
            selectedSyLevel={selectedSyLevel}
            setParam={setParam}
            setGoPrint={setGoPrint}
          />
        ) : (
          <Empty />
        )}
      </Spin>
    </>
  );
};
export default ReportCards;
