import React, { useState, useEffect } from "react";
import {
  Table,
  Row,
  Col,
  Drawer,
  Select,
  Form,
  Typography,
  Empty,
  Tag,
} from "antd";

import moment from "moment";
import SYTeacher from "views/SYSubjects/components/SYTeacher";
import StudentGrades from "views/RatingSheet/StudentGrades";
import {
  useSchoolLevelQuery,
  useRegistration,
  useSettingsQuery,
} from "views/SYSubjects/hooks/Index";

const { Option, OptGroup } = Select;
const { Title } = Typography;

const SelectionForm = ({
  selectedSyLevel,
  setSelectedSyLevel,
  setHasSelection,
}) => {
  const { isLoading, data } = useSchoolLevelQuery(3);
  // const [showSem, setShowSem] = useState(false);
  const [showSyTeacher, setShowSyTeacher] = useState(false);

  const [form] = Form.useForm();
  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 8 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 16 },
    },
  };
  const sySelections = generateSy();
  function generateSy() {
    let yearArray = [];
    let yearNow = Number(moment().format("YYYY"));
    for (let i = yearNow; i > 2000; i--) {
      yearArray.push(`${i}-${i + 1}`);
    }
    return yearArray;
  }
  const onSelectionChange = (value, option, type) => {
    switch (type) {
      case "sy":
        setSelectedSyLevel({
          ...selectedSyLevel,
          sy: value,
          employee_id: null,
        });
        setHasSelection(false);
        break;
      case "level_code":
        setHasSelection(false);
        setShowSyTeacher(true);
        setSelectedSyLevel({
          ...selectedSyLevel,
          level_code: value,
          level: option.children,
          employee_id: null,
        });

        break;

      default:
        return;
    }
  };

  const onTeacherSelection = (record) => {
    const { employee_id } = record;
    setSelectedSyLevel({ ...selectedSyLevel, employee_id: employee_id });
  };

  return (
    <>
      <Form {...formItemLayout} form={form}>
        <Form.Item label="Select SY" name="sy">
          <Select
            placeholder="none"
            style={{ width: 200 }}
            onChange={(value, option) => {
              onSelectionChange(value, option, "sy");
            }}
            rules={[
              {
                required: true,
                message: "Please select School Year!",
              },
            ]}
          >
            {sySelections.map((item) => {
              return (
                <Option value={item} key={item}>
                  {item}
                </Option>
              );
            })}
          </Select>
        </Form.Item>
        <Form.Item label="Select Level" name="level_code">
          <Select
            placeholder="none"
            style={{ width: 200 }}
            loading={isLoading}
            onChange={(value, option) => {
              onSelectionChange(value, option, "level_code");
            }}
            rules={[
              {
                required: true,
                message: "Please select Level!",
              },
            ]}
          >
            {isLoading
              ? null
              : data.map((item, index) => {
                  return (
                    <OptGroup label={item.school_level_category} key={index}>
                      {item.school_level.map((element) => {
                        return (
                          <Option
                            value={element.level_code}
                            key={element.level_code}
                          >
                            {element.level}
                          </Option>
                        );
                      })}
                    </OptGroup>
                  );
                })}
          </Select>
        </Form.Item>
      </Form>

      {showSyTeacher ? (
        <div style={{ minHeight: 190, backgroundColor: "#FFFF" }}>
          <SYTeacher
            selectedSyLevel={selectedSyLevel}
            disabled={false}
            simple={true}
            onTeacherSelection={onTeacherSelection}
          />
        </div>
      ) : (
        <Empty
          image={Empty.PRESENTED_IMAGE_SIMPLE}
          description="Teacher list."
          style={{ marginTop: 60, marginBottom: 60 }}
        />
      )}
    </>
  );
};

const ManageGrades = () => {
  useSettingsQuery();
  const [registeredData, setRegisteredData] = useState([]);
  const [enableMainQuery, setEnableMainQuery] = useState(false);
  const [hasSelection, setHasSelection] = useState(false);
  const [selectedSyLevel, setSelectedSyLevel] = useState({
    sy: null,
    level_code: null,
    level: null,
    employee_id: null,
  });

  const [credentials, setCredentTials] = useState({
    registration_no: null,
    school_level_category_id: null,
    sem: 1,
  });

  const [drawerVisibility, setDrawerVisibility] = useState(false);
  const { isLoading, data: registrationData } = useRegistration(
    selectedSyLevel,
    {
      enabled: enableMainQuery,
    }
  );

  const columns = [
    {
      title: "Regs",
      dataIndex: "registration_no",
      key: "registration_no",
      width: 80,
    },
    {
      title: "ID",
      dataIndex: "student_id",
      key: "student_id",
    },
    {
      title: "Name",
      dataIndex: "fullname",
      key: "fullname",
    },
  ];

  useEffect(() => {
    if (
      selectedSyLevel.sy &&
      selectedSyLevel.level_code &&
      selectedSyLevel.employee_id
    ) {
      setEnableMainQuery(true);
      setHasSelection(true);
      if (registrationData) {
        setRegisteredData(registrationData.registered);
      }
    }
  }, [selectedSyLevel, registrationData]);

  const handlesRowClick = (e, record) => {
    // console.log({
    //   ...credentials,
    //   registration_no: record.registration_no,
    //   school_level_category_id:
    //     parseInt(selectedSyLevel.level_code) < 14 ? 1 : 2,
    // });

    setCredentTials({
      ...credentials,
      registration_no: record.registration_no,
      school_level_category_id:
        parseInt(selectedSyLevel.level_code) < 14 ? 1 : 2,
    });
    setDrawerVisibility(true);
  };

  return (
    <>
      <Row gutter={16}>
        <Col span={9}>
          <SelectionForm
            selectedSyLevel={selectedSyLevel}
            setSelectedSyLevel={setSelectedSyLevel}
            setHasSelection={setHasSelection}
          />
        </Col>
        <Col span={15}>
          {!hasSelection ? (
            <Empty
              description="Select Shool Year, Level and Teacher"
              style={{ marginTop: 40 }}
            />
          ) : (
            <>
              <Title level={4}>
                Registered Students{" "}
                <Tag color="blue">{registeredData.length}</Tag>
              </Title>
              <Table
                name="current"
                rowKey="student_id"
                loading={isLoading}
                columns={columns}
                dataSource={registeredData}
                size="small"
                pagination={false}
                style={{ cursor: "pointer" }}
                onRow={(record) => {
                  return {
                    onClick: (event) => {
                      handlesRowClick(event, record);
                    }, // click row
                  };
                }}
              />
            </>
          )}
        </Col>
      </Row>
      <Drawer
        width={1100}
        placement="right"
        closable={false}
        // onClose={() => setDrawerVisibility(false)}
        visible={drawerVisibility}
        destroyOnClose={true}
      >
        <StudentGrades
          credentials={{ ...credentials }}
          setDrawerVisibility={setDrawerVisibility}
        />
      </Drawer>
    </>
  );
};

export default ManageGrades;
