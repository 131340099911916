import { REQUEST_ERROR } from "./system.types";

export const setRequestError = ({ errorMessage, errorSubMessage }) => (
    dispatch
) => {
    if (errorSubMessage === "Request failed with status code 401") {
        localStorage.clear();
        window.location = `/`;
    } else {
        dispatch({
            type: REQUEST_ERROR,
            payload: { errorMessage, errorSubMessage },
        });
    }
};
