import React, { useRef } from "react";

import { useTable } from "react-table";
import { Input, Typography } from "antd";

import keys from "views/PermanentRecord/components/GradesComposer/keymap";
import {
  StylesFitToWindow,
  StylesFullWidth,
} from "views/PermanentRecord/components/GradesComposer/Styles";
import EditableTableSecondary from "views/PermanentRecord/components/GradesComposer/EditableTableSecondary";

const { Text } = Typography;

// Create an editable cell renderer
const EditableCell = ({
  value: initialValue,
  row,
  column,
  updateMyData, // This is a custom function that we supplied to our table instance
  suffix,
}) => {
  const { index, original } = row;
  const { id, columnIndex, type, isGrade, decimalPlaces } = column;
  const rowIndex = index;
  const inputRef = useRef();

  // We need to keep and update the state of the cell normally
  const [value, setValue] = React.useState(initialValue);

  const onChange = (e) => {
    let newValue = "";
    if (type === "input") {
      newValue = e.target.value;
    } else if (type === "number") {
      newValue = e.target.value.replace(/[^0-9.]/g, "").replace(/(\..*)\./g, "$1");
    }
    // console.log("change");
    setValue(newValue);
  };

  // We'll only update the external data when the input is blurred
  const onBlur = () => {
    if (value !== initialValue) {
      // console.log("blur");
      if (type === "number") {
        let verifiedValue = parseFloat(value);
        if (verifiedValue) {
          if (isGrade) {
            if (verifiedValue < 65 || verifiedValue > 100) {
              console.log("les or greater");
              verifiedValue = parseFloat(initialValue);
            }
          }
          setValue(verifiedValue.toFixed(3));
          updateMyData(index, id, verifiedValue.toFixed(decimalPlaces));
        } else {
          setValue("0.00");
          updateMyData(index, id, "0.00");
        }
      } else if (type === "input") {
        updateMyData(index, id, value);
      }
    }
  };

  const onKeyDown = (e) => {
    const splitName = e.target.name.split("-");
    let rowI = parseInt(splitName[0]);
    let colI = parseInt(splitName[1]);
    let elementID = "";

    switch (e.keyCode) {
      case keys.ENTER:
      case keys.RIGHT:
        e.preventDefault();
        elementID = `${rowI}-${colI + 1}-${suffix}`;
        break;
      case keys.LEFT:
        e.preventDefault();

        elementID = `${rowI}-${colI - 1}-${suffix}`;
        break;
      case keys.UP:
        e.preventDefault();
        elementID = `${rowI - 1}-${colI}-${suffix}`;
        break;
      case keys.DOWN:
        e.preventDefault();
        elementID = `${rowI + 1}-${colI}-${suffix}`;
        break;
      default:
        return null;
    }

    let targetElement = document.getElementById(elementID);
    if (targetElement) {
      targetElement.select();
    }
  };

  // If the initialValue is changed external, sync it up with our state
  React.useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  return type === "number" || type === "input" ? (
    <Input
      value={value}
      id={`${rowIndex}-${columnIndex}-${suffix}`}
      ref={inputRef}
      onChange={onChange}
      onBlur={onBlur}
      onKeyDown={onKeyDown}
      name={`${rowIndex}-${columnIndex}-${suffix}`}
      maxLength={type === "number" ? "7" : "15"}
      //   readOnly={original.with_sub === 1}
      className={original.with_sub === 1 ? "input-disabled" : ""}
    />
  ) : (
    <Text className={id === "sem" ? "sem" : ""}> {value}</Text>
  );
};

// Set our editable cell renderer as the default Cell renderer
const defaultColumn = {
  Cell: EditableCell,
};

// Be sure to pass our updateMyData and the skipPageReset option
function Table({ columns, data, updateMyData, enableSub, suffix, fullWidth, category }) {
  // For this example, we're using pagination to illustrate how to stop
  // the current page from resetting when our data changes
  // Otherwise, nothing is different here.
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    rows,
    visibleColumns,
  } = useTable({
    columns,
    data,
    defaultColumn,
    // use the skipPageReset option to disable page resetting temporarily
    // updateMyData isn't part of the API, but
    // anything we put into these options will
    // automatically be available on the instance.
    // That way we can call this function from our
    // cell renderer!
    updateMyData,
    enableSub,
    suffix,
    fullWidth,
    category,
  });
  // Render the UI for your table
  return (
    <div className={fullWidth ? "tableWrap" : ""}>
      <table {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => {
                return column.hideHeader === true ? null : (
                  <th {...column.getHeaderProps()}>{column.render("Header")}</th>
                );
              })}
            </tr>
          ))}
        </thead>

        <tbody {...getTableBodyProps()}>
          {rows.map((row, i) => {
            prepareRow(row);

            if (category === 2) {
              return (
                <React.Fragment key={i}>
                  <tr {...row.getRowProps()}>
                    {row.cells.map((cell) => {
                      return <td {...cell.getCellProps()}>{cell.render("Cell")}</td>;
                    })}
                  </tr>
                </React.Fragment>
              );
            } else {
              return (
                <React.Fragment key={i}>
                  <tr
                    {...row.getRowProps()}
                    className={enableSub && row.original.with_sub === 1 ? "secondary" : ""}
                  >
                    {row.cells.map((cell) => {
                      return <td {...cell.getCellProps()}>{cell.render("Cell")}</td>;
                    })}
                  </tr>

                  {enableSub && row.original.with_sub === 1 ? (
                    <tr>
                      <td colSpan={visibleColumns.length}>
                        <EditableTableSecondary
                          columns={columns}
                          rowData={{ ...row.original }}
                          updateMyData={updateMyData}
                          primaryIndex={i}
                        />
                      </td>
                    </tr>
                  ) : null}
                </React.Fragment>
              );
            }
          })}
        </tbody>
      </table>
    </div>
  );
}

function EditableTablePrimary({
  columns,
  rowData,
  updateMyData,
  enableSub,
  suffix,
  fullWidth,
  category,
}) {
  return fullWidth ? (
    <StylesFullWidth>
      <Table
        columns={columns}
        data={rowData}
        updateMyData={updateMyData}
        enableSub={enableSub}
        suffix={suffix}
        fullWidth={fullWidth}
      />
    </StylesFullWidth>
  ) : (
    <StylesFitToWindow>
      <Table
        columns={columns}
        data={rowData}
        updateMyData={updateMyData}
        enableSub={enableSub}
        suffix={suffix}
        category={category}
      />
    </StylesFitToWindow>
  );
}

export default EditableTablePrimary;
