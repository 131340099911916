import pdfMake from "pdfmake/build/pdfmake";
import vfsFonts from "pdfmake/build/vfs_fonts";
import { queryCache } from "react-query";

export default async (data) => {
  const { vfs } = vfsFonts.pdfMake;
  pdfMake.vfs = vfs;

  // playground requires you to assign document definition to a variable called dd
  var cellmargin = 1.2;
  var normalFontSize = 7.5;
  var tableLayout = {
    hLineWidth: function (i, node) {
      return i === 0 || i === node.table.body.length ? 0.1 : 0.1;
    },
    vLineWidth: function (i, node) {
      return i === 0 || i === node.table.widths.length ? 0.1 : 0.1;
    },
  };

  // const imgPath = (img) => {
  //   console.log(img);
  //   if (img) {
  //     return getBase64ImageFromURL(img);
  //   } else {
  //     return imgBlank;
  //   }
  // };

  const { director, principal_senior } = queryCache.getQueryData("settings");

  const documentDefinition = {
    pageMargins: [10, 10, 10, 6],
    pageOrientation: "landscape",
    pageSize: "LETTER",
    content: [
      data.map((d, index) => {
        return [
          [
            {
              table: {
                widths: [160, "*", 100],
                body: [
                  [
                    { border: [false, false, false, false], text: "" },
                    {
                      border: [false, false, false, false],
                      stack: [
                        {
                          text: "LITTLE SHEPHERD INTEGRATED MONTESSORI",
                          style: "header",
                          pageBreak: index !== 0 ? "before" : "",
                        },
                        {
                          text: "LITTLE SHEPHERD BUSINESS AND SCIENCE HIGH SCHOOL",
                          style: "header",
                        },
                        {
                          text: "Lumban, Laguna",
                          style: "subheader",
                        },
                        {
                          text: "PROGRESS REPORT CARD",
                          style: "title",
                        },
                      ],
                    },
                    {
                      border: [false, false, false, false],
                      margin: [12, 0, 0, 0],
                      image: d.img_path,
                      fit: [60, 60],
                    },
                  ],
                ],
              },
            },
            {
              width: "*",
              margin: [0, -2, 0, 0],
              columns: [
                {
                  width: "*",
                  columns: [
                    {
                      width: 40,
                      text: "TRACK: ",
                      style: "gradeNormal",
                    },
                    {
                      width: "*",
                      text: "ACADEMIC",
                      style: "labelBold",
                    },
                  ],
                },
                {
                  width: 148,
                  columns: [
                    {
                      width: 60,
                      text: "STRAND: ",
                      style: "gradeNormal",
                    },
                    {
                      width: "*",
                      text: "GENERAL ACADEMIC",
                      style: "labelBold",
                    },
                  ],
                },
              ],
            },
            {
              text: "____________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________",
              margin: [0, -6, 0, 2],
              fontSize: 7,
            },
            {
              width: "*",
              columns: [
                {
                  width: 160,
                  margin: [8, 0, 20, 0],
                  columns: [
                    {
                      width: 28,
                      text: "LRN.:",
                      style: "labelNormal",
                    },
                    {
                      width: "*",
                      text: d.lrn,
                      style: "gradeNormal",
                      fontSize: 8,
                      bold: true,
                    },
                  ],
                },
                {
                  width: "*",
                  columns: [
                    {
                      width: 28,
                      text: "Name:",
                      style: "labelNormal",
                    },
                    {
                      width: "*",
                      text: d.fullname.toUpperCase(),
                      style: "gradeNormal",
                      fontSize: 8,
                      bold: true,
                    },
                  ],
                },
                {
                  width: 160,
                  margin: [20, 0, 20, 0],
                  columns: [
                    {
                      width: 28,
                      text: "Level:",
                      style: "labelNormal",
                    },
                    {
                      width: "*",
                      text: d.level.toUpperCase(),
                      style: "gradeNormal",
                      fontSize: 8,
                      bold: true,
                    },
                  ],
                },
                {
                  width: 160,
                  margin: [20, 0, 20, 0],
                  columns: [
                    {
                      width: 60,
                      text: "School Year:",
                      style: "labelNormal",
                    },
                    {
                      width: "*",
                      text: d.sy,
                      style: "gradeNormal",
                      fontSize: 8,
                      bold: true,
                    },
                  ],
                },
              ],
            },
            {
              columns: [
                {
                  width: 450,
                  margin: [0, 10, 20, 2],
                  text: "REPORT ON LEARNING PROGRESS AND ACHIEVEMENT",
                  style: "title",
                },
                {
                  margin: [0, 10, 20, 2],
                  text: "REPORT ON LEARNER'S OBSERVED VALUES",
                  style: "title",
                },
              ],
            },
            {
              columns: [
                {
                  width: 450,
                  margin: [0, 0, 10, 0],
                  table: {
                    widths: ["*", 38, 38, 60, 50],
                    body: d.subjects,
                    // [
                    //   [
                    //     { text: "FIRST SEMESTER", style: "subheader" },
                    //     { text: "QUARTER", style: "subheader", colSpan: 2 },
                    //     {},
                    //     { border: [true, true, true, false], text: "SEMESTER", style: "subheader" },
                    //     { border: [true, true, true, false], text: "ACTION", style: "subheader" },
                    //   ],
                    //   [
                    //     { text: "CORE SUBJECTS", style: "subjectLabel" },
                    //     { text: "1", style: "subheader" },
                    //     { text: "2", style: "subheader" },
                    //     {
                    //       border: [true, false, true, true],
                    //       text: "FINAL GRADES",
                    //       style: "subheader",
                    //     },
                    //     { border: [true, false, true, true], text: "TAKEN", style: "subheader" },
                    //   ],
                    //   [
                    //     { text: "Oral communication", style: "listNormal", margin: [10, 0, 0, 0] },
                    //     { text: "81.325", style: "gradeNormal" },
                    //     { text: "91.887", style: "gradeNormal" },
                    //     { text: "86.606/(P)", style: "gradeNormal" },
                    //     { text: "Prom.", style: "gradeNormal" },
                    //   ],
                    //   [
                    //     {
                    //       text: "Komunikasyon at Pananaliksik Sa Wika at Kulturang Pilipino",
                    //       style: "listNormal",
                    //       margin: [10, 0, 0, 0],
                    //     },
                    //     { text: "81.325", style: "gradeNormal" },
                    //     { text: "91.887", style: "gradeNormal" },
                    //     { text: "86.606/(P)", style: "gradeNormal" },
                    //     { text: "Prom.", style: "gradeNormal" },
                    //   ],
                    //   [
                    //     { text: "Oral communication", style: "listNormal", margin: [10, 0, 0, 0] },
                    //     { text: "81.325", style: "gradeNormal" },
                    //     { text: "91.887", style: "gradeNormal" },
                    //     { text: "86.606/(P)", style: "gradeNormal" },
                    //     { text: "Prom.", style: "gradeNormal" },
                    //   ],
                    //   [
                    //     { text: "Oral communication", style: "listNormal", margin: [10, 0, 0, 0] },
                    //     { text: "81.325", style: "gradeNormal" },
                    //     { text: "91.887", style: "gradeNormal" },
                    //     { text: "86.606/(P)", style: "gradeNormal" },
                    //     { text: "Prom.", style: "gradeNormal" },
                    //   ],
                    //   [
                    //     { text: "Oral communication", style: "listNormal", margin: [10, 0, 0, 0] },
                    //     { text: "81.325", style: "gradeNormal" },
                    //     { text: "91.887", style: "gradeNormal" },
                    //     { text: "86.606/(P)", style: "gradeNormal" },
                    //     { text: "Prom.", style: "gradeNormal" },
                    //   ],
                    //   [
                    //     {
                    //       text: "APPLIED TRACK SUBJECTS",
                    //       style: "subheader",
                    //       colSpan: 5,
                    //       alignment: "left",
                    //     },
                    //     {},
                    //     {},
                    //     {},
                    //     {},
                    //   ],
                    //   [
                    //     { text: "Oral communication", style: "listNormal", margin: [10, 0, 0, 0] },
                    //     { text: "81.325", style: "gradeNormal" },
                    //     { text: "91.887", style: "gradeNormal" },
                    //     { text: "86.606/(P)", style: "gradeNormal" },
                    //     { text: "Prom.", style: "gradeNormal" },
                    //   ],
                    //   [
                    //     {
                    //       text: "SPECIALIZATION SUBJECTS",
                    //       style: "subheader",
                    //       colSpan: 5,
                    //       alignment: "left",
                    //     },
                    //     {},
                    //     {},
                    //     {},
                    //     {},
                    //   ],
                    //   [
                    //     {
                    //       text: "introduction to World religions and Belief Systems",
                    //       style: "listNormal",
                    //       margin: [10, 0, 0, 0],
                    //     },
                    //     { text: "81.325", style: "gradeNormal" },
                    //     { text: "91.887", style: "gradeNormal" },
                    //     { text: "86.606/(P)", style: "gradeNormal" },
                    //     { text: "Prom.", style: "gradeNormal" },
                    //   ],
                    //   [
                    //     { text: "General Average", style: "subheader", colSpan: 3 },
                    //     {},
                    //     {},
                    //     { text: "86.606/(P)", style: "gradeNormal" },
                    //     { text: "Prom.", style: "gradeNormal" },
                    //   ],

                    //   [{ border: [0, 0, 0, 0], text: "test", fontSize: 5, colSpan: 5 }],

                    //   [
                    //     { text: "FIRST SEMESTER", style: "subheader" },
                    //     { text: "QUARTER", style: "subheader", colSpan: 2 },
                    //     {},
                    //     { border: [true, true, true, false], text: "SEMESTER", style: "subheader" },
                    //     { border: [true, true, true, false], text: "ACTION", style: "subheader" },
                    //   ],
                    //   [
                    //     { text: "CORE SUBJECTS", style: "subjectLabel" },
                    //     { text: "1", style: "subheader" },
                    //     { text: "2", style: "subheader" },
                    //     {
                    //       border: [true, false, true, true],
                    //       text: "FINAL GRADES",
                    //       style: "subheader",
                    //     },
                    //     { border: [true, false, true, true], text: "TAKEN", style: "subheader" },
                    //   ],
                    //   [
                    //     { text: "Oral communication", style: "listNormal", margin: [10, 0, 0, 0] },
                    //     { text: "81.325", style: "gradeNormal" },
                    //     { text: "91.887", style: "gradeNormal" },
                    //     { text: "86.606/(P)", style: "gradeNormal" },
                    //     { text: "Prom.", style: "gradeNormal" },
                    //   ],
                    //   [
                    //     {
                    //       text: "Komunikasyon at Pananaliksik Sa Wika at Kulturang Pilipino",
                    //       style: "listNormal",
                    //       margin: [10, 0, 0, 0],
                    //     },
                    //     { text: "81.325", style: "gradeNormal" },
                    //     { text: "91.887", style: "gradeNormal" },
                    //     { text: "86.606/(P)", style: "gradeNormal" },
                    //     { text: "Prom.", style: "gradeNormal" },
                    //   ],
                    //   [
                    //     { text: "Oral communication", style: "listNormal", margin: [10, 0, 0, 0] },
                    //     { text: "81.325", style: "gradeNormal" },
                    //     { text: "91.887", style: "gradeNormal" },
                    //     { text: "86.606/(P)", style: "gradeNormal" },
                    //     { text: "Prom.", style: "gradeNormal" },
                    //   ],
                    //   [
                    //     { text: "Oral communication", style: "listNormal", margin: [10, 0, 0, 0] },
                    //     { text: "81.325", style: "gradeNormal" },
                    //     { text: "91.887", style: "gradeNormal" },
                    //     { text: "86.606/(P)", style: "gradeNormal" },
                    //     { text: "Prom.", style: "gradeNormal" },
                    //   ],
                    //   [
                    //     { text: "Oral communication", style: "listNormal", margin: [10, 0, 0, 0] },
                    //     { text: "81.325", style: "gradeNormal" },
                    //     { text: "91.887", style: "gradeNormal" },
                    //     { text: "86.606/(P)", style: "gradeNormal" },
                    //     { text: "Prom.", style: "gradeNormal" },
                    //   ],
                    //   [
                    //     {
                    //       text: "APPLIED TRACK SUBJECTS",
                    //       style: "subheader",
                    //       colSpan: 5,
                    //       alignment: "left",
                    //     },
                    //     {},
                    //     {},
                    //     {},
                    //     {},
                    //   ],
                    //   [
                    //     { text: "Oral communication", style: "listNormal", margin: [10, 0, 0, 0] },
                    //     { text: "81.325", style: "gradeNormal" },
                    //     { text: "91.887", style: "gradeNormal" },
                    //     { text: "86.606/(P)", style: "gradeNormal" },
                    //     { text: "Prom.", style: "gradeNormal" },
                    //   ],
                    //   [
                    //     {
                    //       text: "SPECIALIZATION SUBJECTS",
                    //       style: "subheader",
                    //       colSpan: 5,
                    //       alignment: "left",
                    //     },
                    //     {},
                    //     {},
                    //     {},
                    //     {},
                    //   ],
                    //   [
                    //     {
                    //       text: "introduction to World religions and Belief Systems",
                    //       style: "listNormal",
                    //       margin: [10, 0, 0, 0],
                    //     },
                    //     { text: "81.325", style: "gradeNormal" },
                    //     { text: "91.887", style: "gradeNormal" },
                    //     { text: "86.606/(P)", style: "gradeNormal" },
                    //     { text: "Prom.", style: "gradeNormal" },
                    //   ],
                    //   [
                    //     { text: "General Average", style: "subheader", colSpan: 3 },
                    //     {},
                    //     {},
                    //     { text: "86.606/(P)", style: "gradeNormal" },
                    //     { text: "Prom.", style: "gradeNormal" },
                    //   ],
                    // ],
                  },
                  layout: tableLayout,
                },
                [
                  {
                    table: {
                      widths: ["*", 15, 15, 15, 15],
                      body: [
                        [
                          {
                            text: "BEHAVIOR STATEMENTS",
                            style: "subheader",
                            margin: [0, 6, 0, 0],
                            rowSpan: 2,
                          },
                          { text: "QUARTER", style: "subheader", colSpan: 4 },
                          {},
                          {},
                          {},
                        ],
                        [
                          "",
                          { text: "1", style: "subheader" },
                          { text: "2", style: "subheader" },
                          { text: "3", style: "subheader" },
                          { text: "4", style: "subheader" },
                        ],
                        ...d.traits.map((trait) => {
                          return [
                            { text: trait.traits, style: "gradeNormal" },
                            { text: trait.first, style: "gradeNormal" },
                            { text: trait.second, style: "gradeNormal" },
                            { text: trait.third, style: "gradeNormal" },
                            { text: trait.fourth, style: "gradeNormal" },
                          ];
                        }),
                        // [
                        //   {
                        //     text: "Expresses and respects one's spiritual belief",
                        //     style: "gradeNormal",
                        //   },
                        //   { text: "A", style: "gradeNormal" },
                        //   { text: "B", style: "gradeNormal" },
                        //   { text: "C", style: "gradeNormal" },
                        //   { text: "D", style: "gradeNormal" },
                        // ],
                        // [
                        //   {
                        //     text:
                        //       "Shows adherence to ethical principles by upholding  the truth in all undertakings",
                        //     style: "gradeNormal",
                        //   },
                        //   { text: "A", style: "gradeNormal" },
                        //   { text: "B", style: "gradeNormal" },
                        //   { text: "C", style: "gradeNormal" },
                        //   { text: "D", style: "gradeNormal" },
                        // ],
                        // [
                        //   {
                        //     text:
                        //       "Sensitive to individual, social and cultural differences resists typographical people",
                        //     style: "gradeNormal",
                        //   },
                        //   { text: "A", style: "gradeNormal" },
                        //   { text: "B", style: "gradeNormal" },
                        //   { text: "C", style: "gradeNormal" },
                        //   { text: "D", style: "gradeNormal" },
                        // ],
                        // [
                        //   {
                        //     text: "Demonstrates contributions towards solidarity",
                        //     style: "gradeNormal",
                        //   },
                        //   { text: "A", style: "gradeNormal" },
                        //   { text: "B", style: "gradeNormal" },
                        //   { text: "C", style: "gradeNormal" },
                        //   { text: "D", style: "gradeNormal" },
                        // ],
                        // [
                        //   {
                        //     text:
                        //       "Cares for the environment and natures resources wisely, judiciously, and economically",
                        //     style: "gradeNormal",
                        //   },
                        //   { text: "A", style: "gradeNormal" },
                        //   { text: "B", style: "gradeNormal" },
                        //   { text: "C", style: "gradeNormal" },
                        //   { text: "D", style: "gradeNormal" },
                        // ],
                        // [
                        //   {
                        //     text:
                        //       "Demonstrates pride in being a Filipino and exercises the rights & responsibilities of a Filipino citizen",
                        //     style: "gradeNormal",
                        //   },
                        //   { text: "A", style: "gradeNormal" },
                        //   { text: "B", style: "gradeNormal" },
                        //   { text: "C", style: "gradeNormal" },
                        //   { text: "D", style: "gradeNormal" },
                        // ],
                        // [
                        //   {
                        //     text:
                        //       "Demonstrates appropriate behavior in carrying out activities in the school, community and country",
                        //     style: "gradeNormal",
                        //   },
                        //   { text: "A", style: "gradeNormal" },
                        //   { text: "B", style: "gradeNormal" },
                        //   { text: "C", style: "gradeNormal" },
                        //   { text: "D", style: "gradeNormal" },
                        // ],
                      ],
                    },
                    layout: tableLayout,
                  },
                  {
                    margin: [0, 6, 0, 0],
                    table: {
                      widths: ["*", 60, 75],
                      body: [
                        [
                          { text: "Legend", style: "listBold" },
                          { text: " ", style: "gradeNormal" },
                          { text: "Guidelines for Rating", style: "listBold" },
                        ],
                        [
                          { text: "Beginning (B)", style: "listBold" },
                          { text: "74% and Below", style: "gradeNormal" },
                          { text: "A - Very Good", style: "gradeNormal" },
                        ],
                        [
                          { text: "Developing (D)", style: "listBold" },
                          { text: "75-79%", style: "gradeNormal" },
                          { text: "B - good", style: "gradeNormal" },
                        ],
                        [
                          {
                            text: "Approaching Proficiency (AP)",
                            style: "listBold",
                          },
                          { text: "80-84%", style: "gradeNormal" },
                          { text: "C - Fair", style: "gradeNormal" },
                        ],
                        [
                          { text: "Proficient (P)", style: "listBold" },
                          { text: "85-89%", style: "gradeNormal" },
                          { text: "D - Poor", style: "gradeNormal" },
                        ],
                        [
                          { text: "Advanced (A)", style: "listBold" },
                          { text: "90% and Above", style: "gradeNormal" },
                          { text: " ", style: "gradeNormal" },
                        ],
                      ],
                    },
                    layout: tableLayout,
                  },
                  {
                    text: "ATTENDANCE",
                    style: "subheader",
                    margin: [0, 6, 0, 2],
                  },
                  {
                    table: {
                      widths: ["*", 12, 12, 12, 12, 12, 13, 12, 12, 12, 13, 12, 13, 19],
                      body: [
                        [
                          { text: "", style: "subjectLabelTiny" },
                          { text: "AUG", style: "subjectLabelTiny" },
                          { text: "SEP", style: "subjectLabelTiny" },
                          { text: "OCT", style: "subjectLabelTiny" },
                          { text: "NOV", style: "subjectLabelTiny" },
                          { text: "DEC", style: "subjectLabelTiny" },
                          { text: "JAN", style: "subjectLabelTiny" },
                          { text: "FEB", style: "subjectLabelTiny" },
                          { text: "MAR", style: "subjectLabelTiny" },
                          { text: "APR", style: "subjectLabelTiny" },
                          { text: "MAY", style: "subjectLabelTiny" },
                          { text: "JUN", style: "subjectLabelTiny" },
                          { text: "JUL", style: "subjectLabelTiny" },
                          { text: "TOTAL", style: "subjectLabelTiny" },
                        ],
                        ...d.attendance.map((att) => {
                          return [
                            { text: att.attendance, style: "subjectLabelSM" },
                            {
                              text: Number(att.aug).toFixed(0),
                              style: "gradeNormalSm",
                            },
                            {
                              text: Number(att.sep).toFixed(0),
                              style: "gradeNormalSm",
                            },
                            {
                              text: Number(att.oct).toFixed(0),
                              style: "gradeNormalSm",
                            },
                            {
                              text: Number(att.nov).toFixed(0),
                              style: "gradeNormalSm",
                            },
                            {
                              text: Number(att.dec).toFixed(0),
                              style: "gradeNormalSm",
                            },
                            {
                              text: Number(att.jan).toFixed(0),
                              style: "gradeNormalSm",
                            },
                            {
                              text: Number(att.feb).toFixed(0),
                              style: "gradeNormalSm",
                            },
                            {
                              text: Number(att.mar).toFixed(0),
                              style: "gradeNormalSm",
                            },
                            {
                              text: Number(att.apr).toFixed(0),
                              style: "gradeNormalSm",
                            },
                            {
                              text: Number(att.may).toFixed(0),
                              style: "gradeNormalSm",
                            },
                            {
                              text: Number(att.jun).toFixed(0),
                              style: "gradeNormalSm",
                            },
                            {
                              text: Number(att.jul).toFixed(0),
                              style: "gradeNormalSm",
                            },
                            {
                              text: Number(att.total_attendance).toFixed(0),
                              style: "gradeNormalSm",
                            },
                          ];
                        }),
                        // [
                        //   { text: "No. of School Days", style: "subjectLabelSM" },
                        //   { text: "2", style: "gradeNormalSm" },
                        //   { text: "20", style: "gradeNormalSm" },
                        //   { text: "20", style: "gradeNormalSm" },
                        //   { text: "20", style: "gradeNormalSm" },
                        //   { text: "20", style: "gradeNormalSm" },
                        //   { text: "20", style: "gradeNormalSm" },
                        //   { text: "20", style: "gradeNormalSm" },
                        //   { text: "20", style: "gradeNormalSm" },
                        //   { text: "20", style: "gradeNormalSm" },
                        //   { text: "20", style: "gradeNormalSm" },
                        //   { text: "20", style: "gradeNormalSm" },
                        //   { text: "20", style: "gradeNormalSm" },
                        // ],
                        // [
                        //   { text: "No. of School Days Present", style: "subjectLabelSM" },
                        //   { text: "2", style: "gradeNormalSm" },
                        //   { text: "20", style: "gradeNormalSm" },
                        //   { text: "20", style: "gradeNormalSm" },
                        //   { text: "20", style: "gradeNormalSm" },
                        //   { text: "20", style: "gradeNormalSm" },
                        //   { text: "20", style: "gradeNormalSm" },
                        //   { text: "20", style: "gradeNormalSm" },
                        //   { text: "20", style: "gradeNormalSm" },
                        //   { text: "20", style: "gradeNormalSm" },
                        //   { text: "20", style: "gradeNormalSm" },
                        //   { text: "20", style: "gradeNormalSm" },
                        //   { text: "20", style: "gradeNormalSm" },
                        // ],
                        // [
                        //   { text: "No. of Times Tardy", style: "subjectLabelSM" },
                        //   { text: "2", style: "gradeNormalSm" },
                        //   { text: "20", style: "gradeNormalSm" },
                        //   { text: "20", style: "gradeNormalSm" },
                        //   { text: "20", style: "gradeNormalSm" },
                        //   { text: "20", style: "gradeNormalSm" },
                        //   { text: "20", style: "gradeNormalSm" },
                        //   { text: "20", style: "gradeNormalSm" },
                        //   { text: "20", style: "gradeNormalSm" },
                        //   { text: "20", style: "gradeNormalSm" },
                        //   { text: "20", style: "gradeNormalSm" },
                        //   { text: "20", style: "gradeNormalSm" },
                        //   { text: "20", style: "gradeNormalSm" },
                        // ],
                      ],
                    },
                    layout: tableLayout,
                  },
                  {
                    margin: [0, 5, 0, 0],
                    table: {
                      widths: [120, "*"],
                      body: [
                        [
                          {
                            border: [false, false, false, false],
                            text: "Eligible for admission to:",
                            style: "subjectLabel",
                          },
                          {
                            border: [false, false, false, true],
                            text: d.classified_as,
                            style: "gradeNormal",
                          },
                        ],
                        [
                          {
                            border: [false, false, false, false],
                            text: "Retained In: ",
                            style: "subjectLabel",
                          },
                          {
                            border: [false, false, false, true],
                            text: d.retained_in,
                            style: "gradeNormal",
                          },
                        ],
                      ],
                    },
                    layout: tableLayout,
                  },
                ],
              ],
            },
            {
              margin: [0, 20, 0, 0],
              width: "*",
              columns: [
                [
                  {
                    text: "_________________________________________________________",
                    fontSize: 8,
                    bold: false,
                    margin: [20, 0, 20, 0],
                  },
                  {
                    text: director,
                    fontSize: 8,
                    bold: true,
                    margin: [20, 0, 20, 0],
                    alignment: "center",
                  },
                  { text: "Director", style: "labelBoldItalic", fontSize: 7 },
                ],
                [
                  {
                    text: "_________________________________________________________",
                    fontSize: 8,
                    style: "title",
                    margin: [0, 0, 20, 0],
                  },
                  {
                    text: principal_senior,
                    fontSize: 8,
                    bold: true,
                    margin: [20, 0, 20, 0],
                    alignment: "center",
                  },
                  { text: "Principal", style: "labelBoldItalic", fontSize: 7 },
                ],
                [
                  {
                    text: "_________________________________________________________",
                    fontSize: 8,
                    style: "title",
                    margin: [0, 0, 0, 0],
                  },
                  {
                    text: d.employee.toUpperCase(),
                    fontSize: 8,
                    bold: true,
                    margin: [20, 0, 20, 0],
                    alignment: "center",
                  },
                  { text: "Teacher", style: "labelBoldItalic", fontSize: 7 },
                ],
              ],
            },
          ],
        ];
      }),
    ],
    styles: {
      header: {
        fontSize: 10,
        bold: true,
        alignment: "center",
        lineHeight: 1.1,
      },
      subheader: {
        fontSize: normalFontSize,
        bold: true,
        alignment: "center",
      },
      title: {
        fontSize: 10,
        bold: true,
        alignment: "center",
        margin: 10,
      },
      labelBold: {
        fontSize: 8.5,
        bold: true,
      },
      labelNormal: {
        fontSize: 8.5,
      },
      labelBoldItalic: {
        fontSize: 8.5,
        bold: true,
        italics: true,
        alignment: "center",
        margin: [0, 0, 0, 0],
      },
      subjectLabel: {
        fontSize: normalFontSize,
        bold: true,
        margin: cellmargin,
      },
      gradeBold: {
        fontSize: normalFontSize,
        bold: true,
        margin: cellmargin,
        alignment: "center",
      },
      gradeNormal: {
        fontSize: normalFontSize,
        margin: cellmargin,
        alignment: "center",
      },
      table: {
        margin: [0, 10, 0, 10],
      },
      averageFont: {
        fontSize: 11,
        bold: true,
        margin: cellmargin,
        alignment: "center",
      },
      listBold: {
        fontSize: 8,
        bold: true,
      },
      listBoldIndent: {
        fontSize: 8,
        bold: true,
        margin: [5, 0, 0, 0],
      },
      listNormal: {
        fontSize: 8,
      },
      subjectLabelSM: {
        fontSize: 7,
        margin: 1,
      },
      subjectLabelTiny: {
        fontSize: 6,
        alignment: "center",
      },
      gradeNormalSm: {
        fontSize: 7,
        alignment: "center",
      },
    },
  };

  try {
    pdfMake.createPdf(documentDefinition).open();
  } catch (error) {}

  //   pdfMake.createPdf(documentDefinition).print({}, window);
};
