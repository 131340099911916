import React from "react";
import logo from "assets/images/logo.png";
const Dashboard = () => {
  return (
    <div
      style={{
        height: "100vh",
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        padding: 30,
        paddingRight: 70,
      }}
    >
      <img src={logo} width={500} height={250} style={{ marginBottom: 30 }} alt="SWA Logo" />
    </div>
  );
};

export default Dashboard;
