import React, { useContext, useState, useEffect, useRef } from "react";
import { Table, Input, Button, Popconfirm, Form, InputNumber, Space, message } from "antd";
import { useSubjectsK12 } from "views/SYSubjects/hooks/Index";
import { LockOutlined, DeleteOutlined, PlusOutlined, SaveOutlined } from "@ant-design/icons";

import { generateString } from "helpers/randomStringGenerator";

import { useMutation } from "react-query";
import http from "services/httpService.js";

const EditableContext = React.createContext();

const EditableRow = ({ index, ...props }) => {
  const [form] = Form.useForm();
  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  );
};

const EditableCell = ({
  title,
  editable,
  children,
  dataIndex,
  record,
  handleSave,
  dataType,
  ...restProps
}) => {
  const [editing, setEditing] = useState(false);
  const inputRef = useRef();
  const form = useContext(EditableContext);
  useEffect(() => {
    if (editing) {
      //   inputRef.current.focus();
      inputRef.current.select();
    }
  }, [editing]);

  const toggleEdit = () => {
    setEditing(!editing);
    form.setFieldsValue({
      [dataIndex]: record[dataIndex],
    });
  };

  const save = async (e) => {
    try {
      const values = await form.validateFields();
      toggleEdit();
      handleSave({ ...record, ...values });
    } catch (errInfo) {
      console.log("Save failed:", errInfo);
    }
  };

  let childNode = children;
  if (editable) {
    childNode = editing ? (
      <Form.Item
        className="ant-form-item-custom"
        style={{
          margin: 0,
        }}
        name={dataIndex}
        initialValue={record[dataIndex]}
      >
        {dataType === "number" ? (
          <InputNumber
            ref={inputRef}
            onPressEnter={save}
            onBlur={save}
            min={0.0}
            max={100.0}
            step={0.01}
          />
        ) : (
          <Input ref={inputRef} onPressEnter={save} onBlur={save} />
        )}
      </Form.Item>
    ) : (
      <div
        className="editable-cell-value-wrap"
        style={{
          paddingRight: 24,
        }}
        onClick={toggleEdit}
      >
        {children}
      </div>
    );
  }

  return <td {...restProps}>{childNode}</td>;
};

const SubjectsK12 = () => {
  const { isLoading, data: subjectk12 } = useSubjectsK12();
  const [tablePagination, setTablePagination] = useState({
    showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} Subjects`,
    position: ["topRight", "bottomRight"],
    showSizeChanger: true,
    pageSize: 10,
  });

  const [mutate] = useMutation(
    async (values) => {
      await http.put(`subjectk12/update`, {
        values: values,
      });
    },
    {
      onSuccess: (data, variables) => {
        message.success("Record saved.");
      },
      onError: () => {
        // setDrawerVisibility(false);
        message.warning("Unable to save, Something went wrong :/");
      },
    }
  );

  const [mutateDelete] = useMutation(
    async (subject_code) => {
      await http.delete(`subjectk12/delete/${subject_code}`);
    },
    {
      onSuccess: (data, variables) => {
        message.success("Record deleted.");
      },
      onError: () => {
        // setDrawerVisibility(false);
        message.warning("Unable to save, Something went wrong :/");
      },
    }
  );

  const columns = [
    {
      title: () => (
        <>
          <Space>
            <span>
              <b>Subject</b>
            </span>
          </Space>
        </>
      ),
      dataIndex: "subject",
      width: "60%",
      editable: true,
      dataType: "string",
    },
    {
      title: "Units",
      dataIndex: "units",
      editable: true,
      dataType: "number",
    },

    {
      title: "",
      dataIndex: "operation",
      width: 65,
      render: (text, record) => {
        if (data.length <= 0) {
          return null;
        } else {
          if (record.locked === 1) {
            return <Button type="link" icon={<LockOutlined style={{ color: "#ffc53d" }} />} />;
          } else {
            return (
              <Popconfirm
                title="Sure to delete?"
                onConfirm={() => handleDelete(record.subject_code)}
              >
                <Button type="link" icon={<DeleteOutlined />} />
              </Popconfirm>
            );
          }
        }
      },
    },
  ];
  const [data, setData] = useState([]);
  useEffect(() => {
    setData(subjectk12);
  }, [subjectk12]);

  const handleDelete = (subject_code) => {
    mutateDelete(subject_code);
    setData(data.filter((item) => item.subject_code !== subject_code));
  };

  const handleAdd = () => {
    setTablePagination({
      ...tablePagination,
      current: Math.ceil(data.length / tablePagination.pageSize),
    });
    let newCode = generateString(7);
    const newData = {
      subject_code: newCode,
      subject: `{  Type subject name here...  }`,
      units: "0.00",
      locked: 0,
    };
    setData([...data, newData]);
  };

  const handleSave = (row) => {
    const newData = [...data];
    const index = newData.findIndex((item) => row.subject_code === item.subject_code);
    const item = newData[index];
    newData.splice(index, 1, { ...item, ...row });
    setData(newData);
  };

  const components = {
    body: {
      row: EditableRow,
      cell: EditableCell,
    },
  };
  const columnss = columns.map((col) => {
    if (!col.editable) {
      return col;
    }

    return {
      ...col,
      onCell: (record) => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
        dataType: col.dataType,
        handleSave: handleSave,
      }),
    };
  });
  const handleTableChange = async (pagination, filters, sorter) => {
    console.log(pagination.pageSize);
    setTablePagination({
      ...tablePagination,
      current: pagination.current,
      pageSize: pagination.pageSize,
    });
  };

  return (
    <div>
      <Space>
        <Button onClick={() => handleAdd()} type="primary" icon={<PlusOutlined />}>
          Add Subject{" "}
        </Button>
        <Button
          onClick={() => {
            mutate([...data]);
          }}
          type="primary"
          icon={<SaveOutlined />}
        >
          Save
        </Button>
      </Space>

      <Table
        loading={isLoading}
        components={components}
        rowClassName={() => "editable-row"}
        dataSource={data}
        columns={columnss}
        size="small"
        rowKey="subject_code"
        onChange={handleTableChange}
        pagination={tablePagination}
        total={100}
      />
    </div>
  );
};

export default SubjectsK12;
