import React, { useState, useCallback } from "react";
import {
  Table,
  Button,
  Dropdown,
  Tag,
  Space,
  Typography,
  Form,
  Select,
  Drawer,
  message,
  Empty,
  Input,
  Tooltip,
  Modal,
} from "antd";
import { useDrag, useDrop } from "react-dnd";
import update from "immutability-helper";
import { useMutation, queryCache } from "react-query";
import http from "services/httpService.js";
import moment from "moment";
import { generateString } from "helpers/randomStringGenerator";
import { titleCase } from "helpers/textTransformer";
import {
  MoreOutlined,
  DeleteOutlined,
  PlusOutlined,
  SaveOutlined,
  CloseOutlined,
  CopyOutlined,
  UndoOutlined,
  FileTextOutlined,
} from "@ant-design/icons";
import { useEffect } from "react";

import SYMenu from "views/SYSubjects/components/SYMenu";
import {
  customRegsK12,
  customSubjectsK12,
} from "views/PermanentRecord/components/GradesComposer/CustomData/Index";

import DragAndDrop from "views/PermanentRecord/components/SubjectComposer/DragAndDrop";

import { useSYSubjects, useSubjectsK12 } from "views/SYSubjects/hooks/Index";
const { confirm } = Modal;
const type = "DragableBodyRow";
const { Option } = Select;
const { Text } = Typography;

// for extraction
const DragableBodyRow = ({
  index,
  moveRow,
  record,
  className,
  style,
  ...restProps
}) => {
  const ref = React.useRef();
  const [{ isOver, dropClassName }, drop] = useDrop({
    accept: type,
    collect: (monitor) => {
      const { index: dragIndex } = monitor.getItem() || {};
      if (dragIndex === index) {
        return {};
      }
      return {
        isOver: monitor.isOver(),
        dropClassName:
          dragIndex < index ? " drop-over-downward" : " drop-over-upward",
      };
    },

    drop: (item) => {
      moveRow(item.index, index);
    },
  });
  const [, drag] = useDrag({
    item: { type, index },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  if (record && !record.isSub) {
    drop(drag(ref));
  }

  return (
    <tr
      ref={ref}
      className={`${className}${isOver ? dropClassName : ""}`}
      style={{ cursor: record && !record.isSub ? "move" : "", ...style }}
      {...restProps}
    />
  );
};
// for extraction
const SelectionForm = ({
  form,
  selectedSyLevel,
  setSelectedSyLevel,
  levelDetails,
  lvlDetails,
  setLvlDetails,
  setLevelDetails,
  handlesAddPrimarySubject,
  regs,
  setRegs,
}) => {
  const [newSubjectform] = Form.useForm();
  const sySelections = generateSy();

  const [mutate] = useMutation(
    async (values) => {
      await http.post(`subjectk12/store`, {
        values: values,
      });
    },
    {
      onSuccess: (data, variables) => {
        message.success("Subject saved.");
        queryCache.invalidateQueries("subjectk12");
      },
      onError: () => {
        message.warning("Unable to save, Something went wrong :/");
      },
    }
  );
  function generateSy() {
    let yearArray = [];
    let yearNow = Number(moment().format("YYYY")) - 1;
    for (let i = yearNow; i > 1994; i--) {
      yearArray.push(`${i}-${i + 1}`);
    }
    return yearArray;
  }

  useEffect(() => {
    form.setFieldsValue({
      level_code: levelDetails.level,
      sy: null,
      is_transferee: "0",
    });
  }, [levelDetails, form]);

  const onSelectionChange = (value, option, type) => {
    switch (type) {
      case "sy":
        setLvlDetails({
          ...lvlDetails,
          sy: value,
          level_code: levelDetails.level_code,
          level: levelDetails.level,
        });
        setRegs({ ...regs, sy: value });
        break;
      case "level_code":
        break;
      case "type":
        setRegs({ ...regs, is_transferee: Number(value) });

        break;
      default:
        return;
    }
  };

  const handlesCopySySubjects = () => {
    if (form.getFieldValue("sy")) {
      setSelectedSyLevel({
        ...selectedSyLevel,
        level_code: lvlDetails.level_code,
        sy: lvlDetails.sy,
        level: lvlDetails.level,
      });
    } else {
      message.warning("Please select School Year.");
    }
  };

  const handlesClearSubjects = () => {
    setSelectedSyLevel({
      ...selectedSyLevel,
      level_code: null,
      sy: null,
      level: null,
    });
  };

  const handlesAppendSubject = () => {
    let newSubjectID = Number(
      generateString(5, null, "6339854632145214985419271323541123456789")
    );
    let subjectName = newSubjectform.getFieldValue("newsubject");
    newSubjectform.setFieldsValue({ newsubject: null });

    mutate({
      subject_code: newSubjectID,
      subject: titleCase(subjectName),
      units: 0,
      locked: 1,
    });
    handlesAddPrimarySubject({
      subject_code: newSubjectID,
      subject: titleCase(subjectName),
      units: 0,
      locked: 1,
    });
  };

  const handlesAddNewSubject = () => {
    confirm({
      title: "Create New Subject.",
      icon: <FileTextOutlined />,
      content: (
        <>
          <p>Newly created subject/s will be added to global subject list.</p>
          <Form layout="vertical" form={newSubjectform}>
            <Form.Item label="Subject Name" name="newsubject">
              <Input />
            </Form.Item>
          </Form>
        </>
      ),
      onOk() {
        if (
          !newSubjectform.getFieldValue("newsubject") ||
          newSubjectform.getFieldValue("newsubject") === ""
        ) {
          message.error("Subject name cannot be blank.");
          return true;
        } else {
          handlesAppendSubject();
        }
      },
      onCancel() {
        newSubjectform.setFieldsValue({ newsubject: null });
      },
    });
  };

  return (
    <>
      <Form layout="inline" form={form}>
        <Form.Item label="Select School Year" name="sy">
          <Select
            style={{ width: 120 }}
            onChange={(value, option) => {
              onSelectionChange(value, option, "sy");
            }}
            rules={[
              {
                required: true,
                message: "Please select School Year!",
              },
            ]}
          >
            {sySelections.map((item) => {
              return (
                <Option value={item} key={item}>
                  {item}
                </Option>
              );
            })}
          </Select>
        </Form.Item>
        <Form.Item label="Select Level" name="level_code">
          <Input readOnly />
        </Form.Item>
        <Form.Item label="Type" name="is_transferee" initialValue="0">
          <Select
            style={{ width: 120 }}
            onChange={(value, option) => {
              onSelectionChange(value, option, "type");
            }}
            rules={[
              {
                required: true,
                message: "Please select type!",
              },
            ]}
          >
            <Option value="0">Regular</Option>
            <Option value="1">Transfree</Option>
          </Select>
        </Form.Item>
      </Form>
      <div>
        <Tooltip title="Load from SY Subjects">
          <Button
            type="dashed"
            icon={<CopyOutlined />}
            style={{ marginRight: 8 }}
            onClick={handlesCopySySubjects}
          >
            Load from Sy Subjects
          </Button>
        </Tooltip>
        <Tooltip title="Add New Subject">
          <Button
            type="dashed"
            icon={<PlusOutlined />}
            style={{ marginRight: 8 }}
            onClick={handlesAddNewSubject}
          >
            Add New Subject
          </Button>
        </Tooltip>

        <Tooltip title="Clear Subjects">
          <Button
            type="dashed"
            icon={<UndoOutlined />}
            onClick={handlesClearSubjects}
          >
            Clear Subjects
          </Button>
        </Tooltip>
      </div>
    </>
  );
};
// for extraction
const SubjectK12Selection = ({
  modalVisibility,
  setModalVisibility,
  subjectSYCodes,
  handlesAddPrimarySubject,
  isPrimary,
  handlesAppendChildren,
  primarySubjectCredentials,
}) => {
  const columns = [
    {
      title: "Subject Code",
      dataIndex: "subject_code",
      key: "subject_code",
      render: (text, record) => (
        <span
          className={
            subjectSYCodes.includes(record.subject_code) ? "disabled-row" : ""
          }
        >
          {record.subject_code}
        </span>
      ),
    },
    {
      title: "Subject",
      dataIndex: "subject",
      key: "subject",
      render: (text, record) => (
        <span
          className={
            subjectSYCodes.includes(record.subject_code) ? "disabled-row" : ""
          }
        >
          {record.subject}
        </span>
      ),
    },
    {
      title: "Units",
      dataIndex: "units",
      key: "units",
      render: (text, record) => (
        <span
          className={
            subjectSYCodes.includes(record.subject_code) ? "disabled-row" : ""
          }
        >
          {record.units}
        </span>
      ),
    },
  ];
  const { data: subjectk12 } = useSubjectsK12();
  const [tableData, setTableData] = useState([]);
  useEffect(() => {
    setTableData(subjectk12);
  }, [subjectk12, subjectSYCodes]);

  const handlesRowClick = (event, record) => {
    if (subjectSYCodes.includes(record.subject_code)) {
      message.warning("Already in the list. ");
    } else {
      if (isPrimary) {
        handlesAddPrimarySubject(record);
      } else {
        handlesAppendChildren({
          record: record,
          primaryIndex: primarySubjectCredentials.primaryIndex,
          subject_code_primary: primarySubjectCredentials.subject_code_primary,
        });
      }
    }
  };
  return (
    <Drawer
      title={
        isPrimary
          ? "Subject k12 (Add Primary Subjects)"
          : "Subject k12 (Add Secondary Subjects)"
      }
      width={520}
      placement="right"
      closable={true}
      onClose={() => setModalVisibility(false)}
      visible={modalVisibility}
      footer={
        <div
          style={{
            textAlign: "right",
          }}
        >
          <Button onClick={() => setModalVisibility(false)} type="primary">
            Close
          </Button>
        </div>
      }
    >
      <Table
        columns={columns}
        dataSource={tableData}
        rowKey="subject_code"
        pagination={false}
        size="small"
        onRow={(record, rowIndex) => {
          return {
            onClick: (event) => {
              handlesRowClick(event, record);
            }, // click row
          };
        }}
      />
    </Drawer>
  );
};

const ComposerK12 = ({
  levelDetails,
  setSubjectComposerDrawerK12,
  setLevelDetails,
  regsDetails,
  customGradesData,
  setCustomGradesData,
  setGradesComposerDrawer,
}) => {
  const [selectionForm] = Form.useForm();
  const [selectedSyLevel, setSelectedSyLevel] = useState({
    sy: null,
    level_code: null,
    level: null,
  });
  const [modalVisibility, setModalVisibility] = useState(false);
  const [hasSelection, setHasSelection] = useState(true);
  const [subjectSYCodes, setSubjectSYCodes] = useState([]);
  const [enableMainQuery, setEnableMainQuery] = useState(false);
  const [data, setData] = useState([]);
  const [lvlDetails, setLvlDetails] = useState({});
  const [regs, setRegs] = useState({});

  const { isLoading, data: sySubjectsData } = useSYSubjects(selectedSyLevel, {
    enabled: enableMainQuery,
  });
  const [isPrimary, setIsPrimary] = useState(false);
  const [primarySubjectCredentials, setPrimarySubjectCredentials] = useState(
    {}
  );
  // const manager = useRef(RNDContext);

  // const [isLocked, setIsLocked] = useState();

  const columns = [
    {
      title: () => (
        <Space>
          <Text>Subjects</Text>
          {selectedSyLevel.sy && selectedSyLevel.level_code ? (
            <Text>
              {" "}
              {`for (SY - ${selectedSyLevel.sy}) (Level - ${selectedSyLevel.level})`}
            </Text>
          ) : null}
        </Space>
      ),
      name: "subject",
      dataIndex: "subject",
      render: (text, record) => {
        return (
          <Space>
            <span>{record.subject}</span>
            {record.with_sub === 1 ? (
              <Tag color="geekblue">with sub ({record.children.length})</Tag>
            ) : null}
          </Space>
        );
      },
    },
    {
      title: (
        <Button
          shape="circle"
          icon={<PlusOutlined />}
          type="primary"
          disabled={!hasSelection}
          onClick={() => {
            setIsPrimary(true);
            setModalVisibility(true);
          }}
        />
      ),
      key: "manage",
      dataIndex: "manage",
      width: 60,
      render: (text, record, index) => {
        if (record.isSub !== 1) {
          return (
            <Dropdown
              overlay={
                <SYMenu
                  record={record}
                  index={index}
                  handlesDeleteSubject={handlesDeleteSubject}
                  handlesAddChildren={handlesAddChildren}
                  handlesClearChildren={handlesClearChildren}
                  setPrimarySubjectCredentials={setPrimarySubjectCredentials}
                  setIsPrimary={setIsPrimary}
                  setModalVisibility={setModalVisibility}
                />
              }
            >
              <Button
                size="small"
                shape="circle"
                icon={<MoreOutlined />}
              ></Button>
            </Dropdown>
          );
        } else {
          return (
            <Button
              size="small"
              type="link"
              danger
              icon={<DeleteOutlined />}
              onClick={() => handlesDeleteSubject(record, index)}
            />
          );
        }
      },
    },
  ];

  useEffect(() => {
    if (selectedSyLevel.sy && selectedSyLevel.level_code) {
      setHasSelection(true);
      setEnableMainQuery(true);
    }
    if (sySubjectsData) {
      setData(sySubjectsData.subjectsSY);
      setSubjectSYCodes(sySubjectsData.subjectsSYCodes);
      // setIsLocked(sySubjectsData.isLocked);
    }
  }, [sySubjectsData, selectedSyLevel]);

  useEffect(() => {
    setRegs(regsDetails);
  }, [regsDetails]);

  const components = {
    body: {
      row: DragableBodyRow,
    },
  };

  const moveRow = useCallback(
    (dragIndex, hoverIndex) => {
      const dragRow = data[dragIndex];
      setData(
        update(data, {
          $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, dragRow],
          ],
        })
      );
    },
    [data]
  );

  const handlesDeleteSubject = (record, index) => {
    if (record.isSub && record.isSub === 1) {
      let primaryIndex = data.findIndex(
        (element) => element.subject_code === record.subject_code_primary
      );

      if (primaryIndex !== -1) {
        setData(
          update(data, {
            [primaryIndex]: {
              children: {
                $set: update(data[primaryIndex].children, {
                  $splice: [[index, 1]],
                }),
              },
            },
          })
        );
        setSubjectSYCodes(
          subjectSYCodes.filter((item) => item !== record.subject_code)
        );
      }
    } else {
      setData(
        update(data, {
          $splice: [[index, 1]],
        })
      );
      setSubjectSYCodes(
        subjectSYCodes.filter((item) => item !== record.subject_code)
      );
    }
  };
  const handlesClearChildren = (record, index) => {
    const toUnset = record.children.map((item) => {
      return item.subject_code;
    });

    setSubjectSYCodes(subjectSYCodes.filter((item) => !toUnset.includes(item)));
    setData(
      update(data, {
        [index]: {
          $set: update(data[index], {
            with_sub: { $set: 0 },
            $unset: ["children"],
          }),
        },
      })
    );
  };
  const handlesAddChildren = (record, index) => {
    setData(
      update(data, {
        [index]: {
          $set: update(data[index], {
            with_sub: { $set: 1 },
            children: { $set: [] },
          }),
        },
      })
    );
  };

  const handlesAppendChildren = (value) => {
    const { record, primaryIndex, subject_code_primary } = value;

    setData(
      update(data, {
        [primaryIndex]: {
          children: {
            $push: [
              {
                subject_code: record.subject_code,
                subject: record.subject,
                subject_code_primary: subject_code_primary,
                isSub: 1,
              },
            ],
          },
        },
      })
    );
    setSubjectSYCodes([...subjectSYCodes, record.subject_code]);
  };
  const handlesAddPrimarySubject = (record) => {
    setData([
      ...data,
      {
        subject_code: record.subject_code,
        subject: record.subject,
        with_sub: 0,
      },
    ]);
    setSubjectSYCodes([...subjectSYCodes, record.subject_code]);
  };

  const handlesCancel = () => {
    setData([]);
    setSubjectSYCodes([]);
    // setLevelDetails({});
    setSelectedSyLevel({
      ...selectedSyLevel,
      level_code: null,
      sy: null,
      level: null,
    });
    selectionForm.setFieldsValue({
      level_code: null,
      sy: null,
      is_transferee: "0",
    });
    setSubjectComposerDrawerK12(false);
  };

  const handlesSave = (event) => {
    event.preventDefault();
    let dataLength = data.length;
    let syData = selectionForm.getFieldValue("sy");

    if (!syData) {
      message.warning("Please select School Year.");
    } else if (dataLength <= 0) {
      message.warning("Subject list cannot be empty.");
    } else {
      let school_level_category_id = regs.level_code > 13 ? 2 : 1;
      let customRegs = customRegsK12(regs);
      let customSubjects = customSubjectsK12(regs, data);

      setCustomGradesData({
        ...customGradesData,
        credentials: {
          registration_no: regs.registration_no,
          school_level_category_id: school_level_category_id,
          sem: 1,
        },
        newCustomGrades: true,
        regs: customRegs,
        subjects: customSubjects,
      });

      setLevelDetails({ ...levelDetails, sy: regs.sy });

      handlesCancel();
      setGradesComposerDrawer(true);
    }
  };
  return (
    <>
      <SelectionForm
        form={selectionForm}
        selectedSyLevel={selectedSyLevel}
        setSelectedSyLevel={setSelectedSyLevel}
        levelDetails={levelDetails}
        lvlDetails={lvlDetails}
        setLvlDetails={setLvlDetails}
        setLevelDetails={setLevelDetails}
        handlesAddPrimarySubject={handlesAddPrimarySubject}
        regs={regs}
        setRegs={setRegs}
      />
      {!hasSelection ? (
        <Empty
          description="Select School Year And Level"
          style={{ marginTop: 60 }}
        />
      ) : (
        <>
          <DragAndDrop>
            <Table
              columns={columns}
              dataSource={data}
              components={components}
              onRow={(record, index) => ({
                index,
                moveRow,
                record,
              })}
              pagination={false}
              rowKey="subject_code"
              loading={isLoading}
              size="middle"
            />
          </DragAndDrop>

          <Space style={{ marginTop: 20 }}>
            <Button
              icon={<SaveOutlined />}
              type="primary"
              onClick={(event) => {
                handlesSave(event);
              }}
            >
              Save
            </Button>
            <Button icon={<CloseOutlined />} onClick={handlesCancel}>
              Cancel
            </Button>
          </Space>
        </>
      )}
      <SubjectK12Selection
        modalVisibility={modalVisibility}
        setModalVisibility={setModalVisibility}
        subjectSYCodes={subjectSYCodes}
        handlesAddPrimarySubject={handlesAddPrimarySubject}
        isPrimary={isPrimary}
        handlesAppendChildren={handlesAppendChildren}
        primarySubjectCredentials={primarySubjectCredentials}
      />
    </>
  );
};

export default ComposerK12;
