import React, { useState } from "react";
import { Table, Tag, Input, Button, Row, Col, Drawer, Avatar } from "antd";
import { PlusCircleOutlined, UserOutlined } from "@ant-design/icons";

import StudentForm from "views/Student/components/StudentForm/Index";
import { formatAge } from "helpers/ageFormatter";
import { titleCase } from "helpers/textTransformer";

import { useStudentQuery, useSettingsQuery } from "views/SYSubjects/hooks/Index";

const { Search } = Input;

const columns = [
  {
    title: "Name",
    key: "name",
    width: 320,
    render: (text, record) =>
      record.img_path ? (
        <span>
          <Avatar src={record.img_path.replace(".jpg", "-thumb.jpg")} style={{ marginRight: 10 }} />
          {`${record.lastname}, ${record.firstname} ${record.middlename}`}
        </span>
      ) : (
        <span>
          <Avatar icon={<UserOutlined />} style={{ marginRight: 10 }} />{" "}
          {`${record.lastname}, ${record.firstname} ${record.middlename}`}
        </span>
      ),
  },

  {
    title: "BirthDay",
    key: "birth_day",
    width: 100,
    render: (text, record) => <span>{record.birth_day}</span>,
  },
  {
    width: 70,
    title: "Age",
    key: "age",
    render: (text, record) => (
      <span>{record.birth_day ? formatAge("Y", record.birth_day) : record.birth_day}</span>
    ),
  },
  {
    title: "Gender",
    key: "gender",
    width: 80,
    render: (text, record) => {
      let tagColor = "geekblue";
      if (record.gender === "female") {
        tagColor = "volcano";
      }
      return <Tag color={tagColor}>{record.gender ? titleCase(record.gender) : record.gender}</Tag>;
    },
  },
  {
    title: "Town",
    key: "town",
    width: 100,
    render: (text, record) => <span>{record.town_city}</span>,
  },

  {
    title: "LRN",
    key: "lrn",
    width: 120,
    render: (text, record) => <span>{record.lrn}</span>,
  },
  {
    title: "Entry SY",
    key: "entry_sy",
    width: 140,
    render: (text, record) => <span>{record.entry_sy}</span>,
  },
];
const tablePagination = {
  showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} Students`,
};
const Students = () => {
  const [drawerVisibility, setDrawerVisibility] = useState(false);
  // const [tableCurrentPage, setTableCurrentPage] = useState(1);
  const [currentData, setCurrentData] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [searchText, setSearchText] = useState(null);
  useSettingsQuery();
  const { isLoading: isLoadingStudents, data: students } = useStudentQuery(searchText);

  const handleRowclick = (evt, record) => {
    setIsEditing(true);
    setCurrentData(record);
    setDrawerVisibility(true);
  };

  const createStudentRecord = () => {
    setIsEditing(false);
    setCurrentData(null);
    setDrawerVisibility(true);
  };

  // const handleTableChanged = (pagination) => {
  //   setTableCurrentPage(pagination.current);
  // };

  const handleSearchKeyUp = (e) => {
    if (e.target.value.trim() === "") {
      setSearchText(null);
    }
  };

  const handleSearch = (value) => {
    if (value.length <= 0) {
      setSearchText(null);
    } else {
      setSearchText(value);
    }
  };
  return (
    <>
      <Row type="flex" justify="space-between">
        <Col>
          <Search
            placeholder="Search Student "
            enterButton
            allowClear
            onKeyUp={handleSearchKeyUp}
            onSearch={
              (value) => {
                handleSearch(value.trim());
              }
              //
            }
            style={{
              width: 300,
              marginBottom: 10,
            }}
            // suffix={<Button icon={<CloseOutlined />} danger type="text" size="small"></Button>}
          />
        </Col>
        <Col>
          <div
            style={{
              textAlign: "right",
              width: "auto",
            }}
          >
            <Button
              type="primary"
              style={{
                marginBottom: 10,
              }}
              icon={<PlusCircleOutlined />}
              onClick={createStudentRecord}
            >
              Add Student
            </Button>
          </div>
        </Col>
      </Row>
      <Table
        columns={columns}
        rowKey="student_id"
        dataSource={students || []}
        pagination={tablePagination}
        loading={isLoadingStudents}
        onRow={(record) => {
          return {
            onClick: (event) => {
              handleRowclick(event, record);
            },
          };
        }}
      />
      <Drawer
        width={1020}
        placement="right"
        closable={false}
        // onClose={() => setDrawerVisibility(false)}
        visible={drawerVisibility}
        destroyOnClose={true}
      >
        <StudentForm
          currentData={currentData}
          isEditing={isEditing}
          setDrawerVisibility={setDrawerVisibility}
        />
      </Drawer>
    </>
  );
};

export default Students;
