import pdfMake from "pdfmake/build/pdfmake";
import vfsFonts from "pdfmake/build/vfs_fonts";
import { queryCache } from "react-query";
import getBase64ImageFromURL from "helpers/base64ImageFromURL";
import { titleCase } from "helpers/textTransformer";
import moment from "moment";

export default async (data, otherDetails) => {
  const { permanent } = data;
  const { vfs } = vfsFonts.pdfMake;
  pdfMake.vfs = vfs;

  var cellmargin = -0.6;
  var normalFontSize = 7.5;
  var cellColor = "#A8D08D";
  var depEdLogo = await getBase64ImageFromURL(
    "https://itmg.sgp1.digitaloceanspaces.com/swa/deped.jpg"
  );
  var logo = await getBase64ImageFromURL("https://itmg.sgp1.digitaloceanspaces.com/swa/lsim.jpg");
  var imgBlank = await getBase64ImageFromURL(
    "https://itmg.sgp1.digitaloceanspaces.com/swa/_blank.jpg "
  );
  var imgPath = permanent.student.img_path
    ? await getBase64ImageFromURL(permanent.student.img_path.replace(".jpg", "-card.jpg"))
    : imgBlank;

  var thinBorder = {
    hLineWidth: function (i, node) {
      return i === 0 || i === node.table.body.length ? 0.1 : 0.1;
    },
    vLineWidth: function (i, node) {
      return i === 0 || i === node.table.widths.length ? 0.1 : 0.1;
    },
  };
  const { principal_elem } = queryCache.getQueryData("settings");

  // var emptyRows = [
  //   [{}, {}, { text: " ", style: "gradeNormal" }],
  //   [{}, {}, { text: " ", style: "gradeNormal" }],
  //   [{}, {}, { text: " ", style: "gradeNormal" }],
  //   [{}, {}, { text: " ", style: "gradeNormal" }],
  //   [{}, {}, { text: " ", style: "gradeNormal" }],
  //   [{}, {}, { text: " ", style: "gradeNormal" }],
  //   [{}, {}, { text: " ", style: "gradeNormal" }],
  //   [{}, {}, { text: " ", style: "gradeNormal" }],
  //   [{}, {}, { text: " ", style: "gradeNormal" }],
  //   [{}, {}, { text: " ", style: "gradeNormal" }],
  //   [{}, {}, { text: " ", style: "gradeNormal" }],
  //   [{}, {}, { text: " ", style: "gradeNormal" }],
  //   [{}, {}, { text: " ", style: "gradeNormal" }],
  //   [{}, {}, { text: " ", style: "gradeNormal" }],
  // ];

  const emptyRows = () => {
    return [
      [{ colSpan: 3, text: "School: -", style: "subjectLabelBG" }, {}, {}],
      [
        { text: `Level :  -`, style: "subjectLabelBG" },
        { colSpan: 2, text: "School Year: -", style: "subjectLabelBG" },
        {},
      ],
      [
        { text: "SUBJECTS", style: "gradeNormal" },
        { text: "FINAL GRADE", style: "gradeNormal" },
        { text: "ACTION TAKEN", style: "gradeNormal" },
      ],
      [{}, {}, { text: " ", style: "gradeNormal" }],
      [{}, {}, { text: " ", style: "gradeNormal" }],
      [{}, {}, { text: " ", style: "gradeNormal" }],
      [{}, {}, { text: " ", style: "gradeNormal" }],
      [{}, {}, { text: " ", style: "gradeNormal" }],
      [{}, {}, { text: " ", style: "gradeNormal" }],
      [{}, {}, { text: " ", style: "gradeNormal" }],
      [{}, {}, { text: " ", style: "gradeNormal" }],
      [{}, {}, { text: " ", style: "gradeNormal" }],
      [{}, {}, { text: " ", style: "gradeNormal" }],
      [{}, {}, { text: " ", style: "gradeNormal" }],
      [{}, {}, { text: " ", style: "gradeNormal" }],
      [{}, {}, { text: " ", style: "gradeNormal" }],
      [{}, {}, { text: " ", style: "gradeNormal" }],
      [{}, {}, {}],
      [
        { text: "General Average", style: "subjectLabel", bold: true },
        { text: "-", style: "gradeNormal" },
        {
          rowSpan: 3,
          text: "Eligible for admission to\n\n -",
          style: "gradeNormal",
        },
      ],
      [
        { text: "No. of School Days in year", style: "subjectLabel" },
        { text: "-", style: "gradeNormal" },
        {},
      ],
      [
        { text: "No. of Days present", style: "subjectLabel" },
        { text: "-", style: "gradeNormal" },
        {},
      ],
    ];
  };

  const renderRows = (registration) => {
    // action_taken: "PROMOTED"
    // classified_as: "ADVANCE CASA"
    // days_present: "172"
    // final_average: "90.260"
    // final_grade: "90.260"
    // level: "Junior Casa"
    // level_code: 2
    // registration_no: "6100-08-0073"
    // school_attended: "L.S.I.M."
    // school_days: "201"
    return [
      [
        { colSpan: 3, text: `School: ${registration.school_attended}`, style: "subjectLabelBG" },
        {},
        {},
      ],
      [
        { text: `Level :  ${registration.level}`, style: "subjectLabelBG" },
        { colSpan: 2, text: `School Year: ${registration.sy}`, style: "subjectLabelBG" },
        {},
      ],
      [
        { text: "SUBJECTS", style: "gradeNormal" },
        { text: "FINAL GRADE", style: "gradeNormal" },
        { text: "ACTION TAKEN", style: "gradeNormal" },
      ],

      ...registration.subjects,
      [{}, {}, {}],
      [
        { text: "General Average", style: "subjectLabel", bold: true },
        { text: Number(registration.final_average).toFixed(3), style: "gradeNormal" },
        {
          rowSpan: 3,
          text: `Eligible for admission to\n\n ${registration.classified_as}`,
          style: "gradeNormal",
        },
      ],
      [
        { text: "No. of School Days in year", style: "subjectLabel" },
        { text: registration.school_days, style: "gradeNormal" },
        {},
      ],
      [
        { text: "No. of Days present", style: "subjectLabel" },
        { text: registration.days_present, style: "gradeNormal" },
        {},
      ],
    ];
  };

  const documentDefinition = {
    background: {
      columns: [
        { alignment: "right", margin: [0, 10, 50, 0], image: depEdLogo, fit: [60, 60] },
        { margin: [100, 10, 0, 0], image: logo, fit: [60, 60] },
      ],
    },
    pageMargins: [10, 20, 10, 10],
    pageOrientation: "portrait",
    pageSize: "A4",
    content: [
      [
        {
          table: {
            widths: [120, "*", 120],
            body: [
              [
                { border: [false, false, false, false], text: "" },
                {
                  border: [false, false, false, false],
                  stack: [
                    {
                      text: "Republic of the Philippines",
                      style: "headerNormal",
                      pageBreak: "",
                    },
                    {
                      text: "Department of Education",
                      style: "headerNormal",
                    },
                    {
                      text: "Region IV-A Calabarzon",
                      style: "headerNormal",
                    },
                    {
                      text: "Division of Laguna",
                      style: "headerNormal",
                      margin: [0, 0, 0, 4],
                    },
                    {
                      text: "LITTLE SHEPHERD INTEGRATED MONTESSORI",
                      style: "header",
                    },
                    {
                      text: "LITTLE SHEPHERD BUSINESS AND SCIENCE HIGH SCHOOL",
                      style: "header",
                    },
                    {
                      text: "National Road, Brgy. Lewin, Lumban, Laguna",
                      style: "headerNormal",
                      pageBreak: "",
                    },
                    {
                      text: "ELEMENTARY SCHOOL STUDENT PERMANENT RECORD",
                      style: "header",
                      margin: [0, 6, 0, 0],
                      fontSize: 12,
                    },
                    {
                      text: "School ID.: 402547",
                      style: "gradeNormal",
                      margin: [0, 0, 0, 5],
                    },
                  ],
                },
                {
                  border: [false, false, false, false],
                  margin: [12, 0, 0, 0],
                  image: imgPath,
                  fit: [100, 100],
                },
              ],
            ],
          },
        },
        {
          table: {
            widths: [70, "*", "*", "*"],
            body: [
              [
                { text: `LRN: ${permanent.student.lrn}`, style: "subjectLabelBG" },
                { text: `Last Name: ${permanent.student.lastname}`, style: "subjectLabelBG" },
                { text: `First Name: ${permanent.student.firstname}`, style: "subjectLabelBG" },
                { text: `Middle Name: ${permanent.student.middlename}`, style: "subjectLabelBG" },
              ],
            ],
          },
          layout: thinBorder,
        },
        {
          table: {
            widths: ["*", "*", 190],
            body: [
              [
                {
                  text: `Date of Birth: ${moment(permanent.student.birth_day).format(
                    "MMM DD, YYYY"
                  )}`,
                  style: "subjectLabelBG",
                },
                { text: `Birth Place: ${permanent.student.birth_place}`, style: "subjectLabelBG" },
                {
                  text: `Gender: ${
                    permanent.student.gender ? titleCase(permanent.student.gender) : ""
                  }`,
                  style: "subjectLabelBG",
                },
              ],
            ],
          },
          layout: thinBorder,
        },
        {
          table: {
            widths: ["*", 295],
            body: [
              [
                {
                  text: `Parents: ${permanent.student.mother} ${permanent.student.father}`,
                  style: "subjectLabelBG",
                },
                {
                  text: `Address: ${permanent.student.house_number || ""} ${
                    permanent.student.street_barangay || ""
                  } ${permanent.student.town_city || ""} ${permanent.student.province || ""}`,
                  style: "subjectLabelBG",
                },
              ],
            ],
          },
          layout: thinBorder,
        },
        {
          margin: [0, 5, 0, 0],
          table: {
            widths: [60, "*", 60, 50, 50, 50, 70],
            body: [
              [
                { text: "School Year", style: "subjectLabelBG", alignment: "center" },
                { text: "School Attended", style: "subjectLabelBG", alignment: "center" },
                { text: "Level", style: "subjectLabelBG", alignment: "center" },
                { text: "School Days", style: "subjectLabelBG", alignment: "center" },
                { text: "Days Present", style: "subjectLabelBG", alignment: "center" },
                { text: "Final Grade", style: "subjectLabelBG", alignment: "center" },
                { text: "Action Taken", style: "subjectLabelBG", alignment: "center" },
              ],
              [
                {
                  text: permanent.registration["1"] ? permanent.registration["1"].sy : "-",
                  style: "gradeNormal",
                },
                {
                  text: permanent.registration["1"]
                    ? permanent.registration["1"].school_attended
                    : "-",
                  style: "gradeNormal",
                },
                {
                  text: permanent.registration["1"] ? permanent.registration["1"].level : "-",
                  style: "gradeNormal",
                },
                {
                  text: permanent.registration["1"] ? permanent.registration["1"].school_days : "-",
                  style: "gradeNormal",
                },
                {
                  text: permanent.registration["1"]
                    ? permanent.registration["1"].days_present
                    : "-",
                  style: "gradeNormal",
                },
                {
                  text: permanent.registration["1"]
                    ? Number(permanent.registration["1"].final_average).toFixed(3)
                    : "-",
                  style: "gradeNormal",
                },
                {
                  text: permanent.registration["1"]
                    ? permanent.registration["1"].action_taken
                    : "-",
                  style: "gradeNormal",
                },
              ],
              [
                {
                  text: permanent.registration["2"] ? permanent.registration["2"].sy : "-",
                  style: "gradeNormal",
                },
                {
                  text: permanent.registration["2"]
                    ? permanent.registration["2"].school_attended
                    : "-",
                  style: "gradeNormal",
                },
                {
                  text: permanent.registration["2"] ? permanent.registration["2"].level : "-",
                  style: "gradeNormal",
                },
                {
                  text: permanent.registration["2"] ? permanent.registration["2"].school_days : "-",
                  style: "gradeNormal",
                },
                {
                  text: permanent.registration["2"]
                    ? permanent.registration["2"].days_present
                    : "-",
                  style: "gradeNormal",
                },
                {
                  text: permanent.registration["2"]
                    ? Number(permanent.registration["2"].final_average).toFixed(3)
                    : "-",
                  style: "gradeNormal",
                },
                {
                  text: permanent.registration["2"]
                    ? permanent.registration["2"].action_taken
                    : "-",
                  style: "gradeNormal",
                },
              ],
              [
                {
                  text: permanent.registration["3"] ? permanent.registration["3"].sy : "-",
                  style: "gradeNormal",
                },
                {
                  text: permanent.registration["3"]
                    ? permanent.registration["3"].school_attended
                    : "-",
                  style: "gradeNormal",
                },
                {
                  text: permanent.registration["3"] ? permanent.registration["3"].level : "-",
                  style: "gradeNormal",
                },
                {
                  text: permanent.registration["3"] ? permanent.registration["3"].school_days : "-",
                  style: "gradeNormal",
                },
                {
                  text: permanent.registration["3"]
                    ? permanent.registration["3"].days_present
                    : "-",
                  style: "gradeNormal",
                },
                {
                  text: permanent.registration["3"]
                    ? Number(permanent.registration["3"].final_average).toFixed(3)
                    : "-",
                  style: "gradeNormal",
                },
                {
                  text: permanent.registration["3"]
                    ? permanent.registration["3"].action_taken
                    : "-",
                  style: "gradeNormal",
                },
              ],
              [
                {
                  text: permanent.registration["4"] ? permanent.registration["4"].sy : "-",
                  style: "gradeNormal",
                },
                {
                  text: permanent.registration["4"]
                    ? permanent.registration["4"].school_attended
                    : "-",
                  style: "gradeNormal",
                },
                {
                  text: permanent.registration["4"] ? permanent.registration["4"].level : "-",
                  style: "gradeNormal",
                },
                {
                  text: permanent.registration["4"] ? permanent.registration["4"].school_days : "-",
                  style: "gradeNormal",
                },
                {
                  text: permanent.registration["4"]
                    ? permanent.registration["4"].days_present
                    : "-",
                  style: "gradeNormal",
                },
                {
                  text: permanent.registration["4"]
                    ? Number(permanent.registration["4"].final_average).toFixed(3)
                    : "-",
                  style: "gradeNormal",
                },
                {
                  text: permanent.registration["4"]
                    ? permanent.registration["4"].action_taken
                    : "-",
                  style: "gradeNormal",
                },
              ],
              [
                {
                  text: permanent.registration["5"] ? permanent.registration["5"].sy : "-",
                  style: "gradeNormal",
                },
                {
                  text: permanent.registration["5"]
                    ? permanent.registration["5"].school_attended
                    : "-",
                  style: "gradeNormal",
                },
                {
                  text: permanent.registration["5"] ? permanent.registration["5"].level : "-",
                  style: "gradeNormal",
                },
                {
                  text: permanent.registration["5"] ? permanent.registration["5"].school_days : "-",
                  style: "gradeNormal",
                },
                {
                  text: permanent.registration["5"]
                    ? permanent.registration["5"].days_present
                    : "-",
                  style: "gradeNormal",
                },
                {
                  text: permanent.registration["5"]
                    ? Number(permanent.registration["5"].final_average).toFixed(3)
                    : "-",
                  style: "gradeNormal",
                },
                {
                  text: permanent.registration["5"]
                    ? permanent.registration["5"].action_taken
                    : "-",
                  style: "gradeNormal",
                },
              ],
            ],
          },
          layout: thinBorder,
        },
        {
          columns: [
            {
              margin: [0, 5, 0, 0],
              table: {
                widths: ["*", 60, 60],
                body: permanent.registration["6"]
                  ? renderRows(permanent.registration["6"])
                  : emptyRows(),
              },
              layout: thinBorder,
            },
            {
              margin: [0, 5, 0, 0],
              table: {
                widths: ["*", 60, 60],
                body: permanent.registration["7"]
                  ? renderRows(permanent.registration["7"])
                  : emptyRows(),
              },
              layout: thinBorder,
            },
          ],
        },
        {
          columns: [
            {
              margin: [0, 5, 0, 0],
              table: {
                widths: ["*", 60, 60],
                body: permanent.registration["8"]
                  ? renderRows(permanent.registration["8"])
                  : emptyRows(),
              },
              layout: thinBorder,
            },
            {
              margin: [0, 5, 0, 0],
              table: {
                widths: ["*", 60, 60],
                body: permanent.registration["9"]
                  ? renderRows(permanent.registration["9"])
                  : emptyRows(),
              },
              layout: thinBorder,
            },
          ],
        },
        {
          margin: [50, 6, 0, 2],
          text: `I certify that this is a true record of ${permanent.student.lastname}, ${
            permanent.student.firstname
          } ${permanent.student.middlename}. This student is eligible on this ${moment().format(
            "Do"
          )} day of ${moment().format("MMMM")}, for admission to ${otherDetails.admissionTo}, as`,
          style: "subjectLabel",
        },
        {
          text:
            "regular /irregular student, and has no monetary nor property accountability in this school.",
          style: "subjectLabel",
        },
        {
          margin: [0, 6, 0, 0],
          columns: [
            {
              width: 400,
              text: [
                { text: "REMARKS: ", fontSize: 9 },
                {
                  text: otherDetails.remarks,
                  fontSize: 9,
                },
              ],
            },
            [
              {
                margin: [0, 30, 0, 0],
                text: principal_elem,
                style: "headerNormal",
                alignment: "center",
              },
              { text: "Principal", style: "subjectLabel", alignment: "center" },
            ],
          ],
        },
      ],
    ],
    styles: {
      headerNormal: {
        fontSize: 10,
        alignment: "center",
        lineHeight: 1,
      },
      header: {
        fontSize: 9,
        bold: true,
        alignment: "center",
        lineHeight: 1.1,
      },
      subheader: {
        fontSize: normalFontSize,
        bold: true,
        alignment: "center",
      },
      title: {
        fontSize: 10,
        bold: true,
        alignment: "center",
        margin: 10,
      },
      labelBold: {
        fontSize: 8.5,
        bold: true,
      },
      labelNormal: {
        fontSize: 8.5,
      },
      labelBoldItalic: {
        fontSize: 8.5,
        bold: true,
        italics: true,
        alignment: "center",
        margin: [0, 0, 0, 0],
      },
      subjectLabel: {
        fontSize: normalFontSize,
        // 			bold: true,
        margin: cellmargin,
      },
      subjectLabelBG: {
        fontSize: normalFontSize,
        fillColor: cellColor,
        margin: cellmargin,
      },
      subjectSubLabel: {
        fontSize: normalFontSize,
        margin: [10, cellmargin, cellmargin, cellmargin],
        italics: true,
      },
      gradeBold: {
        fontSize: normalFontSize,
        bold: true,
        margin: cellmargin,
        alignment: "center",
      },
      gradeNormal: {
        fontSize: normalFontSize,
        margin: cellmargin,
        alignment: "center",
      },
      table: {
        margin: [0, 10, 0, 10],
      },
      averageFont: {
        fontSize: 11,
        bold: true,
        margin: cellmargin,
        alignment: "center",
      },
      listBold: {
        fontSize: 8,
        bold: true,
      },
      listBoldIndent: {
        fontSize: 8,
        bold: true,
        margin: [5, 0, 0, 0],
      },
      listNormal: {
        fontSize: 8,
      },
      subjectLabelSM: {
        fontSize: 7,
        margin: 1,
      },
      subjectLabelTiny: {
        fontSize: 6,
        alignment: "center",
      },
      gradeNormalSm: {
        fontSize: 7,
        alignment: "center",
      },
    },
  };

  try {
    pdfMake.createPdf(documentDefinition).open();
  } catch (error) {}

  //   pdfMake.createPdf(documentDefinition).print({}, window);
};
