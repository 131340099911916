import React, { useState, useEffect } from "react";
import { Table, Space, Card, Modal, message, Button, Tag } from "antd";

import { useSYTeachers } from "views/SYSubjects/hooks/Index";

import DynamicSearchBox from "globalComponents/DynamicSearchBox";
import { ExclamationCircleOutlined, DeleteOutlined } from "@ant-design/icons";
import { useMutation } from "react-query";
import http from "services/httpService.js";

const { confirm } = Modal;

const SYTeacher = ({ selectedSyLevel, disabled, simple = false, onTeacherSelection, isLocked }) => {
  const [enableMainQuery, setEnableMainQuery] = useState(false);
  const [data, setData] = useState([]);
  const { isLoading, data: syTeacherData } = useSYTeachers(selectedSyLevel, {
    enabled: enableMainQuery,
  });
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [isRowSelected, setIsRowSelected] = useState(false);
  const appendToData = (values) => {
    setData([...data, values]);
  };

  const removeFromData = (values) => {
    setData(data.filter((item) => item.employee_id !== values.employee_id));
  };

  const [mutate] = useMutation(
    async (teacherdata) => {
      return await http.put(
        `teachersy/addremove/${teacherdata.processType}/${selectedSyLevel.sy}/${selectedSyLevel.level_code}`,
        {
          values: teacherdata.values,
        }
      );
    },
    {
      onSuccess: (data, variables) => {
        const { values, processType } = variables;
        const { error } = data.data;
        if (processType === "remove") {
          if (error) {
            message.error(
              "Unable to remove, students already registered under this Teacher, School Year / Level",
              3
            );
          } else {
            removeFromData(values);
            message.success("Succesfully removed.");
          }
        } else {
          appendToData(values);
          message.success("Succesfully added.");
        }
      },
      onError: () => {
        message.warning("Unable to save, Something went wrong :/");
      },
    }
  );

  const columns = [
    {
      title: () => (
        <Space size={60}>
          <span>Name</span>
          {!isRowSelected && simple && (
            <span>
              <Tag color="volcano">Please select Teacher!</Tag>
            </span>
          )}
        </Space>
      ),
      dataIndex: "fullname",
      key: "name",
    },
    {
      title: "",
      key: "action",
      render: (text, record) =>
        isLocked ? null : simple ? null : (
          <Button
            type="link"
            danger
            onClick={() => showAddTeacherConfirm(record, "remove")}
            icon={<DeleteOutlined />}
          />
        ),
    },
  ];

  useEffect(() => {
    if (!disabled) {
      if (selectedSyLevel.sy && selectedSyLevel.level_code) {
        setEnableMainQuery(true);
        setData(syTeacherData);
        setIsRowSelected(false);

        if (!selectedSyLevel.employee_id) {
          setSelectedRowKeys([]);
        }
      }
    }
  }, [syTeacherData, selectedSyLevel, disabled]);

  const onSelect = (option) => {
    if (option) {
      const checkInList = data.filter((item) => item.employee_id === option.employee_id);
      if (checkInList.length > 0) {
        message.warning(
          `Unable to Add, ${option.fullname} is already assigned to this School Year / Level`
        );
      } else {
        showAddTeacherConfirm(option, "add");
      }
    }
  };
  const showAddTeacherConfirm = (option, processType) => {
    confirm({
      title: processType === "add" ? "Confirm Add?" : "Confirm Delete?",
      icon: <ExclamationCircleOutlined />,
      content:
        processType === "add" ? (
          <p>
            <b>{option.fullname}</b>, will be assigned to <br></br>
            <b>SY</b>-{selectedSyLevel.sy}
            <b>LEVEL</b>-{selectedSyLevel.level}
          </p>
        ) : (
          <p>
            <b>{option.fullname}</b>, will be removed to from <br></br>
            <b>SY</b>-{selectedSyLevel.sy}
            <b>LEVEL</b>-{selectedSyLevel.level}
          </p>
        ),
      onOk() {
        mutate({ values: option, processType: processType });
      },
      onCancel() {},
    });
  };
  const handlesOnSelection = (record, selected, selectedRows) => {
    // console.log(record, selectedRows);
    setIsRowSelected(selected);
    onTeacherSelection(record);
  };

  return simple ? (
    <>
      <Table
        columns={columns}
        rowKey="employee_id"
        dataSource={data}
        size="small"
        pagination={false}
        loading={isLoading}
        rowSelection={{
          type: "radio",
          selections: false,
          hideSelectAll: true,
          onSelect: handlesOnSelection,
          onChange: (selectedRowKeys, selectedRows) => {
            setSelectedRowKeys(selectedRowKeys);
            // console.log(selectedRowKeys, selectedRows);
          },
          selectedRowKeys: selectedRowKeys,
        }}
      />
    </>
  ) : (
    <Card title="SY Teachers" bordered={false}>
      {isLocked ? null : (
        <DynamicSearchBox
          searchFrom={"employee"}
          showAddbutton={false}
          disabled={disabled}
          onSelect={onSelect}
        />
      )}

      <Table
        columns={columns}
        rowKey="employee_id"
        dataSource={data}
        size="small"
        pagination={false}
        loading={isLoading}
      />
    </Card>
  );
};

export default SYTeacher;
