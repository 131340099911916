import React, { useState, useEffect } from "react";
import {
  Table,
  Row,
  Col,
  message,
  Select,
  Form,
  // Typography,
  Button,
  Empty,
  Tag,
  Modal,
  Spin,
  Card,
  Space,
  Switch,
  Badge,
  Alert,
} from "antd";
import { ExclamationCircleOutlined, CheckCircleOutlined } from "@ant-design/icons";
import moment from "moment";
import { useMutation, queryCache } from "react-query";
import http from "services/httpService.js";
import update from "immutability-helper";
// import { upperCase } from "helpers/textTransformer";

import {
  // useSchoolLevelQuery,
  useSchoolLevelListingQuery,
  useProcessSubjects,
} from "views/SYSubjects/hooks/Index";

const { Option } = Select;
const { confirm } = Modal;
// const { Title, Link } = Typography;
// const { confirm } = Modal;
// const { TabPane } = Tabs;

const SelectionForm = ({
  selectedSyLevel,
  setSelectedSyLevel,
  setHasSelection,
  setSelectedRowKeys,
}) => {
  // const { data } = useSchoolLevelQuery(3);
  const [form] = Form.useForm();
  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 8 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 16 },
    },
  };
  const sySelections = generateSy();
  function generateSy() {
    let yearArray = [];
    let yearNow = Number(moment().format("YYYY"));
    for (let i = yearNow; i > 2000; i--) {
      yearArray.push(`${i}-${i + 1}`);
    }
    return yearArray;
  }
  const onSelectionChange = (value, option) => {
    setHasSelection(true);
    setSelectedRowKeys([]);
    setSelectedSyLevel({ ...selectedSyLevel, sy: value, level_code: null, level: null });
  };

  return (
    <>
      <Form {...formItemLayout} form={form}>
        <Form.Item label="Select School Year" name="sy">
          <Select
            placeholder="none"
            style={{ width: 200 }}
            onChange={(value, option) => {
              onSelectionChange(value, option);
            }}
            rules={[
              {
                required: true,
                message: "Please select School Year!",
              },
            ]}
          >
            {sySelections.map((item) => {
              return (
                <Option value={item} key={item}>
                  {item}
                </Option>
              );
            })}
          </Select>
        </Form.Item>
      </Form>
    </>
  );
};

const RegisteredByTeacher = ({ selectedSyLevel, setIsSaving }) => {
  const { isLoading, data } = useProcessSubjects(selectedSyLevel);
  const [registeredByTeacherData, setRegisteredByTeacherData] = useState([]);
  const [sySummary, setSySummary] = useState([]);
  const [sySummaryStatus, setSySummaryStatus] = useState(false);
  const [sRowKeys, setSRowKeys] = useState([]);
  const [sRows, setSRows] = useState([]);
  const [switchStatus, setSwitchStatus] = useState(true);

  //

  const [mutate] = useMutation(
    async (values) => {
      console.log(values);
      await http.put(`processsubjects/update`, {
        credentials: selectedSyLevel,
        values: values,
      });
    },
    {
      onSuccess: (data, variables) => {
        setIsSaving(false);
        message.success("Process succesfful.");
      },
      onError: () => {
        setIsSaving(false);
        message.warning("Unable to process, Something went wrong :/");
      },
      onSettled: () => {
        queryCache.invalidateQueries([
          "processsubjects",
          { level_code: selectedSyLevel.level_code, sy: selectedSyLevel.sy },
        ]);
        clearSelection();
      },
    }
  );

  useEffect(() => {
    if (data) {
      setRegisteredByTeacherData(data.registeredByTeacher);
      setSySummary(data.sySummary.summary);
      setSySummaryStatus(data.sySummary.status === "passed" ? true : false);
      setSRowKeys(
        data.registeredByTeacher.map((i) => {
          return [];
        })
      );
      setSRows(
        data.registeredByTeacher.map((i) => {
          return [];
        })
      );
    }
  }, [data]);

  const columns = [
    {
      title: "Name",
      dataIndex: "fullname",
      key: "fullname",
    },
    {
      title: "",
      dataIndex: "subject_count",
      key: "subject_count",

      render: (text, record) => {
        return record.subject_count > 0 ? (
          <Tag color="blue">subjects ({text})</Tag>
        ) : (
          <Tag color="orange">none</Tag>
        );
      },
    },
  ];
  const handlesSwitchChange = (checked) => {
    setSwitchStatus(checked);
  };

  const RegisteredTable = ({ columns, dataSource, index, sRowKeys, setSRowKeys, switchStatus }) => {
    const handlesOnChange = (selectedRowKeys, selectedRows) => {
      setSRowKeys(
        update(sRowKeys, {
          [index]: { $set: selectedRowKeys },
        })
      );
      setSRows(
        update(sRows, {
          [index]: { $set: selectedRows },
        })
      );
    };

    return (
      <Table
        disab
        rowKey="student_id"
        columns={columns}
        dataSource={dataSource}
        rowSelection={
          switchStatus
            ? {
                hideSelectAll: true,
                // onSelect: handlesOnSelection,
                onChange: (selectedRowKeys, selectedRows) => {
                  handlesOnChange(selectedRowKeys, selectedRows);
                  // setSRowKeys([...sRowKeys, [0], selectedRowKeys]);
                },
                selectedRowKeys: sRowKeys[index],
              }
            : false
        }
        showHeader={false}
        size="small"
        pagination={true}
      />
    );
  };

  const clearSelection = () => {
    setSRowKeys(
      data.registeredByTeacher.map((i) => {
        return [];
      })
    );
    setSRows(
      data.registeredByTeacher.map((i) => {
        return [];
      })
    );
  };

  const saveConfirm = (toRegister) => {
    confirm({
      title: "You are about to create Rating Sheet!",
      icon: <ExclamationCircleOutlined />,
      content: (
        <p>
          <b>Important!</b>, make sure that you have composed the right subjects and traits for this
          particular SY / Level. Yo may not be able to make any changes on subjects and traits once
          you have created rating sheet. Reversing the process can only be done by submitting
          support ticket to School Web Assistant Administrator.
          <br></br>
          <br></br>
          Click <b>[OK]</b> to proceed.<br></br>
          Click <b>[Cancel]</b> if you think you need to review first the subjects and traits.
        </p>
      ),
      onOk() {
        setIsSaving(true);
        mutate([...toRegister]);
      },
      onCancel() {},
    });
  };

  return (
    <Spin spinning={isLoading} size="large" tip="Loading, please wait...">
      {sySummaryStatus ? (
        <Space style={{ marginBottom: 4 }}>
          <Switch defaultChecked onChange={handlesSwitchChange} />
          {switchStatus ? (
            <Space>
              <Button
                onClick={() => {
                  let toRegister = [];
                  // console.log(sRowKeys);
                  // console.log(sRows);
                  sRows.forEach((i) => {
                    i.forEach((j) => {
                      toRegister.push(j);
                    });
                  });

                  if (toRegister.length <= 0) {
                    setIsSaving(false);
                    message.warning("You must select student to start the process");
                  } else {
                    saveConfirm(toRegister);
                    // mutate([...toRegister]);
                  }
                }}
              >
                Process Selected
              </Button>

              <Button
                onClick={() => {
                  clearSelection();
                }}
              >
                Clear Selected
              </Button>
            </Space>
          ) : (
            <Button
              onClick={() => {
                let toRegister = [];
                registeredByTeacherData.forEach((i) => {
                  i.students.forEach((j) => {
                    toRegister.push(j);
                  });
                });

                if (toRegister.length <= 0) {
                  setIsSaving(false);
                  message.warning("No registered student/s found.");
                } else {
                  saveConfirm(toRegister);
                  // mutate([...toRegister]);
                }
              }}
            >
              Process All
            </Button>
          )}
        </Space>
      ) : (
        <Alert
          message="Please check for incomplete SY Subjects or SY Traits"
          type="warning"
          showIcon
        />
      )}
      {sySummary.length > 0 ? (
        <div style={{ paddingTop: 4, paddingBottom: 4 }}>
          <Space>
            {sySummary.map((item, index) => {
              return (
                <Tag
                  color={item.status === "passed" ? "green" : "orange"}
                  icon={
                    item.status === "passed" ? (
                      <CheckCircleOutlined />
                    ) : (
                      <ExclamationCircleOutlined />
                    )
                  }
                  key={index}
                >{`${item.title}-${item.message}`}</Tag>
              );
            })}
          </Space>
        </div>
      ) : null}

      {registeredByTeacherData.length > 0 ? (
        registeredByTeacherData.map((item, index) => {
          return (
            <Card
              size="small"
              title={
                <Badge count={item.students.length} offset={[16, 6]}>
                  <b>Teacher - </b> {item.fullname}
                </Badge>
              }
              key={item.employee_id}
              style={{ marginBottom: 6 }}
            >
              <RegisteredTable
                columns={columns}
                dataSource={item.students}
                index={index}
                sRowKeys={sRowKeys}
                setSRowKeys={setSRowKeys}
                switchStatus={switchStatus}
              />
            </Card>
          );
        })
      ) : (
        <Empty description="No registered students found." image={Empty.PRESENTED_IMAGE_SIMPLE} />
      )}
    </Spin>
  );
};

const ProcessSubjects = () => {
  const [isSaving, setIsSaving] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [hasSelection, setHasSelection] = useState(false);
  const [levelListing, setLevelListing] = useState([]);
  const { isLoading, data: levels } = useSchoolLevelListingQuery();
  const [selectedSyLevel, setSelectedSyLevel] = useState({
    sy: null,
    level_code: null,
    level: null,
  });

  const columns = [
    {
      title: "Level",
      dataIndex: "level",
      key: "level",
    },
    {
      title: "Category",
      dataIndex: "school_level_category",
      key: "school_level_category",
    },
  ];

  useEffect(() => {
    if (levels) {
      setLevelListing(levels);
    }
  }, [levels]);

  const handlesOnSelect = (record, selected, selectedRows) => {
    setSelectedSyLevel({ ...selectedSyLevel, level_code: record.level_code, level: record.level });
  };

  return (
    <>
      <Spin spinning={isSaving} size="large" tip="Processing, please wait...">
        <Row gutter={16}>
          <Col span={12} style={{ marginTop: 12 }}>
            <SelectionForm
              selectedSyLevel={selectedSyLevel}
              setSelectedSyLevel={setSelectedSyLevel}
              setHasSelection={setHasSelection}
              setSelectedRowKeys={setSelectedRowKeys}
            />
            <Table
              disab
              rowKey="level_code"
              loading={isLoading}
              columns={columns}
              dataSource={levelListing}
              rowSelection={
                hasSelection
                  ? {
                      type: "radio",
                      selections: hasSelection,
                      hideSelectAll: true,
                      onSelect: handlesOnSelect,
                      onChange: (selectedRowKeys, selectedRows) => {
                        setSelectedRowKeys(selectedRowKeys);
                        // console.log(selectedRowKeys, selectedRows);
                      },
                      selectedRowKeys: selectedRowKeys,
                    }
                  : false
              }
              size="small"
              pagination={false}
            />
          </Col>
          <Col span={12} style={{ marginTop: 12 }}>
            {selectedRowKeys.length > 0 ? (
              <RegisteredByTeacher selectedSyLevel={selectedSyLevel} setIsSaving={setIsSaving} />
            ) : (
              <Empty />
            )}
          </Col>
        </Row>
      </Spin>
    </>
  );
};
export default ProcessSubjects;
