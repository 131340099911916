import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Layout, Menu } from "antd";

import logomedium from "assets/images/logo-medium.png";
import logosmall from "assets/images/logo-small.png";
import {
  DesktopOutlined,
  PieChartOutlined,
  TeamOutlined,
  TagOutlined,
  UserOutlined,
  SolutionOutlined,
  BookOutlined,
  CaretRightOutlined,
  CarryOutOutlined,
  FileTextOutlined,
} from "@ant-design/icons";

const Sidebar = ({ activeMenu, setIsCollapsedMain }) => {
  const [isCollapsed, setIsCollapsed] = useState(false);

  const { Sider } = Layout;
  const { SubMenu } = Menu;

  const handleCollapse = (collapsed) => {
    setIsCollapsed(collapsed);
    setIsCollapsedMain(collapsed);
  };

  return (
    <Sider
      collapsible
      collapsed={isCollapsed}
      onCollapse={handleCollapse}
      className="sidebar-fixed"
    >
      <div style={{ padding: "30px 10px 20px 10px", textAlign: "center" }}>
        {isCollapsed ? (
          <img width={60} src={logosmall} alt="SWA Small" />
        ) : (
          <img width={160} src={logomedium} alt="SWA Medium" />
        )}
      </div>

      <Menu
        theme="dark"
        defaultSelectedKeys={[activeMenu]}
        selectedKeys={[activeMenu]}
        mode="inline"
        // defaultOpenKeys={["SYSubjects", "Subjects", "RatingSheet"]}
      >
        <Menu.Item key="dashboard" icon={<PieChartOutlined />}>
          <Link to="/dashboard">DashBoard</Link>
        </Menu.Item>

        <Menu.Item key="student" icon={<TeamOutlined />}>
          <Link to="/student">Students</Link>
        </Menu.Item>

        <Menu.Item key="employee" icon={<UserOutlined />}>
          <Link to="/employee">Employee</Link>
        </Menu.Item>

        <SubMenu key="Subjects" icon={<BookOutlined />} title="Subjects">
          <Menu.Item key="subjectsk12" icon={<CaretRightOutlined />}>
            <Link to="/subjectsk12">K12</Link>
          </Menu.Item>
          <Menu.Item key="subjectssr" icon={<CaretRightOutlined />}>
            <Link to="/subjectssr">Senior High</Link>
          </Menu.Item>
        </SubMenu>

        <SubMenu key="SYSubjects" icon={<CarryOutOutlined />} title="SY Subjects">
          <Menu.Item key="sy-subjects-k12" icon={<CaretRightOutlined />}>
            <Link to="/sy-subjects-k12">K12</Link>
          </Menu.Item>
          <Menu.Item key="sy-subjects-senior" icon={<CaretRightOutlined />}>
            <Link to="/sy-subjects-senior">Senior High</Link>
          </Menu.Item>
        </SubMenu>

        <SubMenu key="Traits" icon={<TagOutlined />} title="Traits">
          <Menu.Item key="traits" icon={<CaretRightOutlined />}>
            <Link to="/traits"></Link>
            Traits
          </Menu.Item>
          <Menu.Item key="sytraits" icon={<CaretRightOutlined />}>
            <Link to="/sytraits">SY Traits</Link>
          </Menu.Item>
        </SubMenu>

        <Menu.Item key="registration" icon={<SolutionOutlined />}>
          <Link to="/registration">Registration</Link>
        </Menu.Item>
        <SubMenu key="RatingSheet" icon={<FileTextOutlined />} title="Rating Sheet">
          <Menu.Item key="managegrades" icon={<CaretRightOutlined />}>
            <Link to="/managegrades"> Manage Grades</Link>
          </Menu.Item>
          <Menu.Item key="processsubjects" icon={<CaretRightOutlined />}>
            <Link to="/processsubjects">Process Subjects</Link>
          </Menu.Item>
        </SubMenu>

        <SubMenu key="Report" icon={<FileTextOutlined />} title="Reports">
          <Menu.Item key="report-cards" icon={<CaretRightOutlined />}>
            <Link to="/reports-cards">Report Cards</Link>
          </Menu.Item>
          <Menu.Item key="permanent-record" icon={<CaretRightOutlined />}>
            {/* Form 137(soon) */}
            <Link to="/permanent-record">Permanet Record</Link>
          </Menu.Item>
        </SubMenu>

        {/* <Menu.Item key="reports" icon={<DesktopOutlined />}>
          <Link to="/reports">Reports</Link>
        </Menu.Item> */}

        <Menu.Item key="about" icon={<DesktopOutlined />} style={{ marginBottom: 100 }}>
          <Link to="about">About</Link>
        </Menu.Item>
      </Menu>
    </Sider>
  );
};

export default Sidebar;
