import React, { useState, useCallback, useRef, useEffect } from "react";
import {
  Table,
  Row,
  Col,
  message,
  Select,
  Form,
  Typography,
  Button,
  Empty,
  Tag,
  Modal,
  Spin,
  Alert,
} from "antd";
import { DndProvider, useDrag, useDrop, createDndContext } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { DeleteOutlined, ExclamationCircleOutlined, SaveOutlined } from "@ant-design/icons";
import moment from "moment";
import { useMutation, queryCache } from "react-query";
import http from "services/httpService.js";
// import update from "immutability-helper";

// import { upperCase } from "helpers/textTransformer";
import SYTeacher from "views/SYSubjects/components/SYTeacher";
import DynamicSearchBox from "globalComponents/DynamicSearchBox";
import { useSchoolLevelQuery, useRegistration } from "views/SYSubjects/hooks/Index";

const RNDContext = createDndContext(HTML5Backend);

const type = "DragableBodyRow";
const { Option, OptGroup } = Select;
const { Title, Link } = Typography;
const { confirm } = Modal;

const DragableBodyRow = ({
  index,
  moveRow,
  record,
  allowDrag,
  allowAccepts,
  className,
  style,
  ...restProps
}) => {
  const ref = React.useRef();
  const [{ isOver, dropClassName }, drop] = useDrop({
    accept: allowAccepts ? ["DragableBodyRow"] : ["none"],
    collect: (monitor) => {
      const { index: dragIndex } = monitor.getItem() || {};
      if (dragIndex === index) {
        return {};
      }
      return {
        isOver: monitor.isOver(),
        dropClassName: dragIndex < index ? " drop-over-downward" : " drop-over-upward",
      };
    },
    drop: (item) => {
      moveRow(item.index, index, item.record);
    },
  });
  const [, drag] = useDrag({
    item: { type, index, record },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  if (allowDrag) {
    drop(drag(ref));
  } else {
    drop(ref);
  }
  return (
    <tr
      ref={ref}
      className={`${className}${isOver ? dropClassName : ""}`}
      style={{ cursor: "move", ...style }}
      {...restProps}
    />
  );
};

const SelectionForm = ({ selectedSyLevel, setSelectedSyLevel, setHasSelection, insertStudent }) => {
  const { isLoading, data } = useSchoolLevelQuery(3);
  // const [showSem, setShowSem] = useState(false);
  const [showSyTeacher, setShowSyTeacher] = useState(false);
  const [showDynamicSearch, setShowDynamicSearch] = useState(false);
  const [form] = Form.useForm();
  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 8 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 16 },
    },
  };
  const sySelections = generateSy();
  function generateSy() {
    let yearArray = [];
    let yearNow = Number(moment().format("YYYY"));
    for (let i = yearNow; i > 2000; i--) {
      yearArray.push(`${i}-${i + 1}`);
    }
    return yearArray;
  }
  const onSelectionChange = (value, option, type) => {
    setShowDynamicSearch(false);
    switch (type) {
      case "sy":
        setSelectedSyLevel({ ...selectedSyLevel, sy: value, employee_id: null });
        setHasSelection(false);
        break;
      case "level_code":
        setShowSyTeacher(true);
        setHasSelection(false);
        // if (value < 14) {
        //   setShowSem(false);
        //   setShowSyTeacher(true);
        // } else {
        //   setShowSem(true);
        //   setShowSyTeacher(false);
        // }

        setSelectedSyLevel({
          ...selectedSyLevel,
          level_code: value,
          level: option.children,
          sem: null,
          employee_id: null,
        });
        // form.setFieldsValue({
        //   sem: null,
        // });

        break;
      // case "sem":
      //   setSelectedSyLevel({ ...selectedSyLevel, sem: value });
      //   setShowSyTeacher(true);
      //   // if (!showSyTeacher)
      //   break;
      default:
        return;
    }
  };

  const onTeacherSelection = (record) => {
    const { employee_id } = record;
    setSelectedSyLevel({ ...selectedSyLevel, employee_id: employee_id });
    setShowDynamicSearch(true);
  };

  const handlesStudentSearch = (option) => {
    if (option) {
      insertStudent(0, 0, option);
    }
    // console.log(option);
    // if (option) {
    //   const checkInList = data.filter((item) => item.employee_id === option.employee_id);
    //   if (checkInList.length > 0) {
    //     message.warning(
    //       `Unable to Add, ${option.fullname} is already assigned to this School Year / Level`
    //     );
    //   } else {
    //     showAddTeacherConfirm(option, "add");
    //   }
    // }
  };
  return (
    <>
      <Row gutter={16}>
        <Col span={12}>
          <Form {...formItemLayout} form={form}>
            <Form.Item label="Select School Year" name="sy">
              <Select
                placeholder="none"
                style={{ width: 200 }}
                onChange={(value, option) => {
                  onSelectionChange(value, option, "sy");
                }}
                rules={[
                  {
                    required: true,
                    message: "Please select School Year!",
                  },
                ]}
              >
                {sySelections.map((item) => {
                  return (
                    <Option value={item} key={item}>
                      {item}
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>
            <Form.Item label="Select Level" name="level_code">
              <Select
                placeholder="none"
                style={{ width: 200 }}
                loading={isLoading}
                onChange={(value, option) => {
                  onSelectionChange(value, option, "level_code");
                }}
                rules={[
                  {
                    required: true,
                    message: "Please select Level!",
                  },
                ]}
              >
                {isLoading
                  ? null
                  : data.map((item, index) => {
                      return (
                        <OptGroup label={item.school_level_category} key={index}>
                          {item.school_level.map((element) => {
                            return (
                              <Option value={element.level_code} key={element.level_code}>
                                {element.level}
                              </Option>
                            );
                          })}
                        </OptGroup>
                      );
                    })}
              </Select>
            </Form.Item>
            {showDynamicSearch ? (
              <Form.Item label="Add From Record">
                <DynamicSearchBox
                  searchFrom={"student"}
                  showAddbutton={false}
                  disabled={false}
                  onSelect={handlesStudentSearch}
                />
              </Form.Item>
            ) : null}

            {/* {showSem && (
              <Form.Item label="Select Semester" name="sem">
                <Select
                  placeholder="none"
                  style={{ width: 200 }}
                  onChange={(value, option) => {
                    onSelectionChange(value, option, "sem");
                  }}
                  rules={[
                    {
                      required: true,
                      message: "Please School Semester!",
                    },
                  ]}
                >
                  <Option value="1">1st Semester</Option>
                  <Option value="2">2nd Semester</Option>
                </Select>
              </Form.Item>
            )} */}
          </Form>
        </Col>
        <Col span={12}>
          {showSyTeacher ? (
            <div style={{ minHeight: 190, backgroundColor: "#FFFF" }}>
              <SYTeacher
                selectedSyLevel={selectedSyLevel}
                disabled={false}
                simple={true}
                onTeacherSelection={onTeacherSelection}
              />
            </div>
          ) : (
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description="Teacher list."
              style={{ marginTop: 60, marginBottom: 60 }}
            />
          )}
        </Col>
      </Row>
    </>
  );
};

const Registration = () => {
  const [registeredData, setRegisteredData] = useState([]);
  const [registeredBeforeData, setRegisteredBeforeData] = useState([]);
  const [deletedStudents, setDeletedStudents] = useState([]);
  const [enableMainQuery, setEnableMainQuery] = useState(false);
  const [hasSelection, setHasSelection] = useState(false);
  const [selectedSyLevel, setSelectedSyLevel] = useState({
    sy: null,
    level_code: null,
    level: null,
    employee_id: null,
    sem: null,
  });
  const [isSaving, setIsSaving] = useState(false);

  const { isLoading, data: registrationData } = useRegistration(selectedSyLevel, {
    enabled: enableMainQuery,
  });
  // 'mainCredentials' => $mainCredentials,
  // 'toRegister' => $toRegister,
  // 'toDelete' => $toDelete

  const [mutate] = useMutation(
    async () => {
      return await http.put(
        `registration/update/${selectedSyLevel.sy}/${selectedSyLevel.level_code}/${selectedSyLevel.employee_id}`,
        {
          mainCredentials: selectedSyLevel,
          toRegister: registeredData,
          toDelete: deletedStudents,
        }
      );
    },
    {
      onSuccess: (data) => {
        // const { values, processType } = variables;
        // const { error  } = data.data;
        setIsSaving(false);
      },
      onError: () => {
        setIsSaving(false);
        message.warning("Unable to save, Something went wrong :/");
      },
      onSettled: () => {
        queryCache.invalidateQueries([
          "registration",
          {
            employee_id: selectedSyLevel.employee_id,
            level_code: selectedSyLevel.level_code,
            sy: selectedSyLevel.sy,
          },
        ]);
        Modal.success({
          title: "Registration Successful",
          content:
            'You need to run "Process Rating Sheet" before you can manage grades of newly registered student',
        });
      },
    }
  );

  const components = {
    body: {
      row: DragableBodyRow,
    },
  };

  const columns = [
    // {
    //   title: "Regs",
    //   dataIndex: "registration_no",
    //   key: "registration_no",
    //   width: 80,
    //   render: (text, record) =>
    //     record.registration_no === "new" ? <Tag color="orange">{text}</Tag> : upperCase(text),
    // },
    // {
    //   title: "ID",
    //   dataIndex: "student_id",
    //   key: "student_id",
    // },
    {
      title: () => (
        <>
          Name <Tag color="blue">Registered ({registeredData.length})</Tag>
          <Tag color="orange">
            New ({registeredData.filter((item) => item.registration_no === "new").length})
          </Tag>
        </>
      ),
      dataIndex: "fullname",
      key: "fullname",
    },
    {
      title: "",
      dataIndex: "actions",
      render: (text, record, index) => (
        <Link href="#/" onClick={() => showStudentDeleteConfirm(record, index)} type="danger">
          <DeleteOutlined />
        </Link>
        // <Button type="link" danger icon={} size="small" />
      ),
    },
  ];

  const columnsBefore = [
    // {
    //   title: "Regs",
    //   dataIndex: "registration_no",
    //   key: "registration_no",
    //   width: 80,
    //   // render: (text, record) => <Text style={{ opacity: 0.4 }}>{text}</Text>,
    // },
    {
      title: () => (
        <>
          Name <Tag color="purple">Registered Students ({registeredBeforeData.length})</Tag>
        </>
      ),
      dataIndex: "fullname",
      key: "fullname",
      // render: (text) => <Text style={{ opacity: 0.4 }}>{text}</Text>,
    },
  ];

  const showStudentDeleteConfirm = (record, index) => {
    // console.log(record, index);
    if (record.registration_no !== "new") {
      confirm({
        title: "Confirm Delete?",
        icon: <ExclamationCircleOutlined />,
        content: (
          <p>
            <b>{record.fullname}</b>, may already have grades in rating sheet, removing this student
            from registration will also remove rating sheet, attendance, and traits that was created
            for this paritcular student. Please take note that this action cannot be undone once
            saved.
          </p>
        ),
        onOk() {
          setRegisteredData(registeredData.filter((regs) => regs.student_id !== record.student_id));
          setDeletedStudents([...deletedStudents, record]);
        },
        onCancel() {},
      });
    } else {
      setRegisteredData(registeredData.filter((regs) => regs.student_id !== record.student_id));
    }
  };

  useEffect(() => {
    if (selectedSyLevel.sy && selectedSyLevel.level_code && selectedSyLevel.employee_id) {
      setEnableMainQuery(true);
      setHasSelection(true);
      if (registrationData) {
        setRegisteredData(registrationData.registered);
        setRegisteredBeforeData(registrationData.registeredBefore);
        setDeletedStudents([]);
      }
    }
  }, [selectedSyLevel, registrationData]);

  const moveRow = useCallback(
    (dragIndex, hoverIndex, draggedRecord) => {
      const checkData = registeredData.filter(
        (item) => item.student_id === draggedRecord.student_id
      );
      if (checkData.length > 0) {
        message.info("Already in the list.");
      } else {
        let newRow = {
          registration_no: "new",
          student_id: draggedRecord.student_id,
          fullname: draggedRecord.fullname,
          created_at: moment().format("YYYY-MM-DD H:mm:ss"),
        };
        setRegisteredData([...registeredData, newRow]);
      }
    },
    [registeredData]
  );
  // const saveRegistration = () => {

  //   // setTimeout(() => {
  //   //   // simulation only
  //   //   Modal.success({
  //   //     title: "Registration Successful",
  //   //     content:
  //   //       'You need to run "Process Rating Sheet" before you can manage grades of newly registered student',
  //   //   });
  //   //   setIsSaving(false);
  //   // }, 3000);
  // };

  const manager = useRef(RNDContext);

  return (
    <>
      <Spin spinning={isSaving} size="large" tip="Saving, please wait...">
        <SelectionForm
          selectedSyLevel={selectedSyLevel}
          setSelectedSyLevel={setSelectedSyLevel}
          setHasSelection={setHasSelection}
          insertStudent={moveRow}
        />
        {!hasSelection ? (
          <Empty description="Select Shool Year, Level and Teacher" style={{ marginTop: 40 }} />
        ) : (
          <DndProvider manager={manager.current.dragDropManager}>
            <Row gutter={16}>
              <Col span={12} style={{ marginTop: 12 }}>
                <Title level={4}>Currently Registered</Title>
                <Table
                  name="current"
                  rowKey="student_id"
                  loading={isLoading}
                  columns={columns}
                  dataSource={registeredData}
                  components={components}
                  onRow={(record, index) => ({
                    index,
                    moveRow,
                    record,
                    allowAccepts: true,
                    allowDrag: false,
                  })}
                  size="small"
                  pagination={true}
                />
                <Row gutter={8} style={{ marginTop: 20 }}>
                  <Col span={18}>
                    <Alert
                      message="Please click save button to apply changes on Registration. "
                      type="info"
                      showIcon
                    />
                  </Col>
                  <Col span={6}>
                    <Button
                      icon={<SaveOutlined />}
                      type="primary"
                      block
                      size="large"
                      onClick={() => {
                        setIsSaving(true);
                        mutate();
                        // saveRegistration();
                      }}
                    >
                      Save
                    </Button>
                  </Col>
                </Row>
              </Col>
              <Col span={12} style={{ marginTop: 12 }}>
                <Title level={4}>Previously Registered</Title>
                <Table
                  rowKey="student_id"
                  loading={isLoading}
                  columns={columnsBefore}
                  dataSource={registeredBeforeData}
                  components={components}
                  onRow={(record, index) => ({
                    index,
                    moveRow,
                    record,
                    allowAccepts: false,
                    allowDrag: true,
                  })}
                  size="small"
                  pagination={true}
                  name="previous"
                />
              </Col>
            </Row>
          </DndProvider>
        )}
      </Spin>
    </>
  );
};

export default Registration;
