import React, { useState, useCallback, useRef } from "react";
import {
  Table,
  Button,
  Dropdown,
  Tag,
  Space,
  Typography,
  Form,
  Select,
  Drawer,
  message,
  Row,
  Col,
  Empty,
  Alert,
} from "antd";
import { DndProvider, useDrag, useDrop, createDndContext } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import update from "immutability-helper";
import { useMutation, queryCache } from "react-query";
import http from "services/httpService.js";
import moment from "moment";
import { MoreOutlined, DeleteOutlined, PlusOutlined, SaveOutlined } from "@ant-design/icons";
import { useEffect } from "react";

import SYMenu from "views/SYSubjects/components/SYMenu";
import SYTeacher from "views/SYSubjects/components/SYTeacher";

import { useSchoolLevelQuery, useSYSubjects, useSubjectsK12 } from "views/SYSubjects/hooks/Index";

const RNDContext = createDndContext(HTML5Backend);
const type = "DragableBodyRow";
const { Option, OptGroup } = Select;
const { Text } = Typography;

// for extraction
const DragableBodyRow = ({ index, moveRow, record, className, style, ...restProps }) => {
  const ref = React.useRef();
  const [{ isOver, dropClassName }, drop] = useDrop({
    accept: type,
    collect: (monitor) => {
      const { index: dragIndex } = monitor.getItem() || {};
      if (dragIndex === index) {
        return {};
      }
      return {
        isOver: monitor.isOver(),
        dropClassName: dragIndex < index ? " drop-over-downward" : " drop-over-upward",
      };
    },

    drop: (item) => {
      moveRow(item.index, index);
    },
  });
  const [, drag] = useDrag({
    item: { type, index },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  if (record && !record.isSub) {
    drop(drag(ref));
  }

  return (
    <tr
      ref={ref}
      className={`${className}${isOver ? dropClassName : ""}`}
      style={{ cursor: record && !record.isSub ? "move" : "", ...style }}
      {...restProps}
    />
  );
};
// for extraction
const SelectionForm = ({ form, selectedSyLevel, setSelectedSyLevel }) => {
  const { isLoading, data } = useSchoolLevelQuery(1);
  const sySelections = generateSy();
  function generateSy() {
    let yearArray = [];
    let yearNow = Number(moment().format("YYYY"));
    for (let i = yearNow; i > 2000; i--) {
      yearArray.push(`${i}-${i + 1}`);
    }
    return yearArray;
  }

  const onSelectionChange = (value, option, type) => {
    switch (type) {
      case "sy":
        setSelectedSyLevel({ ...selectedSyLevel, sy: value });
        break;
      case "level_code":
        setSelectedSyLevel({ ...selectedSyLevel, level_code: value, level: option.children });
        break;
      default:
        return;
    }
  };
  return (
    <Form layout="inline" form={form}>
      <Form.Item label="Select School Year" name="sy">
        <Select
          style={{ width: 150 }}
          onChange={(value, option) => {
            onSelectionChange(value, option, "sy");
          }}
          rules={[
            {
              required: true,
              message: "Please select School Year!",
            },
          ]}
        >
          {sySelections.map((item) => {
            return (
              <Option value={item} key={item}>
                {item}
              </Option>
            );
          })}
        </Select>
      </Form.Item>
      <Form.Item label="Select Level" name="level_code">
        <Select
          style={{ width: 180 }}
          loading={isLoading}
          onChange={(value, option) => {
            onSelectionChange(value, option, "level_code");
          }}
          rules={[
            {
              required: true,
              message: "Please select Level!",
            },
          ]}
        >
          {isLoading
            ? null
            : data.map((item, index) => {
                return (
                  <OptGroup label={item.school_level_category} key={index}>
                    {item.school_level.map((element) => {
                      return (
                        <Option value={element.level_code} key={element.level_code}>
                          {element.level}
                        </Option>
                      );
                    })}
                  </OptGroup>
                );
              })}
        </Select>
      </Form.Item>
    </Form>
  );
};

// for extraction
const SubjectK12Selection = ({
  modalVisibility,
  setModalVisibility,
  subjectSYCodes,
  handlesAddPrimarySubject,
  isPrimary,
  handlesAppendChildren,
  primarySubjectCredentials,
}) => {
  const columns = [
    {
      title: "Subject Code",
      dataIndex: "subject_code",
      key: "subject_code",
      render: (text, record) => (
        <span className={subjectSYCodes.includes(record.subject_code) ? "disabled-row" : ""}>
          {record.subject_code}
        </span>
      ),
    },
    {
      title: "Subject",
      dataIndex: "subject",
      key: "subject",
      render: (text, record) => (
        <span className={subjectSYCodes.includes(record.subject_code) ? "disabled-row" : ""}>
          {record.subject}
        </span>
      ),
    },
    {
      title: "Units",
      dataIndex: "units",
      key: "units",
      render: (text, record) => (
        <span className={subjectSYCodes.includes(record.subject_code) ? "disabled-row" : ""}>
          {record.units}
        </span>
      ),
    },
  ];
  const { data: subjectk12 } = useSubjectsK12();
  const [tableData, setTableData] = useState([]);
  useEffect(() => {
    setTableData(subjectk12);
  }, [subjectk12]);

  const handlesRowClick = (event, record) => {
    if (subjectSYCodes.includes(record.subject_code)) {
      message.warning("Already in the list. ");
    } else {
      if (isPrimary) {
        handlesAddPrimarySubject(record);
      } else {
        handlesAppendChildren({
          record: record,
          primaryIndex: primarySubjectCredentials.primaryIndex,
          subject_code_primary: primarySubjectCredentials.subject_code_primary,
        });
      }
    }
  };
  return (
    <Drawer
      title={
        isPrimary ? "Subject k12 (Add Primary Subjects)" : "Subject k12 (Add Secondary Subjects)"
      }
      width={520}
      placement="right"
      closable={true}
      onClose={() => setModalVisibility(false)}
      visible={modalVisibility}
      footer={
        <div
          style={{
            textAlign: "right",
          }}
        >
          <Button onClick={() => setModalVisibility(false)} type="primary">
            Close
          </Button>
        </div>
      }
    >
      <Table
        columns={columns}
        dataSource={tableData}
        rowKey="subject_code"
        pagination={false}
        size="small"
        onRow={(record, rowIndex) => {
          return {
            onClick: (event) => {
              handlesRowClick(event, record);
            }, // click row
          };
        }}
      />
    </Drawer>
  );
};

const SYSubjectsK12 = () => {
  const [selectionForm] = Form.useForm();
  const [selectedSyLevel, setSelectedSyLevel] = useState({
    sy: null,
    level_code: null,
    level: null,
  });
  const [modalVisibility, setModalVisibility] = useState(false);
  const [hasSelection, setHasSelection] = useState(false);
  const [subjectSYCodes, setSubjectSYCodes] = useState([]);
  const [enableMainQuery, setEnableMainQuery] = useState(false);
  const [data, setData] = useState([]);

  const { isLoading, data: sySubjectsData } = useSYSubjects(selectedSyLevel, {
    enabled: enableMainQuery,
  });
  const [isPrimary, setIsPrimary] = useState(false);
  const [primarySubjectCredentials, setPrimarySubjectCredentials] = useState({});
  const manager = useRef(RNDContext);

  const [isLocked, setIsLocked] = useState();

  const [mutate] = useMutation(
    async (values) => {
      await http.put(`subjectk12sy/update/${selectedSyLevel.sy}/${selectedSyLevel.level_code}`, {
        values: values,
      });
    },
    {
      onSuccess: (data, variables) => {
        message.success("Record saved.");
      },
      onError: () => {
        message.warning("Unable to save, Something went wrong :/");
      },
      onSettled: () => {
        queryCache.invalidateQueries([
          "subjectk12sy",
          { level_code: selectedSyLevel.level_code, sy: selectedSyLevel.sy },
        ]);
      },
    }
  );

  const columns = [
    {
      title: () => (
        <Space>
          <Text>Subjects</Text>
          {selectedSyLevel.sy && selectedSyLevel.level_code ? (
            <Text> {`for (SY - ${selectedSyLevel.sy}) (Level - ${selectedSyLevel.level})`}</Text>
          ) : null}
        </Space>
      ),
      name: "subject",
      dataIndex: "subject",
      //   width: 300,
      render: (text, record) => {
        return (
          <Space>
            <span>{record.subject}</span>
            {record.with_sub === 1 ? (
              <Tag color="geekblue">with sub ({record.children.length})</Tag>
            ) : null}
          </Space>
        );
      },
    },
    {
      title: !isLocked ? (
        <Button
          shape="circle"
          icon={<PlusOutlined />}
          type="primary"
          disabled={!hasSelection}
          onClick={() => {
            setIsPrimary(true);
            setModalVisibility(true);
          }}
        />
      ) : null,
      // <Button
      //   shape="circle"
      //   icon={<ReloadOutlined />}
      //   type="primary"
      //   disabled={!hasSelection}
      // ></Button>
      key: "manage",
      dataIndex: "manage",
      width: 60,
      render: (text, record, index) => {
        if (record.isSub !== 1) {
          return !isLocked ? (
            <Dropdown
              overlay={
                <SYMenu
                  record={record}
                  index={index}
                  handlesDeleteSubject={handlesDeleteSubject}
                  handlesAddChildren={handlesAddChildren}
                  handlesClearChildren={handlesClearChildren}
                  setPrimarySubjectCredentials={setPrimarySubjectCredentials}
                  setIsPrimary={setIsPrimary}
                  setModalVisibility={setModalVisibility}
                />
              }
            >
              <Button size="small" shape="circle" icon={<MoreOutlined />}></Button>
            </Dropdown>
          ) : null;
        } else {
          return !isLocked ? (
            <Button
              size="small"
              type="link"
              danger
              icon={<DeleteOutlined />}
              onClick={() => handlesDeleteSubject(record, index)}
            />
          ) : null;
        }
      },
    },
  ];

  useEffect(() => {
    if (selectedSyLevel.sy && selectedSyLevel.level_code) {
      setHasSelection(true);
      setEnableMainQuery(true);
    }
    if (sySubjectsData) {
      setData(sySubjectsData.subjectsSY);
      setSubjectSYCodes(sySubjectsData.subjectsSYCodes);
      setIsLocked(sySubjectsData.isLocked);
    }
  }, [sySubjectsData, selectedSyLevel]);

  const components = {
    body: {
      row: DragableBodyRow,
    },
  };

  const moveRow = useCallback(
    (dragIndex, hoverIndex) => {
      const dragRow = data[dragIndex];
      setData(
        update(data, {
          $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, dragRow],
          ],
        })
      );
    },
    [data]
  );

  const handlesDeleteSubject = (record, index) => {
    if (record.isSub && record.isSub === 1) {
      let primaryIndex = data.findIndex(
        (element) => element.subject_code === record.subject_code_primary
      );
      console.log(primaryIndex);
      if (primaryIndex !== -1) {
        setData(
          update(data, {
            [primaryIndex]: {
              children: { $set: update(data[primaryIndex].children, { $splice: [[index, 1]] }) },
            },
          })
        );
        setSubjectSYCodes(subjectSYCodes.filter((item) => item !== record.subject_code));
      }
    } else {
      setData(
        update(data, {
          $splice: [[index, 1]],
        })
      );
      setSubjectSYCodes(subjectSYCodes.filter((item) => item !== record.subject_code));
    }
  };
  const handlesClearChildren = (record, index) => {
    const toUnset = record.children.map((item) => {
      return item.subject_code;
    });

    setSubjectSYCodes(subjectSYCodes.filter((item) => !toUnset.includes(item)));
    setData(
      update(data, {
        [index]: { $set: update(data[index], { with_sub: { $set: 0 }, $unset: ["children"] }) },
      })
    );
  };
  const handlesAddChildren = (record, index) => {
    setData(
      update(data, {
        [index]: { $set: update(data[index], { with_sub: { $set: 1 }, children: { $set: [] } }) },
      })
    );
  };

  const handlesAppendChildren = (value) => {
    const { record, primaryIndex, subject_code_primary } = value;

    setData(
      update(data, {
        [primaryIndex]: {
          children: {
            $push: [
              {
                subject_code: record.subject_code,
                subject: record.subject,
                subject_code_primary: subject_code_primary,
                isSub: 1,
              },
            ],
          },
        },
      })
    );
    setSubjectSYCodes([...subjectSYCodes, record.subject_code]);
  };
  const handlesAddPrimarySubject = (record) => {
    setData([...data, { subject_code: record.subject_code, subject: record.subject, with_sub: 0 }]);
    setSubjectSYCodes([...subjectSYCodes, record.subject_code]);
  };

  const handlesSave = (event) => {
    event.preventDefault();
    mutate([...data]);
  };
  return (
    <>
      <SelectionForm
        form={selectionForm}
        selectedSyLevel={selectedSyLevel}
        setSelectedSyLevel={setSelectedSyLevel}
      />

      {isLocked ? (
        <Alert
          message="Locked!"
          description="This SY / Level is locked, you cannot make any changes on subjects once rating sheet has been created. "
          type="warning"
          showIcon
          style={{ marginBottom: 20 }}
        />
      ) : null}
      {!hasSelection ? (
        <Empty description="Select School Year And Level" style={{ marginTop: 60 }} />
      ) : (
        <Row gutter={8}>
          <Col span={16}>
            <DndProvider manager={manager.current.dragDropManager}>
              <Table
                columns={columns}
                dataSource={data}
                components={components}
                onRow={(record, index) => ({
                  index,
                  moveRow,
                  record,
                })}
                pagination={false}
                rowKey="subject_code"
                loading={isLoading}
                //   style={{ pointerEvents: "none", opacity: "0.4" }}
              />
            </DndProvider>

            <Row gutter={8} style={{ marginTop: 20 }}>
              <Col span={18}>
                <Alert
                  message="Please click save button to apply changes on Subjects. "
                  type="info"
                  showIcon
                />
              </Col>
              <Col span={6}>
                <Button
                  icon={<SaveOutlined />}
                  type="primary"
                  block
                  onClick={(event) => {
                    handlesSave(event);
                  }}
                  size="large"
                  disabled={isLocked ? true : !hasSelection}
                >
                  Save
                </Button>
              </Col>
            </Row>
          </Col>
          <Col span={8}>
            <SYTeacher
              selectedSyLevel={selectedSyLevel}
              disabled={!hasSelection}
              isLocked={isLocked}
            />
          </Col>
        </Row>
      )}
      <SubjectK12Selection
        modalVisibility={modalVisibility}
        setModalVisibility={setModalVisibility}
        subjectSYCodes={subjectSYCodes}
        handlesAddPrimarySubject={handlesAddPrimarySubject}
        isPrimary={isPrimary}
        handlesAppendChildren={handlesAppendChildren}
        primarySubjectCredentials={primarySubjectCredentials}
      />
    </>
  );
};

export default SYSubjectsK12;
