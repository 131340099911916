import React, { useState } from "react";
import { Upload, message, Button, Card, Modal, Badge } from "antd";

import { UploadOutlined } from "@ant-design/icons";

import http from "services/httpService";
import { apiUrl } from "config.json";
import { useEffect } from "react";

const Attachment = ({ studentID }) => {
  const [studentFiles, setStudentFiles] = useState([]);

  useEffect(() => {
    getAttachments(studentID);
  }, [studentID]);

  async function getAttachments(studentID) {
    try {
      const { data } = await http.get(`studentsattachment/${studentID}`);
      if (Object.keys(data).length) {
        setStudentFiles(JSON.parse(data.attachment));
      }
    } catch (error) {
      message.error("Something went wrong :/, " + error.message);
    }
  }

  const { confirm } = Modal;

  const handleAttachmentDelete = (cb) => {
    confirm({
      title: `Are you sure to remove this file?`,
      content: "This cannot be undone",
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      maskClosable: true,
      onOk() {
        cb(true);
      },
      onCancel() {
        cb(false);
      },
    });
  };

  const uploadProps = {
    name: "uniFile",
    //accept: ".png,.jpg,.jpeg,.pdf",
    action: `${apiUrl}s3upload2?student_id=${studentID}`,
    headers: {
      authorization: localStorage.getItem("token"),
    },
    async onChange(info) {
      let newStudentFiles = [...info.fileList];
      newStudentFiles = newStudentFiles.map((file) => {
        if (file.response) {
          // Component will show file.url as link
          file.url = file.response.url;
        }
        return file;
      });

      if (info.file.status) {
        setStudentFiles(newStudentFiles);
      }

      if (info.file.status === "done") {
        //refine attachments array and save it to database
        const files = [];
        newStudentFiles.forEach((file) => {
          const { uid, name, url, size, status } = file;
          files.push({
            uid,
            name,
            url,
            size,
            status,
          });
        });
        try {
          await http.put(`studentsattachment/update`, {
            student_id: studentID,
            attachment: JSON.stringify(files),
          });
        } catch (error) {
          message.error("Something went wrong on your last operation :( " + error.message);
        }
      }
    },
    beforeUpload(file) {
      const isValidFile =
        file.type === "image/jpeg" || file.type === "image/png" || file.type === "application/pdf";
      if (!isValidFile) {
        message.error("You can only upload JPG/PNG and PDF file!");
      }
      const isValidSize = file.size / 1024 / 1024 < 5;
      if (!isValidSize) {
        message.error("File must smaller than 5MB!");
      }
      return isValidFile && isValidSize;
    },

    onRemove(file) {
      return new Promise(async (resolve, reject) => {
        handleAttachmentDelete(async function (isConfirmed) {
          if (isConfirmed) {
            resolve();
            const newStudentFiles = studentFiles.filter(
              (studentFiles) => studentFiles.uid !== file.uid
            );
            try {
              await http.put(`studentsattachment/update`, {
                student_id: studentID,
                attachment: JSON.stringify(newStudentFiles),
              });
            } catch (error) {
              message.error("Something went wrong on your last operation :( " + error.message);
            }
          }
        });
      });
    },
  };

  return (
    <div style={{ marginBottom: 16 }}>
      <Card title="Attachments" extra={<Badge count={studentFiles.length} showZero />}>
        <Upload {...uploadProps} fileList={studentFiles}>
          <Button icon={<UploadOutlined />}>Upload File</Button>
        </Upload>
      </Card>
    </div>
  );
};

export default Attachment;
